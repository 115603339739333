import React from 'react';
import Tooltip from "@mui/material/Tooltip";

// Define a type for the component props
interface CurrencyFormatterProps {
  amount: number;      // The amount to format
  locale: string;      // Locale code (e.g., "en-US", "en-IN")
  currency: string;    // Currency code (e.g., "USD", "INR")
}

const CurrencyFormatter: React.FC<CurrencyFormatterProps> = ({ amount, locale, currency }) => {
  // Creating a formatter using the Intl.NumberFormat API with specific options
  const options: Intl.NumberFormatOptions = {
    currency : currency,
    minimumFractionDigits: 2, // Specifies that 2 decimals are required
    maximumFractionDigits: 2  // Ensures the output has 2 decimal places
  };

  if (currency !== 'INR') {
    options.style = 'currency';
  }

  if (currency == 'INR') {
    options.style = 'decimal';
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  }

  const formatter = new Intl.NumberFormat(locale, options);

  // Format the amount using the formatter
  const formattedAmount = formatter.format(amount);

  return <Tooltip arrow title={formattedAmount}><span>{formattedAmount}</span></Tooltip>;
};

export default CurrencyFormatter;
