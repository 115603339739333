import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';

export const Service = {
    getUserById, updateUser, getAllApprovedUsers, getAllUsers, sendInvite, registerUser, getAllRoles, getSubmittedUsers, authentication, getAllTTBTypeAndFacility, updateUserProfile,
    resetUserPassword, getSubmittedUsersCount, uploadAndSave , removeUserProfile, getLoginHistory, fetchOpenRequestCount,fetchRecentRequestCount, fetchUnreadMessages,
    uploadDocs,fetchCompaniesList,fetchCompaniesOpenRequestCountByCompanyId, fetchCompanyUserData, fetchCompanyById, addUser, addCompany, getCityAndState, getEmailById, getActiveUsersCount, getAllRejectedUsers, getRejectedUsersCount
    ,fetchRequestData, changePassword, uploadProfileImage, getProfileImage,sendResetPasswordLink, removeProfileImage, logout, deleteCompanyById, getUserRoles, fetchAllPermissions, saveAllPermissions, getRoleById, updateRoleDescription, 
    saveSupportRequest, getAllSupportRequests, getOpenSupportRequestCount, getCloseSupportRequestCount, getAllComments, updateSupportRequestStatus, downloadFile, fetchCompaniesWithPagination, getAllServiceType, getFieldByServiceType, 
    fetchUsersList, saveCustomerSupportRequest, getAllActiveAndInactiveCompanies, updateCustomerSupportRequestValue,
}

async function getUserById(userId : any) {
    return await AxiosApiService.get<Object>(Url.GET_USER_BY_USER_ID + userId);
}

async function updateUser(user: any) {
    return await AxiosApiService.put<Object>(Url.UPDATE_USER, user);
}

async function getAllApprovedUsers(companyId:any,page:any,size:any, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_ALL_USERS, 
        { 
            companyId: companyId,
            page:page,
            size:size
        }, filter, { signal });
}

async function getAllUsers() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_USERS);
}

async function sendInvite(emailIds: any,companyId: any, flag: any) {
    let path = Url.SEND_INVITE + "/" + flag + "/" + companyId;
    return await AxiosApiService.post<ListFormat>(path, emailIds);
}

async function authentication(credential: any) {
    return await AxiosApiService.post<any>(Url.LOGIN, credential);
}

async function logout() {
    return await AxiosApiService.get<any>(Url.LOGOUT);

}
async function registerUser(user: any) {
    return await AxiosApiService.post<Object>(Url.USER_REGISTRATION, user);
}

async function getAllRoles() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_ROLES);

}

async function getUserRoles(includeSuperAdmin:boolean) {
    return await AxiosApiService.getWithParams<any>(Url.GET_USER_ROLES, {params: {
        includeSuperAdmin:includeSuperAdmin
    }});
}

async function getRoleById(id:any) {
    return await AxiosApiService.get<any>(Url.GET_ROLE_NAME_BY_ID + "/" + id);
}

async function getSubmittedUsers(companyId:any,page:any, size:any, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_SUBMITTED_USERS, 
        {
            companyId: companyId,
            page:page,
            size:size
        }, filter, { signal });

}

async function updateUserProfile(user: any) {
    return await AxiosApiService.put<Object>(Url.UPDATE_STATUS_IN_BULK, user)
}

async function removeUserProfile(list:any) {
    let path = Url.BULK_REMOVE_PROFILES + "/" + true;
    return await AxiosApiService.put<Object>(path, list)
}

async function resetUserPassword(user: any) {
    return await AxiosApiService.post<Object>(Url.RESET_PASSWORD, user)
}


//    async function getAllInventory() {
//        let path = Url.GET_ALL_INVENTORY;
//        axiosInstance.defaults.headers.Accept = 'text/event-stream'
//        return await get(path, {})
//    }

//    async function getProductById(productId: any) {
//        let path = Url.GET_INVENTORY_BY_ID + productId;
//        //axiosInstance.defaults.headers.Accept= 'text/event-stream'
//        return await get(path, {})
//    }

async function uploadAndSave(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_CSV, fileUpload)
}

async function uploadDocs(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_DOC, fileUpload)
}

async function getAllTTBTypeAndFacility() {
    return await AxiosApiService.get<any>(Url.GET_ALL_TYPE_AND_FACILITY);
}

async function getSubmittedUsersCount(companyId:any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_SUBMITTED_USER_COUNT, {params: {
        companyId: companyId
    }});
}

async function getLoginHistory(page:any, size:any){
    return await AxiosApiService.getWithParams<any>(Url.GET_LOGIN_HISTORY, {
        params: {
            page: page,
            size: size
        }
    });
}

async function fetchOpenRequestCount() {
    return await AxiosApiService.get<any>(Url.GET_OPEN_REQUEST_COUNT);
}

async function fetchRecentRequestCount(params: { id:string}, page:any, size:any, dashboardPrefrence: string ) {
    return await AxiosApiService.getWithParams<Object>(Url.GET_RECENT_REQUEST_COUNT, {
        params: {
            companyId: params.id,
            page: page,
            size: size,
            dashboardPrefrence: dashboardPrefrence,
        }
    });
}

async function fetchRequestData(params: { id:string }) {
    console.log("id" + params);
    return await AxiosApiService.getWithParams<Object>(Url.GET_REQUEST_DATA, {
        params: {
            companyId: params
        }
    });
}

async function fetchUnreadMessages(params: { id:string },dashboardPrefrence: string ) {
    return await AxiosApiService.getWithParams<Object>(Url.FETCH_UNREAD_MESSAGES, {
        params: {
            companyId: params.id,
            dashboardPrefrence: dashboardPrefrence
        }
    });
}

async function fetchCompaniesList(){
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_LIST);
}

async function fetchCompaniesWithPagination(page:any, size:any, filter: any, signal: AbortSignal){
    return await AxiosApiService.postWithSearchParams<Object>(Url.FETCH_ALL_COMPANIES_WITH_PAGINATION, 
        {
            page: page,
            size: size
        }, filter, { signal });
}

async function fetchCompaniesOpenRequestCountByCompanyId(companyId:any,dashboardPrefrence: string ) {
    return await AxiosApiService.getWithParams<any>(Url.FETCH_COMPANIES_OPEN_REQUEST_COUNT + '/' + companyId, {
        params: {
            dashboardPrefrence: dashboardPrefrence
        }
    });
}

async function fetchCompanyUserData(companyId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_USER_DATA + '/' + companyId);
}

async function fetchCompanyById(companyId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_BY_ID + '/' + companyId);
}

async function getEmailById(id:any) {
    return await AxiosApiService.get<any>(Url.GET_EMAIL_BY_ID + '/' + id);
}

async function addUser(data:any) {
    return await AxiosApiService.post<any>(Url.ADD_USER, data);
}

async function addCompany(data:any) {
    return await AxiosApiService.post<any>(Url.ADD_COMPANY,data);
}

async function getCityAndState(zipcode:any) {
    return await AxiosApiService.post<any>(Url.GET_CITY_AND_STATE + '/' + zipcode, "");
}

async function getAllRejectedUsers(companyId:any,page:any,size:any, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_ALL_REJECTED_USERS,
        {
            companyId: companyId,
            page:page,
            size:size
        },filter, { signal });
}

async function getRejectedUsersCount(companyId:any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_REJECTED_USER_COUNT, {params: {
        companyId: companyId
    }});
}

async function getActiveUsersCount(companyId:any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_ACTIVE_USER_COUNT, {params: {
        companyId: companyId
    }});
}

async function changePassword(passwordDetails: any) {
    return await AxiosApiService.put<Object>(Url.CHANGE_PASSWORD, passwordDetails);
}

async function uploadProfileImage(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_PROFILE_IMAGE, fileUpload)
}

async function getProfileImage(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.GET_PROFILE_IMAGE, downloadDoc);
}

async function sendResetPasswordLink(email:any) {
    return await AxiosApiService.get<any>(Url.SEND_RESET_PASSWORD_LINK + "/" + email);
}

async function removeProfileImage(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.REMOVE_PROFILE_IMAGE, fileUpload)
}

async function deleteCompanyById(companyIds: any) {
    return await AxiosApiService.post<any>(Url.DELETE_COMPANIES_BY_ID , companyIds);
}

async function fetchAllPermissions(roleId: any) {
    return await AxiosApiService.get<any>(Url.GET_ALL_PERMISSIONS + "/" + roleId);
}

async function updateRoleDescription(roleId:any, description:any) {
    return await AxiosApiService.getWithParams<any>(Url.UPDATE_ROLE_DESCRIPTION + "/" + roleId, {
        params:{
            description : description
        }
    })
}

async function saveAllPermissions(permissions:any, roleId:any) {
    return await AxiosApiService.post<any>(Url.SAVE_ROLES_AND_PERMISSIONS + "/" + roleId + "/save", permissions);
}

async function saveSupportRequest(formData:any) {

    return await AxiosApiService.post<any>(Url.SAVE_SUPPORT_REQUEST, formData);
}

async function getAllSupportRequests(companyId:any, supportStatus:any,page: any, size: any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_SUPPORT_REQUEST, {
        params:{
            companyId: companyId,
            supportStatus: supportStatus,
            page: page,
            size: size
        }
    })

}

async function getAllComments(id:any) {
    return await AxiosApiService.get<any>(Url.GET_ALL_COMMENTS + "/" + id);
}

async function getOpenSupportRequestCount (id:any) {
    return await AxiosApiService.getWithParams<any>(Url.OPEN_SUPPORT_REQUEST_COUNT, {
        params:{
            companyId: id
        }
    })
}

async function getCloseSupportRequestCount (id:any) {
    return await AxiosApiService.getWithParams<any>(Url.CLOSE_SUPPORT_REQUEST_COUNT, {
        params:{
            companyId: id
        }
    })
}

async function updateSupportRequestStatus (id:any, status:any) {
    return await AxiosApiService.getWithParams<any>(Url.UPDATE_SUPPORT_REQUEST_STATUS + "/" + id, {
        params:{
            status: status
        }
    })
}

async function downloadFile (requestData:any) {
    return await AxiosApiService.postBlob<any>(Url.DOWNLOAD_SUPPORT_REQUEST_FILE, requestData)
}

async function getAllServiceType () {
    return await AxiosApiService.get<any>(Url.GET_ALL_SERVICE_TYPE);
}

async function getFieldByServiceType (serviceTypeId:any) {
    return await AxiosApiService.get<any>(Url.GET_FIELD_BY_SERVICE_TYPE + "/" + serviceTypeId);
}

async function saveCustomerSupportRequest (requestData:any) {
    return await AxiosApiService.post<any>(Url.SAVE_CUSTOMER_SUPPORT_REQUEST, requestData);
}

async function fetchUsersList (companyId:any) {
    return await AxiosApiService.get<any>(Url.GET_USER_LIST_BY_COMPANY_ID + "/" + companyId)
}

async function getAllActiveAndInactiveCompanies(){
    return await AxiosApiService.get<any>(Url.GET_ALL_ACTIVE_AND_INACTIVE_COMPANIES);
}

async function updateCustomerSupportRequestValue (requestData:any) {
    return await AxiosApiService.post<any>(Url.UPDATE_CUSTOMER_SUPPORT_REQUEST_VALUE, requestData);
}
