import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Dashboard from "../Dynamic/Dashboard";
// import Footer from './Footer';
import Header from "./Header";
import Register from "../Static/Register";
import Inventory from "../Dynamic/Inventory";
import Cookies from "universal-cookie";
import Typesense from "../Dynamic/Typesense";
import InventoryCSV from "../Dynamic/InventoryCSV";
import ViewCSV from "../Dynamic/ViewCSV";
import OrderDetail from "../Dynamic/OrderDetail";
import ProductDetail from "../Dynamic/ProductDetail";
import MyCart from "../Dynamic/MyCart";
import LoginHistory from "../Dynamic/LoginHistory";
import UserHeader from "./UserHeader";
import Footer from "./Footer";
import ActiveOrder from "../Dynamic/ActiveOrder";
import SampleRequest from "../Dynamic/SampleRequest";
import OfferRequest from "../Dynamic/OfferRequest";
import ContainerMessageBox from "../Dynamic/ContainerMessageBox";
import ViewSellRequest from "../Dynamic/ViewSellRequest";
import GroupMessageBox from "../Dynamic/GroupMessageBox";
import ViewSampleRequest from "../Dynamic/ViewSampleRequest";
import ViewOfferRequest from "../Dynamic/ViewOfferRequest";
import OrderSuccess from "../Dynamic/OrderSuccess";
import UserList from "../Dynamic/UserList";
import WarehouseCompanyDashboard from "../Dynamic/WarehouseCompanyDashboard";
import MyInventories from "../Dynamic/MyInventories";
import EditInventory from "../Dynamic/EditInventory";
import CompareInventory from "../Dynamic/CompareInventory";
import EmptyCart from "../Dynamic/EmptyCart";
import MyRequests from "../Dynamic/MyRequests";
import ViewOrEditProfile from "../Static/Profile/ViewOrEditProfile";
import UATbelt from "./UATbelt";
import InventoryEmpty from "../Dynamic/InventoryEmpty";
import AddCompanyOrUser from "../Dynamic/AddCompanyOrUser";
import AddSupportRequest from "../Dynamic/AddSupportRequest";
import CustomerSupport from "../Dynamic/CustomerSupport";
import SupportRequest from "../Dynamic/SupportRequest";
import ViewSupportRequest from "../Dynamic/ViewSupportRequest";

const UserLayout = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    const fetchUserRole = () => {
      const cookies = new Cookies();
      const role = cookies.get("userRole");
      setUserRole(role);
    };

    fetchUserRole();
  }, []); // Empty dependency array ensures this effect runs only once

  if (userRole === null) {
    // Render loading indicator while fetching user role
    return <div>Loading...</div>;
  }

  const route = (route: string) => {
    history.push(route);
  };

  return (
    <div>
      <UATbelt />
      <UserHeader />
      <Switch>
        <Route exact path="/user/my-profile/:id">
          <ViewOrEditProfile />
        </Route>
        <Route exact path="/user/my-requests">
          <MyRequests />
        </Route>
        <Route exact path="/user/edit-inventory/:id">
          <EditInventory />
        </Route>
        <Route exact path="/user/my-warehouse/inventory">
          <MyInventories />
        </Route>
        <Route exact path="/user/my-warehouse/available-for-sell">
          <MyInventories />
        </Route>
        <Route exact path="/user/my-warehouse/not-available-for-sell">
          <MyInventories />
        </Route>
        <Route exact path="/user/inventory-change-requests">
          <MyInventories />
        </Route>
        <Route exact path="/user/compare-inventory/:id">
          <CompareInventory />
        </Route>
        <Route exact path="/user/my-warehouse">
          <WarehouseCompanyDashboard />
        </Route>
        <Route exact path="/user/inventories">
          <Typesense />
        </Route>
        <Route exact path="/user/inventory-sell-requests">
          <InventoryCSV />
        </Route>
        <Route exact path="/user/product-details/:id">
          <ProductDetail />
        </Route>
        <Route exact path="/user/my-cart">
          <MyCart />
        </Route>
        <Route exact path="/user/view-csv/:id">
          <ViewCSV />
        </Route>
        <Route exact path="/user/view-sell-request/:id">
          <ViewSellRequest />
        </Route>
        <Route exact path="/user/order-detail/:id">
          <OrderDetail />
        </Route>
        <Route exact path="/user/view-as-owner/order-detail/:id">
          <OrderDetail />
        </Route>
        <Route exact path="/user/view-as-buyer/order-detail/:id">
          <OrderDetail />
        </Route>
        <Route exact path="/user/active-order">
          <ActiveOrder />
        </Route>
        <Route exact path="/user/my-warehouse/active-order">
          <ActiveOrder />
        </Route>
        <Route exact path="/user/view-as-owner/active-order">
          <ActiveOrder />
        </Route>
        <Route exact path="/user/view-as-buyer/active-order">
          <ActiveOrder />
        </Route>
        <Route exact path="/user/sample-request">
          <SampleRequest />
        </Route>
        <Route exact path="/user/view-sample-request/:id">
          <ViewSampleRequest />
        </Route>
        <Route exact path="/user/my-warehouse/sample-request">
          <SampleRequest />
        </Route>
        <Route exact path="/user/offer-request">
          <OfferRequest />
        </Route>
        <Route exact path="/user/view-offer-request/:id">
          <ViewOfferRequest />
        </Route>
        <Route exact path="/user/my-warehouse/offer-request">
          <OfferRequest />
        </Route>
        <Route exact path="/user/wish-list">
          <Typesense />
        </Route>
        <Route exact path="/user/messages">
          <GroupMessageBox />
        </Route>
        <Route exact path="/user/order-success">
          <OrderSuccess />
        </Route>
        <Route exact path="/user/empty-cart">
          <EmptyCart />
        </Route>
        <Route exact path="/user/user-list">
          <UserList />
        </Route>
        <Route exact path="/user/my-warehouse/user-list/:id">
          <UserList />
        </Route>
        <Route exact path="/user/user-detail/:id">
          <ViewOrEditProfile />
        </Route>
        <Route exact path="/user/inventory-list">
          <InventoryEmpty />
        </Route>
        <Route exact path="/user/add-user">
          <AddCompanyOrUser type={"user"} />
        </Route>
        <Route exact path="/user/view-as-buyer">
          <WarehouseCompanyDashboard />
        </Route>
        <Route exact path="/user/view-as-owner">
          <WarehouseCompanyDashboard />
        </Route>
        <Route exact path="/user/customer-support">
          <CustomerSupport/>
        </Route>
        <Route exact path="/user/customer-service">
          <SupportRequest />
        </Route>
        <Route exact path="/user/add-customer-service-request">
          <AddSupportRequest />
        </Route>
        <Route exact path="/user/view-customer-service-request/:id">
          <ViewSupportRequest />
        </Route>
        <Route exact path="/user/edit-customer-service-request/:id">
          <AddSupportRequest />
        </Route>
        <Route path="/">
          {userRole === "SUPER_ADMIN" ? (
            <Redirect to="/master/dashboard" />
          ) : (
            <Redirect to="/user/inventories" />
          )}
        </Route>
      </Switch>
      {<Footer />}
    </div>
  );
};

export default UserLayout;