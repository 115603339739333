import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Skeleton,
} from "@mui/material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { CommonService } from "../../Service/CommonService";

interface ChildProps {
  historyData: any[];
}

export const HistoryStepper: React.FC<ChildProps> = ({ historyData }) => {
  return (
    <Paper elevation={3} style={{ padding: "16px", margin: "16px" }}>
      {historyData.length === 0 ? (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            // height={550}
            style={{ height: "100%" }}
          />
        </>
      ) : (
        <>
          {historyData.length === 0 ? (
            <Typography variant="h6" color="textSecondary">
              No history data available
            </Typography>
          ) : (
            <>
              {" "}
              {historyData.map((historyGroup: any, groupIndex: any) => (
                <div
                  key={groupIndex}
                  className={`history-item d-flex align-items-start ${groupIndex ===historyData.length-1  ? "last-child" : ""}`}
                >
                  <Typography style={{ marginRight: "16px",minWidth:100,marginTop:4 }}>
                    {new Date(historyGroup.date).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </Typography>
                  <Stepper orientation="vertical">
                    <Step active>
                      <StepLabel></StepLabel>
                      <StepContent>
                        {historyGroup.histories.map(
                          (history: any, index: any) => (
                            <div className="history-content" key={index}>
                              <Typography variant="subtitle1">
                                <div className="d-flex gap-4">
                                <b className="text-nowrap">
                                  {CommonService.formatRecentActivityTime(
                                    history.createdDate
                                  )}{" "}
                                </b>
                                <span>
                                {/* <b>{new Date(history.createdDate).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} </b>  */}
                                {history.firstName} {history.lastName} |{" "}
                                {history.description ||
                                  "No description provided"}
                                  </span>
                                  </div>
                              </Typography>
                            </div>
                          )
                        )}
                      </StepContent>
                    </Step>
                  </Stepper>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </Paper>
  );
};
