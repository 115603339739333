import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormatter from '../Static/CurrencyFormatter';
import { Table } from 'react-bootstrap';
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CommonService } from '../../Service/CommonService';
import EditIcon from '../../assets/images/icons/EditIcon.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { VisibilityOutlined, EditOutlined, DeleteOutlined } from "@mui/icons-material";
import StatusDropdown from "../Common/StatusDropdown";
import { OrderApiService } from '../../Service/OrderApiService';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ViewProductDetail from './ViewProductDetail';
import { useLocation, useParams } from 'react-router';
import Constant from '../../Constant';
import Customspinner from '../Common/CustomSpinner';

interface CartProps {
    cartItems: any[];
    onEditClick: (itemId: string) => void;
    onDeleteClick: (itemId: string) => void;
    onOrderItemStatusChange:(newStatus: string, orderItemId: any, inventoryId: any, inventoryName: any) => void;
    viewDetails: any;
    handleViewClose: any;
    handleViewShow: any;
}

const InventoryFileListTable: React.FC<CartProps> = ({ cartItems, onEditClick, onDeleteClick, onOrderItemStatusChange, viewDetails, handleViewClose, handleViewShow }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const { id } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useState<boolean[]>();
    const [inventoryId, setinventoryId] = useState("");
    const [isCurrentStatusPendingOrUnderReview, setIsCurrentStatusPendingOrUnderReview] = useState<boolean>(false);
    const [isSeller, setIsSeller] = useState<boolean>(false);
    const [orderSummary, setOrderSummary] = React.useState<any>([]);
    let dashboardPrefrence="";

    // For Setting Equal width 
    const priceRef = useRef<HTMLTableCellElement>(null);
  const totalPriceRef = useRef<HTMLTableCellElement>(null);
  const handlingRef = useRef<HTMLTableCellElement>(null);
  const servicesRef = useRef<HTMLTableCellElement>(null);
  const ownerStatusRef = useRef<HTMLTableCellElement>(null);
  const actionRef = useRef<HTMLTableCellElement>(null);
  const platformFeesRef = useRef<HTMLTableCellElement>(null);

  const [columnWidths, setColumnWidths] = useState<{
    priceWidth: number;
    totalPriceWidth: number;
    handlingWidth: number;
    servicesWidth: number;
    ownerStatusWidth: number;
    actionWidth: number;
    platformFeesWidth: number;
  }>({
    priceWidth: 0,
    totalPriceWidth: 0,
    handlingWidth: 0,
    servicesWidth: 0,
    ownerStatusWidth: 0,
    actionWidth: 0,
    platformFeesWidth: 0,
  });

  useEffect(() => {
    const measureWidths = () => {
      setColumnWidths({
        priceWidth: priceRef.current?.offsetWidth || 0,
        totalPriceWidth: totalPriceRef.current?.offsetWidth || 0,
        handlingWidth: handlingRef.current?.offsetWidth || 0,
        servicesWidth: servicesRef.current?.offsetWidth || 0,
        ownerStatusWidth: ownerStatusRef.current?.offsetWidth || 0,
        actionWidth: actionRef.current?.offsetWidth || 0,
        platformFeesWidth: platformFeesRef.current?.offsetWidth || 0,
      });
    };

    // Use ResizeObserver to track size changes
    const resizeObserver = new ResizeObserver(() => {
      measureWidths();
    });

    // Observe each reference
    const refs = [
      priceRef,
      totalPriceRef,
      handlingRef,
      servicesRef,
      ownerStatusRef,
      actionRef,
      platformFeesRef,
    ];

    refs.forEach((ref) => {
      if (ref.current) {
        resizeObserver.observe(ref.current);
      }
    });

    // Measure widths initially
    measureWidths();

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  

    useEffect(() => {
        setIsOpen(cartItems.map(() => false));

        const isSeller = cartItems.some((item) => {
            return item.typeSenseResponse.company_id === CommonService.getCompanyId() && CommonService.isUser()
        });
        setIsSeller(isSeller);

        const currentStatus = cartItems.some((item) => {
            return item.orderStatus === "PENDING_REVIEW" || item.orderStatus === "UNDER_REVIEW";
        });
        setIsCurrentStatusPendingOrUnderReview(currentStatus);

        fetchOrderSummaryDetail();
    }, [cartItems]);

    const toggleSection = (index: number) => {
        setIsOpen(prevState => prevState.map((open, i) => (i === index ? !open : open)));
    };

    //formate the status
    function formatStatus(status?: string): string {
        let formattedStatus = "";
        if (status !== undefined) {
        formattedStatus = status
            ?.split(/[_\\]/)
            .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        }
        return formattedStatus;
    }

    const fetchOrderSummaryDetail = async () => {

        if (currentPath.startsWith('/user/view-as-buyer/order-detail'))
          dashboardPrefrence = Constant.Profile.BUYER;
        else if (currentPath.startsWith('/user/view-as-owner/order-detail'))
          dashboardPrefrence = Constant.Profile.OWNER;
        else
          dashboardPrefrence= CommonService.getDashboardPrefrence();
    
        const response: any = await OrderApiService.getOrderSummaryByOrderId(id, dashboardPrefrence);
        if (response.data) setOrderSummary(response.data);
    };

    return (
        <>
        <div className="table-responsive-lg table-responsive-fixed-header">
                <Table className='orderRequest-inv-table text-center'>
                <thead>
                <tr>
                    <th className='text-nowrap text-start'>Inventory</th>
                    { !isSeller && <>
                    <th colSpan={2} className="text-center text-nowrap">Seller</th>
                    <th colSpan={2} className="text-center text-nowrap">Buyer</th>
                    </>}
                    <th className='text-nowrap text-center'>Quantity</th>
                    <th  ref={priceRef} className='text-nowrap text-center'>Price</th>
                    {isSeller && <th className='text-nowrap text-center' ref={platformFeesRef}>Fee</th>}
                    <th ref={totalPriceRef} className='text-nowrap text-center'>Total Price</th>
                    { !isSeller && <th colSpan={2} className="text-center text-nowrap">Fees</th>}
                    { (isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center' ref={ownerStatusRef}>Owner Status</th> }
                    {(CommonService.isAdmin() && isCurrentStatusPendingOrUnderReview) && <th className='text-nowrap text-center' ref={actionRef}> Action</th> }
                </tr>
                <tr>
                    <th className='text-nowrap text-center'></th>
                    { !isSeller && <>
                    <th className='text-nowrap text-center'>Relocation</th>
                    <th className='text-nowrap text-center'>Cha. of Acc.</th>
                    <th className='text-nowrap text-center'>Relocation</th>
                    <th className='text-nowrap text-center'>Cha. of Acc.</th>
                    </>}
                    <th className='text-nowrap text-center'></th>
                    <th className='text-nowrap text-center'></th>
                    {isSeller && <th className='text-nowrap text-center'></th>}
                    <th className='text-nowrap text-center'></th>
                    { !isSeller && <>
                    <th className='text-nowrap text-center' ref={handlingRef}>Handling</th>
                    <th className='text-nowrap text-center' ref={servicesRef}>Services</th>
                    </>}
                    {(isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center'></th>}
                    {(CommonService.isAdmin() && isCurrentStatusPendingOrUnderReview) &&<th className='text-nowrap text-center'></th>}
                </tr>
                </thead>
                {/* {(!cartItems || cartItems.length == 0) ? (
                  <div style={{ height: "236px", display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center' }}>
                    <Customspinner />
                  </div>
                ):(     */}
                <tbody>
                 
                    <>
                    {cartItems.length > 0 ? (
                        cartItems.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td className='text-start cursor-pointer' onClick={() => {handleViewShow(); setinventoryId(item.inventoryId); }}>
                                        <div className="header d-flex flex-column" onClick={() => toggleSection(index)}>
                                            {/* <div className={`icon ${isOpen[index] ? 'minus' : 'plus'}`}></div> */}
                                            {/* <>
                                                {isOpen[index] ? <RemoveCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} /> : <AddCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} />}
                                            </> */}
                                            <span className="inventory-name"><p className='m-1'>{item.inventoryName}</p></span>
                                            <Typography component="p" sx={{ color: "#737376", fontSize: "13px", fontWeight: "400", lineHeight:"21.28px" }}>
                                                {CommonService.isAdmin() && item.inventoryOwnerCompanyName}
                                            </Typography>

                                        </div>
                                    </td>
                                    { !isSeller && <>
                                    <td className='text-center'>
                                        {item.typeSenseResponse?.is_relocation_required ? (
                                            <>
                                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                            </>
                                        ) : (
                                            <>
                                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.typeSenseResponse?.is_account_transfer ? (
                                            <>
                                               <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                           </>
                                        ) : (
                                           <>
                                               <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                           </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.isRelocationRequired ? (
                                            <>
                                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                            </>
                                        ) : (
                                            <>
                                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.isAccountTransfer ? (
                                            <>
                                               <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                           </>
                                        ) : (
                                           <>
                                               <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                           </>
                                        )}
                                    </td>
                                    </>}
                                    <td className='text-center'>{item.quantity} barrels
                                        {(isSeller || CommonService.isAdmin()) && 
                                        <span style={{cursor: 'default'}} className='ms-2'>
                                            {item.typeSenseResponse?.quantity > 100 ? (
                                                <>
                                                <Tooltip 
                                                        arrow 
                                                        title={`${item.typeSenseResponse?.quantity} ${(item.typeSenseResponse?.quantity) > 1 ? 'Barrels' : 'Barrel'} in Stock`}
                                                    >
                                                <span className='available-barrels-count text-nowrap' style={{backgroundColor:"#75BC84"}}>{item.typeSenseResponse?.quantity} 
                                                    {/* In  Stock */}
                                                    </span>
                                                    </Tooltip>
                                                </> 
                                            ): (<>
                                            <Tooltip 
                                                        arrow 
                                                        title={`${item.typeSenseResponse?.quantity} ${(item.typeSenseResponse?.quantity) > 1 ? 'Barrels' : 'Barrel'} in Stock`}
                                                    >
                                                <span className='available-barrels-count text-nowrap' style={{backgroundColor:"#C7858B"}}>{item.typeSenseResponse?.quantity} 
                                                    {/* In  Stock */}
                                                    </span>
                                                    </Tooltip>
                                                </> 
                                            )}
                                        </span>}
                                    </td>
                                    <td  ref={priceRef} className='text-center'><CurrencyFormatter amount={!isSeller ? item.price : 
                                        item.typeSenseResponse.fee_type == 'FLAT' 
                                        ? item.price - item.platformFees
                                        : item.price / (1 + item.platformFees / 100)} locale="en-US" currency="USD" />
                                    </td>
                                    {isSeller && <td className='text-center' ref={platformFeesRef}>
                                        (<CurrencyFormatter
                                            amount={
                                            item.typeSenseResponse.fee_type == 'FLAT' 
                                                ? item.platformFees
                                                : (item.price * item.platformFees) / 100
                                            }
                                            locale="en-US"
                                            currency="USD"
                                        />)
                                    </td>}
                                    <td className='text-center' ref={totalPriceRef}><CurrencyFormatter amount={!isSeller ? item.totalPrice : 
                                        item.typeSenseResponse.fee_type == 'FLAT' 
                                        ? (item.totalPrice  - (item.platformFees * item.quantity))
                                        : (item.totalPrice / (1 + item.platformFees / 100))} locale="en-US" currency="USD" /></td>
                                    { !isSeller && <>
                                    <td className='text-center' ref={handlingRef}><CurrencyFormatter amount={item.handlingFees} locale="en-US" currency="USD" /></td>
                                    <td className='text-center' ref={servicesRef}><CurrencyFormatter amount={item.serviceFees} locale="en-US" currency="USD" /></td>
                                    </>}
                                    {(isSeller || CommonService.isAdmin()) && <> 
                                    <td className='text-center' ref={ownerStatusRef}>
                                        {CommonService.isAdmin() && <StatusDropdown currentStatus={formatStatus(item?.status)}
                                            onStatusChange={(newStatus) =>
                                                onOrderItemStatusChange(newStatus,item['id'],item['inventoryId'],item['inventoryName'])
                                            }
                                            disableCondition={CommonService.isSameCompany(item?.companyId) || ["REJECTED", "ADMIN_REJECTED", "ACCEPTED"].includes(item?.status)
                                                || ["ACCEPTED", "REJECTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "CLOSED", "CANCELLED"].includes(item?.orderStatus)
                                            }
                                            allowedLabels={(item?.status === "ADMIN_ACCEPTED") ? ["Reject for Owner"] 
                                                            : item?.status === "PENDING_REVIEW" ? ["Accept for Owner", "Reject for Owner"] 
                                                            :["Send to Owner", "Accept for Owner", "Reject for Owner"]}/>}

                                        {CommonService.isUser() && <StatusDropdown currentStatus={formatStatus(item?.status)}
                                            onStatusChange={(newStatus) =>
                                                onOrderItemStatusChange(newStatus,item['id'],item['inventoryId'],item['inventoryName'])
                                            }
                                            disableCondition={CommonService.isSameCompany(item?.companyId) || ["REJECTED", "ADMIN_REJECTED"].includes(item?.status)
                                                || ["ACCEPTED", "REJECTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "CLOSED", "CANCELLED"].includes(item?.orderStatus)
                                            }
                                            allowedLabels={item?.status === "ADMIN_ACCEPTED" ? ["Rejected"] : ["Accepted", "Rejected"]}/>}

                                        {(item?.status === "REJECTED" || item?.status === "ADMIN_REJECTED") && (
                                            <Tooltip
                                            title={`Rejection Note: ${item.rejectionNote}`}
                                            arrow
                                            >
                                            <StickyNote2Icon
                                                className="note-icon cursor-pointer"
                                                style={{ color: "gray" }}
                                            />
                                            </Tooltip>
                                        )}

                                    </td>
                                    </>}
                                    {(CommonService.isAdmin() && isCurrentStatusPendingOrUnderReview) && 
                                        <td className='text-center' ref={actionRef}>
                                        <div className='d-flex justify-content-center gap-3'>
                                            <img
                                                src={EditIcon}
                                                alt="EditIcon"
                                                onClick={() => onEditClick(item.id)} 
                                                style={{ cursor: 'pointer' }}
                                            />
                                            {cartItems.length > 1 && (<DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => { onDeleteClick(item.id); fetchOrderSummaryDetail();}}/>)}
                                        </div>
                                    </td>}
                                </tr>
                                {/* <tr className="product-info position-sticky bottom-0">
                                    <td colSpan={!isSeller ? 6 : 2} className='text-nowrap text-end'>
                                        <Typography component="span" sx={{ color: "#333333", fontSize: "13px", fontWeight: "400", lineHeight: "17.29px" }}>
                                            The price does not include shipping costs.
                                        </Typography>
                                    </td>
                                    <td className='text-nowrap text-center'> <Typography
                                        component="span"
                                        sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                                    >
                                        Subtotal:
                                    </Typography>
                                    </td>
                                    {isSeller && <td className='text-center'>
                                        (<CurrencyFormatter
                                            amount={(orderSummary.totalPlatformFees)}
                                            locale="en-US"
                                            currency="USD"
                                        />)
                                    </td>}
                                    <td className='text-nowrap'>
                                        <Typography
                                            component="span"
                                            sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                        >
                                            <CurrencyFormatter
                                                amount={!isSeller ? orderSummary.subTotal : orderSummary.orderTotalWithoutPlatformFees}
                                                locale="en-US"
                                                currency="USD"
                                            />
                                        </Typography>
                                    </td>
                                    {!isSeller && <>
                                        <td className='text-nowrap'>
                                            <Typography
                                                component="span"
                                                sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                            >
                                                <CurrencyFormatter
                                                    amount={orderSummary.totalHandlingFees}
                                                    locale="en-US"
                                                    currency="USD"
                                                />
                                            </Typography>
                                        </td>
                                        <td className='text-nowrap'>
                                            <Typography
                                                component="span"
                                                sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                            >
                                                <CurrencyFormatter
                                                    amount={orderSummary.totalServiceFees}
                                                    locale="en-US"
                                                    currency="USD"
                                                />
                                            </Typography>
                                        </td>
                                    </>}
                                    <td className='text-nowrap' colSpan={2}>
                                        <Typography
                                            component="span"
                                            sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                                        >
                                            Total:
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px", paddingLeft: "10px", marginLeft:"auto" }}
                                        >
                                            <CurrencyFormatter
                                                amount={!isSeller ? orderSummary.orderTotal : orderSummary.orderTotalWithoutPlatformFees}
                                                locale="en-US"
                                                currency="USD"
                                            />
                                        </Typography>
                                    </td>
                                </tr> */}
                            </React.Fragment>
                        ))
                    ):(
                        <tr>
                        <td colSpan={12} style={{ textAlign: 'center' }} className='border-0'>
                          <div style={{ height: "222px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Customspinner />
                          </div>
                        </td>
                      </tr>  
                    )}
                    </>
                </tbody>
            </Table>
            
        </div>
        {cartItems.length > 0 && (<div className='total-row--wrapper'>
            <div className={`d-flex justify-content-end ${(!CommonService.isAdmin() && !isSeller)? "flex-column align-items-end" : "flex-row align-items-start"}`}>
                    <div className='d-flex align-items-start'>
                        <div className='total--amount-wrapper' style={{ width: `${columnWidths.priceWidth}px` }}>
                            Subtotal:
                        </div>
                        {isSeller && (<div className='amount-wrapper' style={{ width: `${isSeller ? columnWidths.platformFeesWidth : columnWidths.totalPriceWidth}px`, minWidth: 100, }}>
                            (<CurrencyFormatter
                                amount={(orderSummary.totalPlatformFees)}
                                locale="en-US"
                                currency="USD"
                            />)
                        </div>)}
                        <div className='amount-wrapper' style={{ width: `${isSeller ? columnWidths.platformFeesWidth : columnWidths.totalPriceWidth}px`, minWidth: 100, }}>
                            {/* {isSeller && (<CurrencyFormatter
                                amount={(orderSummary.totalPlatformFees)}
                                locale="en-US"
                                currency="USD"
                            />)} */}
                            <CurrencyFormatter
                                amount={!isSeller ? orderSummary.subTotal : orderSummary.orderTotalWithoutPlatformFees}
                                locale="en-US"
                                currency="USD"
                            />
                        </div>
                        {!isSeller && <><div className='amount-wrapper' style={{ width: `${columnWidths.handlingWidth}px` }}>
                            <CurrencyFormatter
                                amount={orderSummary.totalHandlingFees}
                                locale="en-US"
                                currency="USD"
                            />
                        </div>
                            <div className='amount-wrapper' style={{ width: `${columnWidths.servicesWidth}px` }}>
                                <CurrencyFormatter
                                    amount={orderSummary.totalServiceFees}
                                    locale="en-US"
                                    currency="USD"
                                />
                            </div></>}
                    </div>
                <div className="total--amount-wrapper d-flex justify-content-end pb-4" style={{ width: `${columnWidths.ownerStatusWidth + columnWidths.actionWidth}px`, minWidth: 180 }}>
                    <div style={{ width: (!isSeller && !CommonService.isAdmin ) && (`${columnWidths.handlingWidth}px`) }}>Total:</div>
                     <div style={{ width: (!isSeller && !CommonService.isAdmin ) && (`${columnWidths.servicesWidth}px` )}} className='position-relative ms-2'>
                                <CurrencyFormatter
                                    amount={!isSeller ? orderSummary.orderTotal : orderSummary.orderTotalWithoutPlatformFees}
                                    locale="en-US"
                                    currency="USD"
                                />
                    <p className='shipping-info m-0 position-absolute text-nowrap end-0'>Shipping cost not included.</p>

                            </div>
                    {/* <p className='shipping-info m-0'>Shipping cost not included.</p> */}
                </div>
            </div>
        </div>)}
        {viewDetails &&
            <ViewProductDetail handleViewClose={handleViewClose} inventoryId={inventoryId} />
        }
        </>
    );
};

export default InventoryFileListTable;