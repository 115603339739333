const BASE_URL_USER = process.env.REACT_APP_BASE_URL_USER;
const BASE_URL_INVENTORY = process.env.REACT_APP_BASE_URL_INVENTORY;
const BASE_URL_ORDER = process.env.REACT_APP_BASE_URL_ORDER;
const BASE_URL_MESSAGE = process.env.REACT_APP_BASE_URL_MESSAGE;
const BASE_URL_SOCKET = process.env.REACT_APP_BASE_URL_SOCKET;
const BASE_URL_SUPPORT = process.env.REACT_APP_BASE_URL_SUPPORT;
const REACT_APP_IS_BELT = process.env.REACT_APP_IS_BELT;

export {
  BASE_URL_USER,
  BASE_URL_INVENTORY,
  BASE_URL_ORDER,
  BASE_URL_MESSAGE,
  BASE_URL_SOCKET,
  BASE_URL_SUPPORT,
  REACT_APP_IS_BELT
};

const USER_BASE_URL = `${BASE_URL_USER}/user`;
const INVENTORY_BASE_URL = `${BASE_URL_INVENTORY}/inventory`;
const ORDER_BASE_URL = `${BASE_URL_ORDER}/order`;
const  SUPPORT_BASE_URL = `${BASE_URL_SUPPORT}/support`;

export const Url = {

  REACT_APP_IS_BELT : REACT_APP_IS_BELT,

  BASE_URL_SOCKET: BASE_URL_SOCKET,

  GET_ALL_USERS: `${USER_BASE_URL}/getAllApprovedUsers`,

  LOGIN: `${BASE_URL_USER}/auth/login`,

  LOGOUT: `${BASE_URL_USER}/user/logout`,

  SEND_INVITE: `${BASE_URL_USER}/mail/sendInviteMail`,

  USER_REGISTRATION: `${BASE_URL_USER}/noAuth/register`,

  GET_EMAIL_BY_ID: `${BASE_URL_USER}/noAuth/getEmail`,

  ADD_USER : `${USER_BASE_URL}/addUser`,

  ADD_COMPANY : `${USER_BASE_URL}/addCompany`,

  GET_USER_ROLES : `${BASE_URL_USER}/role/getAllRoles`,

  GET_ALL_PERMISSIONS : `${BASE_URL_USER}/permission`,

  GET_ROLE_NAME_BY_ID : `${BASE_URL_USER}/role/getRole`,

  GET_ALL_SERVICE_TYPE : `${BASE_URL_SUPPORT}/customerSupport/getAllServiceType`,

  FETCH_CUSTOMER_SERVICE_REQUEST_HISTORY : `${BASE_URL_SUPPORT}/customerSupport/fetchCustomerServiceRequestHistory`,

  FETCH_CUSTOMER_SERVICE_REQUEST_DOCS : `${BASE_URL_SUPPORT}/customerSupport/fetchCustomerServiceRequestDocs`,

  FETCH_CUSTOMER_SERVICE_REQUEST_DOCS_FOR_EDITING : `${BASE_URL_SUPPORT}/customerSupport/fetchCustomerServiceRequestDocsForEditing`,

  GET_CUSTOMER_SERVICE_REQUEST : `${BASE_URL_SUPPORT}/customerSupport/getServiceRequest`,

  GET_CUSTOMER_SERVICE_REQUEST_FOR_EDITING : `${BASE_URL_SUPPORT}/customerSupport/getServiceRequestForEditing`,

  DOWNLOAD_CUSTOMER_SERVICE_REQUEST_DOC : `${BASE_URL_SUPPORT}/customerSupport/downloadCustomerServiceRequestDOC`,

  GET_FIELD_BY_SERVICE_TYPE : `${BASE_URL_SUPPORT}/customerSupport/getField`,

  SAVE_CUSTOMER_SUPPORT_REQUEST : `${BASE_URL_SUPPORT}/customerSupport/save`,

  GET_CUSTOMER_SUPPORT_REQUESTS :  `${BASE_URL_SUPPORT}/customerSupport/getAllServiceRequests`,

  DELETE_CUSTOMER_SUPPORT_REQUEST : `${BASE_URL_SUPPORT}/customerSupport/deleteCustomerServiceRequest`,

  DELETE_CUSTOMER_SUPPORT_REQUEST_DOC : `${BASE_URL_SUPPORT}/customerSupport/deleteCustomerServiceRequestDoc`,

  UPDATE_CUSTOMER_SUPPORT_REQUEST : `${BASE_URL_SUPPORT}/customerSupport/updateStatus`,

  GET_USER_LIST_BY_COMPANY_ID: `${USER_BASE_URL}/getUsersListByCompanyId`,

  UPDATE_ROLE_DESCRIPTION: `${BASE_URL_USER}/role/updateDescription`,

  SAVE_ROLES_AND_PERMISSIONS : `${BASE_URL_USER}/permission`,

  SAVE_SUPPORT_REQUEST : `${SUPPORT_BASE_URL}/save`,

  GET_SUPPORT_REQUEST :  `${SUPPORT_BASE_URL}/getAllSupportRequests`,

  GET_ALL_COMMENTS : `${SUPPORT_BASE_URL}/getComments`,

  OPEN_SUPPORT_REQUEST_COUNT : `${SUPPORT_BASE_URL}/openRequestCount`,

  CLOSE_SUPPORT_REQUEST_COUNT : `${SUPPORT_BASE_URL}/closeRequestCount`,

  UPDATE_SUPPORT_REQUEST_STATUS : `${SUPPORT_BASE_URL}/updateStatus`,

  DOWNLOAD_SUPPORT_REQUEST_FILE : `${SUPPORT_BASE_URL}/downloadFile`,

  GET_ALL_ROLES: `${BASE_URL_USER}/noAuth/getAllRoles`,

  GET_SUBMITTED_USERS: `${USER_BASE_URL}/getAllSubmittedUsers`,

  UPDATE_USER_STATUS: `${USER_BASE_URL}/updateStatus`,

  BULK_REMOVE_PROFILES: `${USER_BASE_URL}/bulkRemoveById`,

  GET_REJECTED_USER_COUNT: `${USER_BASE_URL}/getRejectedUserCount`,

  GET_ACTIVE_USER_COUNT: `${USER_BASE_URL}/getAllActiveUsersCount`,

  GET_ALL_REJECTED_USERS: `${USER_BASE_URL}/getRejectedUser`,

  GET_CITY_AND_STATE: `${BASE_URL_USER}/usps/cityStateLookup`,

  SAMPLE_ORDER_REQUEST: `${BASE_URL_ORDER}/sampleRequest/sampleOrderRequest`,

  IS_SAMPLE_ORDER_REQUEST_CREATED: `${BASE_URL_ORDER}/sampleRequest/isSampleRequestCreated`,

  UPDATE_SAMPLE_ORDER_REQUEST: `${BASE_URL_ORDER}/sampleRequest/updateSampleRequest`,

  SAVE_PURCHASE_OFFER: `${BASE_URL_ORDER}/purchaseOffer/purchaseOfferRequest`,

  IS_PURCHASE_OFFER_REQUEST_CREATED: `${BASE_URL_ORDER}/purchaseOffer/isPurchaseOfferCreated`,

  UPDATE_PURCHASE_OFFER_REQUEST: `${BASE_URL_ORDER}/purchaseOffer/updatePurchaseOfferRequest`,

  RESET_PASSWORD: `${BASE_URL_USER}/noAuth/savePassword`,

  GET_ALL_INVENTORY: `${INVENTORY_BASE_URL}/getProductsByFilters`,

  GET_INVENTORY_BY_ID: `${INVENTORY_BASE_URL}/getProductById/`,

  EDIT_INVENTORY: `${INVENTORY_BASE_URL}/editInventory`,

  GET_INVENTORY_LIST_BY_COMPANY_ID: `${INVENTORY_BASE_URL}/getInventoryList`,

  SEARCH_INVENTORY: `${INVENTORY_BASE_URL}/search`,

  UPLOAD_CSV: `${INVENTORY_BASE_URL}/upload`,

  GET_ALL_TYPE_AND_FACILITY: `${INVENTORY_BASE_URL}/getAllTTBtypesAndFacilities`,

  GET_USER_BY_USER_ID: `${USER_BASE_URL}/getById/`,

  GET_INVENTORY_CSV: `${BASE_URL_INVENTORY}/inventoryCSV/getInventorySellRequests`,

  READ_CSV: `${BASE_URL_INVENTORY}/inventoryCSV/readCSV`,

  GET_SUBMITTED_USER_COUNT: `${USER_BASE_URL}/getCountForSubmittedUsers`,

  UPDATE_INVENTORY_CSV: `${BASE_URL_INVENTORY}/inventoryCSV/updateInventoryCsv`,

  UPDATE_STATUS_IN_BULK: `${USER_BASE_URL}/updateStatusForMultipleUsers`,

  GET_INVENTORY_CSV_WITH_DOCS: `${BASE_URL_INVENTORY}/inventoryCSV/getInventorySellRequestWithDocs`,

  DOWNLOAD_CSV: `${BASE_URL_INVENTORY}/inventoryCSV/downloadCSV`,

  DOWNLOAD_INVENTORY_TEMPLATE: `${BASE_URL_INVENTORY}/inventoryCSV/downloadInventoryTemplate`,

  DOWNLOAD_DOC: `${BASE_URL_INVENTORY}/inventoryCSV/downloadDOC`,

  DOWNLOAD_ZIP_WITH_DOCS: `${BASE_URL_INVENTORY}/inventoryCSV/getZipWithDocs`,

  GET_LOGIN_HISTORY: `${BASE_URL_USER}/getLoginHistory`,

  // GET_LOCATION_DETAILS: `https://geolocation-db.com/json/709bbed0-fb73-11ee-8070-55ec9ebae196`,
  GET_LOCATION_DETAILS: `https://api.ipbase.com/v1/json/`,

  GET_ORDER_ITEMS: `${BASE_URL_ORDER}/orderItems/getOrderItems`,

  GET_ORDER_ITEM_BY_ID : `${BASE_URL_ORDER}/orderItems/getOrderItem`,

  UPDATE_ORDER_ITEM: `${BASE_URL_ORDER}/orderItems/updateOrderItem`,
  
  UPDATE_ORDER_ITEM_STATUS: `${BASE_URL_ORDER}/orderItems/updateOrderItemStatus`,

  SAVE_ORDER_ITEMS: `${BASE_URL_ORDER}/orderItems/save`,

  DELETE_ITEM: `${BASE_URL_ORDER}/orderItems/delete`,

  GET_ORDER_ITEM_BY : `${BASE_URL_ORDER}/orderItem`,

  PLACE_ORDER: `${BASE_URL_ORDER}/orderItems/placeOrder`,

  GET_ACTIVE_ORDERS: `${BASE_URL_ORDER}/orderItems/getActiveOrders`,

  GET_SAMPLE_REQUEST: `${BASE_URL_ORDER}/sampleRequest/getActiveSampleRequests`,

  GET_OFFER_REQUEST: `${BASE_URL_ORDER}/purchaseOffer/getActiveOfferRequest`,

  ADD_TO_WISH_LIST: `${BASE_URL_ORDER}/wishList/addToWishlist`,

  REMOVE_FROM_WISH_LIST: `${BASE_URL_ORDER}/wishList/removeFromWishlist`,

  GET_WISH_LIST: `${BASE_URL_ORDER}/wishList/getWishlist`,

  IS_INVENTORY_IN_WISH_LIST: `${BASE_URL_ORDER}/wishList/isInventoryInWishlist`,

  IS_INVENTORY_IN_CART_FOR_USER: `${BASE_URL_ORDER}/orderItems/isInventoryInCartForUser`,

  GET_ACCEPTED_ORDERS: `${BASE_URL_ORDER}/orderItems/getAcceptedOrders`,

  GET_PENDING_ORDERS: `${BASE_URL_ORDER}/orderItems/getPendingOrders`,

  GET_PENDING_ORDERS_COUNT: `${BASE_URL_ORDER}/orderItems/getPendingOrdersCount`,

  UPDATE_ORDER_STATUS_BY_ORDER_ID: `${BASE_URL_ORDER}/orderItems/updateOrderStatus`,

  GET_ORDERS: `${BASE_URL_ORDER}/orderItems/getOrders`,

  CHANGE_ORDER_STATUS: `${BASE_URL_ORDER}/orderItems/changeOrderStatus`,

  CHANGE_OFFER_STATUS: `${BASE_URL_ORDER}/purchaseOffer/changeOfferStatus`,

  CHANGE_OWNER_STATUS_FOR_OFFER: `${BASE_URL_ORDER}/purchaseOffer/updateOwnerStatus`,

  GET_ALL_BARRELS: `${BASE_URL_ORDER}/purchaseOffer/getAllBarrels`,

  GET_RESERVED_BARRELS_COUNT: `${BASE_URL_ORDER}/purchaseOffer/getReservedBarrelsCount`,

  CHANGE_SAMPLE_REQUEST_STATUS: `${BASE_URL_ORDER}/sampleRequest/updateStatus`,

  UPDATE_SAMPLE_REQUEST_OWNER_STATUS: `${BASE_URL_ORDER}/sampleRequest/updateOwnerStatus`,

  GET_ORDER_ITEMS_BY_ORDER_ID: `${BASE_URL_ORDER}/orderItems/getItemsByOrderId`,

  GET_ORDER_SUMMARY_BY_ORDER_ID: `${BASE_URL_ORDER}/orderItems/getOrderSummary`,

  GET_ACTIVE_MESSAGES_BY_TYPE: `${BASE_URL_MESSAGE}/message/active/message`,

  SAVE_MESSAGES: `${BASE_URL_MESSAGE}/message/save`,

  GET_GROUP_MESSAGES: `${BASE_URL_MESSAGE}/message/getMessageGroups`,

  GET_ADMIN_MESSAGE_GROUP: `${BASE_URL_MESSAGE}/message/getAdminMessageGroups`,

  GET_OPEN_REQUEST_COUNT: `${BASE_URL_USER}/dashboard/getOpenRequestsCount`,

  GET_RECENT_REQUEST_COUNT: `${BASE_URL_USER}/dashboard/getRecentRequestsData`,

  GET_REQUEST_DATA: `${BASE_URL_USER}/dashboard/getRequestsData`,

  FETCH_UNREAD_MESSAGES: `${BASE_URL_MESSAGE}/message/fetchUnreadMessagesForLoggedInUser`,

  GET_CSVs_BY_SELL_REQUEST_ID: `${BASE_URL_INVENTORY}/inventoryCSV/getCSVListByReqId`,

  GET_SELL_REQ_BY_ID_WITH_COMPANY: `${BASE_URL_INVENTORY}/inventoryCSV/getSellReqById`,

  GET_DOCS_BY_SELL_REQUEST_ID: `${BASE_URL_INVENTORY}/inventoryCSV/fetchInventorySellRequestDoc`,

  GET_HISTORY_BY_SELL_REQUEST_ID: `${BASE_URL_INVENTORY}/inventoryCSV/fetchInventoryCsvHistory`,

  UPLOAD_DOC: `${INVENTORY_BASE_URL}/uploadDocs`,

  FETCH_COMPANIES_LIST: `${BASE_URL_USER}/company/getAllApprovedCompanies`,

  GET_ALL_ACTIVE_AND_INACTIVE_COMPANIES: `${BASE_URL_USER}/company/getAllActiveAndInactiveCompanies`,

  FETCH_COMPANIES_OPEN_REQUEST_COUNT: `${BASE_URL_USER}/company/getOpenRequestsCount`,

  FETCH_COMPANIES_USER_DATA: `${BASE_URL_USER}/company/getCompanyUsers`,
 
  FETCH_SAMPLE_REQUEST_BY_ID: `${BASE_URL_ORDER}/sampleRequest/getSampleRequestById`,

  FETCH_COMPANIES_BY_ID: `${BASE_URL_USER}/company/getCompanyById`,

  DELETE_COMPANIES_BY_ID: `${BASE_URL_USER}/company/deleteCompany`,
  
  UPLOAD_SAMPLE_REQUEST_DOC: `${BASE_URL_ORDER}/sampleRequest/uploadSampleRequestDoc`,

  UPLOAD_CUSTOMER_SERVICE_REQUEST_DOC: `${BASE_URL_SUPPORT}/customerSupport/uploadCustomerServiceRequestDoc`,

  FETCH_UNREAD_MESSAGE_COUNT_FOR_CUSTOMER_SERVICE_REQUEST: `${BASE_URL_MESSAGE}/message/getUnreadMessageCountForCustomerServiceRequest`,

  FETCH_UNREAD_MESSAGE_COUNT_FOR_SELL_REQUEST: `${BASE_URL_MESSAGE}/message/getUnreadMessageCountForSellRequest`,

  FETCH_ALL_BARRELS_COUNT: `${INVENTORY_BASE_URL}/getAllBarrelsCount`,

  FETCH_SAMPLE_REQUEST_DOCS: `${BASE_URL_ORDER}/sampleRequest/fetchSampleRequestDocs`,

  FETCH_SAMPLE_REQUEST_HISTORY: `${BASE_URL_ORDER}/sampleRequest/fetchSampleRequestHistory`,

  DOWNLOAD_SAMPLE_REQUEST_DOC: `${BASE_URL_ORDER}/sampleRequest/downloadSampleRequestDOC`,

  UPLOAD_ORDER_REQUEST_DOC: `${BASE_URL_ORDER}/orderProcessing/uploadOrderRequestDoc`,

  FETCH_ORDER_REQUEST_DOCS: `${BASE_URL_ORDER}/orderProcessing/fetchOrderRequestDocs`,

  FETCH_ORDER_REQUEST_HISTORY: `${BASE_URL_ORDER}/orderProcessing/fetchOrderRequestHistory`,

  DOWNLOAD_ORDER_REQUEST_DOC: `${BASE_URL_ORDER}/orderProcessing/downloadOrderRequestDOC`,

  SHOW_PDF_URL: `${BASE_URL_INVENTORY}/inventoryCSV/viewPDF`,
 
  SHOW_PDF_FOR_ORDER_URL: `${BASE_URL_ORDER}/orderProcessing/viewPDF`,

  GET_COMPANY_WISE_SELL_REQUEST: `${BASE_URL_INVENTORY}/inventoryCSV/getInventorySellRequestsByCompanyId`,

  GET_MY_INVENTORIES: `${INVENTORY_BASE_URL}/getMyInventories`,

  GET_INVENTORY_CHANGE_REQUESTS: `${INVENTORY_BASE_URL}/getInventoryChangeRequests`,

  GET_ALL_INVENTORY_CHANGE_REQUESTS: `${INVENTORY_BASE_URL}/getAllInventoryChangeRequests`,

  GET_ALL_DUPLICATE_INVENTORY: `${INVENTORY_BASE_URL}/duplicateInventory`,

  GET_INVENTORY_CHANGE_REQUEST_BY_ID: `${INVENTORY_BASE_URL}/getInventoryChangeRequest`,

  UPDATE_INVENTORY_CHANGE_REQUEST_STATUS: `${INVENTORY_BASE_URL}/updateInventoryChangeRequestStatus`,

  GET_COMPANY_WISE_SAMPLE_REQUEST: `${BASE_URL_ORDER}/sampleRequest/getSampleRequestsByCompanyId`,

  GET_COMPANY_WISE_OFFER_REQUEST: `${BASE_URL_ORDER}/purchaseOffer/getOfferRequestByCompanyId`,

  UPDATE_RESERVED_BARREL_DETAILS_FOR_OFFER_REQUEST : `${BASE_URL_ORDER}/purchaseOffer/updateReservedBarrelDetails`,

  GET_COMPANY_WISE_ORDER_REQUEST: `${BASE_URL_ORDER}/orderItems/getOrderRequestByCompanyId`,

  GET_COMPANY_WISE_ORDER_REQUEST_PAGINATION: `${BASE_URL_ORDER}/orderItems/getOrderRequestByCompanyIdPagination`,

  REMOVE_ITEM_FROM_CART: `${BASE_URL_ORDER}/orderItems/removeItem`,

  FETCH_OFFER_REQUEST_BY_ID: `${BASE_URL_ORDER}/purchaseOffer/getOfferRequestById`,

  FETCH_OFFER_REQUEST_DOCS: `${BASE_URL_ORDER}/purchaseOffer/fetchOfferRequestDocs`,

  FETCH_OFFER_REQUEST_HISTORY: `${BASE_URL_ORDER}/purchaseOffer/fetchOfferRequestHistory`,

  UPLOAD_OFFER_REQUEST_DOC: `${BASE_URL_ORDER}/purchaseOffer/uploadOfferRequestDoc`,

  DOWNLOAD_OFFER_REQUEST_DOC: `${BASE_URL_ORDER}/purchaseOffer/downloadOfferRequestDOC`,

  GET_ALL_UNSEEN_NOTIFICATION_COUNT: `${BASE_URL_ORDER}/notification/getUnseenMessageCount`,

  FETCH_ALL_NOTIFICATION: `${BASE_URL_ORDER}/notification/getAllNotifications`,

  UPDATE_IS_SEEN_FLAG : `${BASE_URL_ORDER}/notification/updateIsSeenFlag`,

  UPDATE_USER: `${USER_BASE_URL}/updateUser`,
  
  CHANGE_PASSWORD: `${USER_BASE_URL}/changePassword`,

  UPLOAD_PROFILE_IMAGE: `${USER_BASE_URL}/uploadProfileImage`,

  GET_PROFILE_IMAGE: `${USER_BASE_URL}/getProfileImage`,

  SEND_RESET_PASSWORD_LINK: `${BASE_URL_USER}/noAuth/sendResetPasswordMail`,

  REMOVE_PROFILE_IMAGE: `${USER_BASE_URL}/removeProfileImage`,

  FETCH_ALL_COMPANIES_WITH_PAGINATION: `${BASE_URL_USER}/company/getAllCompaniesWithPagination`,

  DOWNLOAD_CUSTOMER_SERVICE_REQUEST_VALUE_DOC : `${BASE_URL_SUPPORT}/customerSupport/downloadCustomerServiceRequestValueDOC`,

  UPDATE_CUSTOMER_SUPPORT_REQUEST_VALUE : `${BASE_URL_SUPPORT}/customerSupport/updateCustomerServiceRequestValue`,
}

