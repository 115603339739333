import { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import { RangeSlider as SpectrumRangeSlider } from '@adobe/react-spectrum';
import { useControlledState } from '@react-stately/utils'; // To synchronize with SliderState

// interface AgeRangeSliderProps {
//   attribute: string;
//   min?: number;
//   max?: number;
//   precision?: number;
//   defaultRefinement?: number[];
// }

interface SliderValue {
  start: number;
  end: number;
}

export function AgeRangeSlider(props): JSX.Element {
  const { range, canRefine, refine } = useRange(props);
  const random = 0;
  const [minAge, setMinAge] = useState<number>(0);
  const [maxAge, setMaxAge] = useState<number>(100);
  const [value, setValue] = useState<SliderValue>({ start: 0, end: 100 });

  // Utility function to calculate the difference in days from a timestamp
  function getDaysDifference(timestamp: number): number {
    const date = new Date(timestamp);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate.getTime() - date.getTime());
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  }

  // Utility function to get a timestamp for a specific number of days ago
  function getTimestampFromDaysAgo(days: number): number {
    const currentDate = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00Z');
    const pastDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000);
    return pastDate.getTime();
  }

  useEffect(() => {
    setMinAge(0);
    setMaxAge(100);
    setValue({ start: 0, end: 100 });
  }, []);

  // Update state when range changes
  useEffect(() => {
    if (range.min !== undefined && range.max !== undefined) {
      const minDaysDifference = getDaysDifference(range.max);
      const maxDaysDifference = getDaysDifference(range.min);

      setMinAge(minDaysDifference);
      setMaxAge(maxDaysDifference);
      setValue({ start: minDaysDifference, end: maxDaysDifference });
    }
  }, [range.min, range.max, random]);

  // Handle refinement when slider value changes
  const handleRefine = ({ start, end }: SliderValue) => {
    const startTimestamp = getTimestampFromDaysAgo(start);
    const endTimestamp = getTimestampFromDaysAgo(end);
    refine([endTimestamp, startTimestamp]);
  };

  return (
    <SpectrumRangeSlider
      label="Select age"
      minValue={minAge}
      maxValue={maxAge}
      value={value}
      onChange={setValue}
      onChangeEnd={handleRefine}
      isDisabled={!canRefine}
      getValueLabel={({ start, end }) => {
        const formatAge = (totalDays: number) => {
          const years = Math.floor(totalDays / 365);
          const months = Math.floor((totalDays % 365) / 30);
          return `${years}y ${months}m`;
        };

        const youngerAge = formatAge(Math.min(start, end));
        const olderAge = formatAge(Math.max(start, end));
        return `${youngerAge} - ${olderAge}`;
      }}
    />
  );
}
