import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Service } from "../../../Service/ApiService";
import Spinner from "../../Common/Spinner";
import Typography from '@mui/material/Typography';
import './ViewOrEditProfile.scss';
import ProfileAvatarIcon from '../../../assets/images/logo.svg';
import AlterImage from '../../../assets/images/alter_image.png';
import PencilSimpleRoundedOutlineIcon from '../../../assets/images/icons/PencilSimpleRoundedOutline.svg';
import TrashRoundedOutlineIcon from '../../../assets/images/icons/TrashRoundedOutline.svg';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import useFormValidation from "../../Dynamic/FormValidation";
import InputMask from "react-input-mask";
import { CommonService } from "../../../Service/CommonService";
import { Modal } from "react-bootstrap";
import ImageUploader from "../../Dynamic/ImageUploader";
import ChangePassword from "../ChangePassword";
import Swal from "sweetalert2";
import DeleteItemIcon from "../../../assets/images/icons/DeleteItem.png";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ViewOrEditProfile = () => {
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(false);
    const [isValidZipcode, setValidZipcode] = useState(false);
    const [isZipcodeLoading, setZipcodeLoading] = useState(false);
    const dateInputRef = useRef(null);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => { setShow(false); };
    const [isEditDetail, setEditDetail] = useState(false);
    const handleEditDetail = () => { setEditDetail(true); setChangePassword(false); }
    const handleViewDetail = () => { setEditDetail(false); };
    const [isChangePassword, setChangePassword] = useState(false);
    const showChangePassword = () => { setChangePassword(true); setEditDetail(false); }
    const hideChangePassword = () => setChangePassword(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const imageFormData = new FormData();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const location = useLocation();
    const currentPath = location.pathname;
    const history = useHistory();


   
        
    let ImageDetail = {};
    const initialFormData = {
        firstName: "",
        lastName: "",
        birthdate: "",
        zipcode: "",
        phoneNumber: "",
        email: "",
        password: "",
        retypePassword: "",
        companyName: "",
        companyEmail: "",
        contactNumber: "",
        contactPerson: "",
        companyId: "",
        siteId: id,
        roleId: "",
        userStatus: "ACCEPTED",
        city: "",
        state: "",
        streetAddress: "",
        isBroker: false, 
    };

    const { formData, errors, handleChange, handleBlur, setErrors, validateForm, setFormData } = useFormValidation(initialFormData);

    const handleDateClick = () => {
        if (dateInputRef.current) {
            dateInputRef.current?.showPicker(); // This will trigger the date picker to open
        }
    };

    const handleIsBrokerChange = (event) => {
        setFormData(prevData => ({
          ...prevData,
          isBroker: event.target.checked
        }));
      };

    const getMinAgeDate = (): string => {
        const today = new Date();
        const minAgeYear = today.getFullYear() - 21;
        return `${minAgeYear}-${String(today.getMonth() + 1).padStart(
            2,
            "0"
        )}-${String(today.getDate()).padStart(2, "0")}`;
    };

    const minAgeDate = getMinAgeDate();

    const validateZipcode = async (zipcode: string): Promise<boolean> => {
        setZipcodeLoading(true);
        try {
            const response = await Service.getCityAndState(zipcode);
            const { city, state } = response.data.zipCode;

            if (city && state) {
                setFormData((prevState) => ({
                    ...prevState,
                    city,
                    state,
                    zipcode,
                }));
                setErrors((prevErrors) => ({ ...prevErrors, zipcode: "" }));
                setValidZipcode(true);
                return true;
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    city: "",
                    state: "",
                    zipcode,
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    zipcode: "Please enter a valid zipcode",
                }));
                setValidZipcode(false);
                return false;
            }
        } catch (error) {
            setFormData((prevState) => ({
                ...prevState,
                city: "",
                state: "",
                zipcode,
            }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                zipcode: "Please enter a valid zipcode",
            }));
            setValidZipcode(false);
            return false;
        } finally {
            setZipcodeLoading(false);
        }
    };

    const handleZipcodeBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
        const zipcode = e.target.value;
        await validateZipcode(zipcode);
    };

    const fetchUserById = async () => {
        const response: any = await Service.getUserById(id);
        setFormData(response.data);
    };

    // Validate user fields
    const validateUserFields = async (): Promise<boolean> => {
        let isValidationPass = true;

        const requiredFields = [
            { field: "firstName", error: "Please enter first name" },
            { field: "birthdate", error: "Please enter birthdate" },
            { field: "email", error: "Please enter email" },
            { field: "companyName", error: "Please enter company name" },
        ];

        requiredFields.forEach(({ field, error }) => {
            if (formData[field]?.trim().length === 0 || errors[field]) {
                if (formData[field]?.trim().length === 0) {
                    setErrors((prevState) => ({ ...prevState, [field]: error }));
                }
                isValidationPass = false;
            }
        });

        if (formData?.zipcode?.length === 0 || errors?.zipcode) {
            if (formData?.zipcode?.length === 0) {
                setErrors((prevState) => ({
                    ...prevState,
                    zipcode: "Please enter zipcode",
                }));
            }
            isValidationPass = false;
        } else {
            const result = await validateZipcode(formData?.zipcode?.trim());
            if(!result) {
                isValidationPass = false;
            }
        }

        return isValidationPass;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setIsLoading(true);

        const isFormValid = await validateUserFields();

        if (!isFormValid) {
            setIsLoading(false);
            return;
        }

        try {
            const response = await Service.updateUser(formData);
            if (response.status === 200) {
                CommonService.Toast.fire({
                    title: "User Updated Successfully",
                    icon: "success",
                });
            }
        } catch (error: any) {
            CommonService.Toast.fire({
                title: error.response.data.errorMessage,
                icon: "error",
            });
        } finally {
            fetchUserById();
            handleViewDetail();
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserById();
        getProfileImage();
    }, [id]);

    // Convert base64 to a file
    const base64ToFile = (base64: string, filename: string): void => {
        if (base64 && filename) {
            const arr = base64.split(',');
            const mime = arr[0].match(/:(.*?);/)?.[1] || '';
            const bstr = atob(arr[1]);
            const u8arr = new Uint8Array(bstr.length);

            for (let i = 0; i < bstr.length; i++) {
                u8arr[i] = bstr.charCodeAt(i);
            }

            const file = new File([u8arr], filename, { type: mime });

            // Create form data to send to the backend
            imageFormData.append('image', file);
           
            ImageDetail = {
                id: id,
                fileName: filename,
            };

            handleSaveImage();
        }else{
            setSelectedFile(null);
        }
        handleClose();
    };

    const handleSaveImage = async () => {
        try {
            setIsLoading(true);

            imageFormData.append(
                "param",
                new Blob([JSON.stringify(ImageDetail)], {
                    type: "application/json",
                })
            );

            const response = await Service.uploadProfileImage(imageFormData);
            if (response.status === 200) {
                CommonService.Toast.fire({
                    title: "Profile picture updated successfully",
                    icon: "success",
                });
            }else{
                CommonService.Toast.fire({
                    title: "There is some technical problem occured",
                    icon: "error",
                }); 
            }
            localStorage.removeItem("profile");
            handleClose();
        } catch (error) {
            console.error('Error occured on profile picture uploading:', error);
        } finally {
            getProfileImage();
            setSelectedFile(null);
            setIsLoading(false);
        }
    };

    // const getProfileImage = async () => {
    //     if (CommonService.getProfile() == null) {
    //         const requestDto = {
    //             id: id
    //         };
    //         try {

    //             const response: any = await Service.getProfileImage(requestDto);
    //             const blob = response.data as Blob;
    //             if (blob.size != 0) {
    //                 // Create a URL for the Blob and set it as the image source
    //                 const imageUrl = URL.createObjectURL(blob);
    //                 setImageSrc(imageUrl);
    //                 localStorage.setItem("profile", imageUrl);

    //                 //Dispatch custom event
    //                 const event = new CustomEvent('profileImageChanged');//, { detail: imageUrl });
    //                 window.dispatchEvent(event);
    //             } else {
    //                 setImageSrc(null);
    //             }
    //         } catch (error) {
    //             console.error("Error occured on fetching profile picture:", error);
    //         }
    //     } else {
    //         setImageSrc(CommonService.getProfile());
    //     }
    // };

    const getProfileImage = async () => {
        
        let isUserDetailsRoute = currentPath.includes('/user-detail');

        if (CommonService.getProfile() == null || isUserDetailsRoute) {
          const requestDto = { id: id };
          try {
            const response: any = await Service.getProfileImage(requestDto);
            const blob = response.data as Blob;
            if (blob.size != 0) {
              // Create a URL for the Blob and set it as the image source
              const imageUrl = URL.createObjectURL(blob);
              setImageSrc(imageUrl);
              
              // Only set in localStorage if not on the user-details route
              if (!isUserDetailsRoute) {
                localStorage.setItem("profile", imageUrl);

                // Dispatch custom event
                const event = new CustomEvent('profileImageChanged');
                window.dispatchEvent(event);
              }
            } else {
              setImageSrc(null);
            }
          } catch (error) {
            console.error("Error occurred on fetching profile picture:", error);
          }
        } else {
          setImageSrc(CommonService.getProfile());
        }
      };

    const removeProfileImage = async () => {

        let isUserDetailsRoute = currentPath.includes('/user-detail');

        const requestDto = {
            id: id,
            fileName: null
        };
        try {

            const response: any = await Service.removeProfileImage(requestDto);
            if (response.status === 200) {
                CommonService.Toast.fire({
                    title: "Profile picture removed successfully ",
                    icon: "success",
                });
                setImageSrc(null);

                if(!isUserDetailsRoute)
                    localStorage.removeItem("profile");

                //Dispatch custom event
                const event = new CustomEvent('profileImageChanged');//, { detail: null });
                window.dispatchEvent(event);
                handleClose();
            }
        } catch (error) {
            console.error("Error occured on profile picture removed:", error);
        } finally {
            getProfileImage();
        }
    };

    const removePicConfirmation = () => {
        Swal.fire({
        html: `
        <style>
            .custom-swal .icon-container {
                background-color: #ffebee;
                border-radius: 50%;
                width: 105px;
                height: 105px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto 20px;
            }
            .custom-swal .icon-container i {
                color: #ff3e4e;
                font-size: 24px;
            }
            .custom-swal h2 {
                font-size: 20px;
                margin-bottom: 10px;
                margin-top: 20px;
            }
            .custom-swal p {
                font-size: 14px;
                color: #666;
                margin-bottom: 20px;
            }
            .custom-swal .button-container {
                margin-top: 40px;
                display: flex;
                justify-content: center;
                gap: 20px;
            }
            .custom-swal button {
                border: none;
                padding: 10px 50px;
                border-radius: 30px;
                font-size: 14px;
                cursor: pointer;
            }
            .custom-swal .cancel-btn {
                background-color: #e0e0e0;
                color: #333;
            }
            .custom-swal .delete-btn {
                background-color: #ec2323;
                color: white;
            }
            
        </style>
        <div class="custom-swal">

        <div class="icon-container" id="iconContainer">
            </div>

            <h2>Are you sure you want to remove the profile picture?</h2>
            <p></p>
            <div class="button-container">
            <button class="cancel-btn" id="cancelBtn">Cancel</button>
            <button class="delete-btn" id="deleteBtn">Delete</button>
            </div>
        </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: true,
        focusCancel: true,
        customClass: {
            popup: "custom-popup",
        },
        backdrop: `rgba(0,0,0,0.4)`,
        width: "500px",
        background: "#f8f8f8",
        didOpen: () => {
            // Add the image after the popup has been rendered
            const iconContainer = Swal.getPopup()!.querySelector("#iconContainer");
            if (iconContainer) {
            const img = document.createElement("img");
            img.src = DeleteItemIcon;
            img.width = 47;
            img.height = 51;
            iconContainer.appendChild(img);
            }

            // Add event listeners to the buttons
            const cancelBtn = Swal.getPopup()!.querySelector("#cancelBtn");
            const deleteBtn = Swal.getPopup()!.querySelector("#deleteBtn");

            cancelBtn?.addEventListener("click", () => {
             Swal.close();
            });

            deleteBtn?.addEventListener("click", () => {
                removeProfileImage();
                Swal.close();
            });
        },
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files?.[0];
            setSelectedFile(file);

            // Clear the input value to allow selecting the same file again
            event.target.value = '';
        }
    };

    const handleBack = () => {
        history.goBack();
      }

    return (
        <>
            <div className={`usersList ${CommonService.isAdmin() ? "profile-admin": "profile-user"}`}>
                <div className="usersList__header--wrapper d-flex flex-wrap  align-items-center">
                {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton> )}
                    <div className="header--title">
                        <h1 className="title mb-0 ms-2">
                            Profile Details
                        </h1>
                    </div>
                </div>
                <div className="profile-wrapper rounded-2 bg-white">
                    <div className="profile-details mt-4 mb-3">
                        <div className="row">
                            <div className="col-xl-3">
                                <div className="py-5">
                                    <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                                        <span className="profile-avatar position-relative">
                                            <img src={imageSrc || AlterImage} alt="ProfileAvatarIcon" className='img-fluid rounded-circle'
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.onerror = null; // Prevent infinite loop if the fallback image fails
                                                    target.src = AlterImage;
                                                    localStorage.removeItem("profile");
                                                }}
                                            />
                                            <span className="profile-edit position-absolute" onClick={() => fileInputRef.current?.click()}> 
                                                <img src={PencilSimpleRoundedOutlineIcon} alt="ProfileAvatarIcon" width={32} height={32} />
                                                <label htmlFor="upload-input" className='upload-input-label' style={{ cursor: 'pointer' }}>
                                                    <input
                                                        id="upload-input"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                        ref={fileInputRef}
                                                    />
                                                </label>
                                            </span>
                                            {imageSrc != null && (<span className="profile-delete position-absolute cursor-pointer" onClick={removePicConfirmation}>
                                                    <img src={TrashRoundedOutlineIcon} alt="ProfileAvatarIcon" width={32} height={32} />
                                            </span>)}
                                        </span>
                                        <span className="profile-name mt-2 fs-5 fw-semibold">
                                            {formData.firstName} {formData.lastName}
                                        </span>
                                    </div>
                                    <div className="profile-menu rounded-3 p-2 cursor-pointer">
                                        <div className='p-2 ' onClick={handleEditDetail}>
                                            <PersonOutlineIcon sx={{ mx: 1 }} />
                                            Edit Profile
                                        </div>
                                        <div className='p-2' onClick={showChangePassword} >
                                            <LockOutlinedIcon sx={{ mx: 1 }} />
                                            Change Password
                                        </div>
                                        {/* <div className='p-2'>
                                    <SettingsIcon sx={{ mx: 1 }} />
                                    Settings
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9">

                                {(!isEditDetail && !isChangePassword) && (<div className="row m-0">
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Email
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Phone Number
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.phoneNumber}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Birthdate
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.birthdate}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Company Name
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.companyName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Zip Code
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.zipcode}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Street Address
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.streetAddress}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="details-card py-3 h-100">
                                            <div className="title position-relative pb-1 mb-3">
                                                Broker
                                                <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                                            </div>
                                            <div className="desc">
                                                {formData.isBroker? "Yes" : "No"}
                                            </div>
                                        </div>
                                    </div>
                                </div>)}

                                {(isEditDetail && !isChangePassword) && (<form className="form__inner row d-flex mx-auto py-lg-5" onSubmit={onSubmit} noValidate>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="firstName" className="form-label">
                                            First Name <span className="star-required text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Enter first name"
                                            value={formData.firstName}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^A-Za-z ]/g, '');
                                                handleChange({
                                                    target: {
                                                        name: e.target.name,
                                                        value: value
                                                    }
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.firstName ? "is_invalid" : ""
                                                }`}
                                        />
                                        {errors.firstName && (
                                            <div className="error-message">{errors.firstName}</div>
                                        )}
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="lastName" className="form-label">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Enter last name"
                                            value={formData.lastName}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^A-Za-z ]/g, '');
                                                handleChange({
                                                    target: {
                                                        name: e.target.name,
                                                        value: value
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="lastName" className="form-label">
                                            Email <span className="star-required text-danger">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.email ? "is_invalid" : ""
                                                }`}
                                        />
                                        {errors.email && (
                                            <div className="error-message">{errors.email}</div>
                                        )}
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="phoneNumber" className="form-label">
                                            Phone Number
                                        </label>
                                        <InputMask
                                            mask="(999) 999-9999"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                        >
                                            {(inputProps) => (
                                                <input
                                                    type="text"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    placeholder="Enter phone number"
                                                    className="form-control"
                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="birthdate" className="form-label">
                                            Birthdate (Must be 21+ years) <span className="star-required text-danger">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            id="birthdate"
                                            name="birthdate"
                                            placeholder="Birthdate"
                                            value={formData.birthdate}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            max={minAgeDate}
                                            // ref={dateInputRef}
                                            onClick={handleDateClick}
                                            className={`form-control ${errors.birthdate ? "is_invalid" : ""
                                                }`}
                                        />
                                        {errors.birthdate && (
                                            <div className="error-message">{errors.birthdate}</div>
                                        )}
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="companyName" className="form-label">
                                            Company Name <span className="star-required text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="companyName"
                                            name="companyName"
                                            placeholder="Enter Company Name"
                                            value={formData.companyName}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^A-Za-z ]/g, '');
                                                handleChange({
                                                    target: {
                                                        name: e.target.name,
                                                        value: value
                                                    }
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.companyName ? "is_invalid" : ""
                                                }`}
                                        />
                                        {errors.companyName && (
                                            <div className="error-message">{errors.companyName}</div>
                                        )}
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="zipcode" className="form-label">
                                            Zip Code <span className="star-required text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="zipcode"
                                            name="zipcode"
                                            placeholder="Enter Zip Code"
                                            value={formData.zipcode}
                                            onChange={handleChange}
                                            onBlur={handleZipcodeBlur}
                                            className={`form-control ${errors.zipcode ? "is_invalid" : ""
                                                }`}
                                        />
                                        {errors.zipcode && (
                                            <div className="error-message">{errors.zipcode}</div>
                                        )}
                                        {formData.city && formData.state && (
                                            <p className="mt-2">
                                                {formData.city + ", " + formData.state}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label htmlFor="companyName" className="form-label">
                                            Street Address
                                        </label>
                                        <input
                                            type="text"
                                            id="streetAddress"
                                            name="streetAddress"
                                            placeholder="Enter street address"
                                            value={formData.streetAddress}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 mt-3 mb-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="barrelBroker"
                                                name="barrelBroker"
                                                style={{ cursor: 'pointer' }}
                                                checked= {formData.isBroker}
                                                onChange={handleIsBrokerChange}
                                            />
                                            <label className="form-check-label" htmlFor="barrelBroker">
                                                I am a Broker
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <hr />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="form__btn--group d-flex justify-content-center">
                                            <div className="pe-1">
                                                <button
                                                    type="reset"
                                                    className="btn secondary-btn register-btn mb-2"
                                                    onClick={handleViewDetail}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="ps-1">
                                                <button
                                                    type="submit"
                                                    className="btn primary-btn register-btn mb-2"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading && <Spinner />} Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>)}

                                {(isChangePassword && !isEditDetail) && (<ChangePassword onClose={hideChangePassword} />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedFile && (
                <ImageUploader file={selectedFile} onSave={base64ToFile} />
            )}
        </>
    )
}

export default ViewOrEditProfile;