import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { CommonService } from "../../Service/CommonService";
import '../Dynamic/InventoryCSV.scss';
import SearchIcon from "../../assets/images/icons/search.svg";
import { InventoryApiService } from '../../Service/InventoryApiService';
import UploadCSV from '../popups/UploadCSV';
import { CookieService } from '../../Service/CookieService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Skeleton, Typography } from '@mui/material';
import SearchBar from "../Static/SearchBar";
import { useHistory, useLocation } from "react-router";
import ViewDocs from '../popups/ViewDocs';
import CurrencyFormatter from '../Static/CurrencyFormatter';

const ViewCSV: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    // Define the localeText object based on the isLoading flag
    const localeText = {
        noRowsLabel: isLoading ? 'Fetching records ...' : 'No Record Found',
    };
    const history = useHistory();
    const [search, setSearch] = useState("");
    const userRole = CookieService.getCookie("userRole");
    const { id } = useParams<{ id: string }>();
    const [tableData, setTableData] = useState<any[]>([]);
    const [inventoryCsvWithDocs, setInventoryCsvWithDocs] = useState<any>(null);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const fetchData = async () => {

        try {
            setIsLoading(true);
            const response: any = await InventoryApiService.readCSV(id);

            setTableData(response.data)
            // setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
        finally{
            setIsLoading(false);
        }
    };

    const getInventorySellRequestWithDocs = async () => {

        try {
            const response: any = await InventoryApiService.getInventorySellRequestWithDocs(id);

            setInventoryCsvWithDocs(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        getInventorySellRequestWithDocs()
    }, []);

    const downloadCSV = async () => {
        const requestDto = {
            id:id,
            siteId: inventoryCsvWithDocs.siteId,
            companyId: inventoryCsvWithDocs.ecommUser.companyId,
            fileName: inventoryCsvWithDocs.fileName,
          };

        try {
            const response: any = await InventoryApiService.downloadCSV(requestDto);
            // Assuming response.data is the blob data
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = inventoryCsvWithDocs.fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const downloadZipWithDocs = async () => {
        try {
            const response: any = await InventoryApiService.downloadZipWithDocs(id);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const handleVisibilityClick = (event: any, params: any) => {
        // Logic to handle the click event for Visibility button
        console.log('Visibility button clicked for row:', params.row);
        event.stopPropagation(); // Prevent event from bubbling up to the row
        CommonService.Toast.fire({
            title: "View icon clicked",
        })
    };

    const handleSelectionModelChange = (newSelection: GridRowId[]) => {
        setSelectionModel(newSelection);
        console.log(newSelection.length);

    };

    const formatDateString = (dateString: any) => {
        if (dateString) {
            // Parse the input date string
            const date = new Date(dateString.replace(' ', 'T'));

            // Define arrays for month names and add leading zero function
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            const addLeadingZero = (num: any) => (num < 10 ? `0${num}` : num);

            // Extract date parts
            const year = date.getFullYear();
            const month = monthNames[date.getMonth()];
            const day = addLeadingZero(date.getDate());
            const hours = addLeadingZero(date.getHours());
            const minutes = addLeadingZero(date.getMinutes());
            const seconds = addLeadingZero(date.getSeconds());

            // Format the date string
            return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
        } else
            return '';
    };

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Title',
            // // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        // {
        //     field: 'description',
        //     headerName: 'Description',
        //     // flex: 1
        //     //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
        //     headerClassName: 'table-header',
        // },
        {
            field: 'lotNo',
            headerName: 'Lot No',
            // // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'barrelNumber',
            headerName: 'Barrel Number',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'fillDate',
            headerName: 'Fill Date',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'volume',
            headerName: 'Volume(gal)',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'proof',
            headerName: 'proof(proof)',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'proofGallons',
            headerName: 'Proof Gallons',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        // {
        //     field: 'rackhouse',
        //     headerName: 'Rackhouse',
        //     // flex: 1
        //     //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
        //     headerClassName: 'table-header',
        // },
        // {
        //     field: 'row',
        //     headerName: 'Row',
        //     // flex: 1
        //     //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
        //     headerClassName: 'table-header',
        // },
        // {
        //     field: 'depth',
        //     headerName: 'Depth',
        //     // flex: 1
        //     //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
        //     headerClassName: 'table-header',
        // },
        // {
        //     field: 'height',
        //     headerName: 'Height',
        //     // flex: 1
        //     //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
        //     headerClassName: 'table-header',
        // },
        {
            field: 'spiritAge',
            headerName: 'Spirit Age',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'state',
            headerName: 'State',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'country',
            headerName: 'Country',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'ttbType',
            headerName: 'TTB Type',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'ttbClass',
            headerName: 'TTB Class',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'facility',
            headerName: 'Facility',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'dsp',
            headerName: 'DSP',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'barrelCost',
            headerName: 'Barrel Cost',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'mashBill',
            headerName: 'Mash Bill',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'stage',
            headerName: 'Stage',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        },
        {
            field: 'price',
            headerName: 'Price',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
            renderCell: (params: any) => (
                <CurrencyFormatter
                  amount={params.value}
                  locale="en-US"
                  currency="USD"
                />
              ),
        },
        {
            field: 'producer',
            headerName: 'Producer',
            // flex: 1
            //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
            headerClassName: 'table-header',
        }
    ];

    function routeBack() {
        if (userRole == 'ADMIN')
            history.push("/user/inventory-sell-requests");
        else
            history.push("/master/inventory-sell-requests");
    }

    // Filter rows based on search query
    const filteredRows = tableData.filter(
        (row) =>
        row.mashBill?.toLowerCase().includes(search?.toLowerCase()) ||
        row.title?.toLowerCase().includes(search?.toLowerCase()) ||
        row.price === parseFloat(search) ||
        row.barrelNumber?.toLowerCase().includes(search?.toLowerCase()) ||
        row.ttbType?.toLowerCase().includes(search?.toLowerCase())
    );

    // Map filtered rows with unique IDs
    const tableDataWithIds = filteredRows.map((rowData, index) => ({
        ...rowData
    }));

    return (
        <div className="usersList">
            <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
                {CommonService.isAdmin() && (<div className="back__button me-3" onClick={routeBack}>
                    <IconButton aria-label="ArrowBackIcon" sx={{ backgroundColor: "#FFF", mb: 1 }}>
                        <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                    </IconButton>
                </div>)}

                <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
                    <div className="company__details me-4 me-xl-4 mb-3">
                        <Typography variant="h6" sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}>
                            Company name
                        </Typography>
                        <Typography variant="h3" sx={{ color: "#333333", fontSize: { xl: '18px', xs: '16px' }, fontWeight: 700 }}>
                            {inventoryCsvWithDocs?.ecommUser?.ecommCompany?.companyName}
                        </Typography>
                    </div>
                    <div className="person__details me-4 me-xl-4 mb-3">
                        <Typography variant="h6" sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}>
                            Submitted by
                        </Typography>
                        <Typography variant="h3" sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}>
                            {inventoryCsvWithDocs?.ecommUser?.firstName} {inventoryCsvWithDocs?.ecommUser?.lastName}
                        </Typography>
                    </div>
                    <div className="time__details me-4 me-xl-4 mb-3">
                        <Typography variant="h6" sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}>
                            Submitted on
                        </Typography>
                        <Typography variant="h3" sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}>
                            {CommonService.formatDateTime(inventoryCsvWithDocs?.createdDate)}
                        </Typography>
                    </div>
                </div>


                <div className="header--menu ms-auto mb-xxl-0">
                    <div className="header--menu-items row d-flex justify-content-end">
                        <div className="col-auto d-inline-flex mb-3 position-relative">
                            <SearchBar search={search} setSearch={setSearch} />
                            {/* <input type="search" id="gsearch" name="gsearch" /> */}
                            {/* <input placeholder="Search something..." className="input" name="text" type="text"></input> */}
                            {/* <img src={SearchIcon} alt="SearchIcon" className="icon position-absolute top-50 start-50 translate-middle" /> */}
                        </div>
                        
                        <div className="col-auto menu-items__btnGroup d-inline-flex mb-3">
                            <button onClick={downloadCSV} className="white-outline-btn me-1">
                                Download Inventory File
                            </button>
                            <button onClick={downloadZipWithDocs} className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                                Download All Docs
                            </button>
                            <ViewDocs docs={inventoryCsvWithDocs} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="user-table-container">
                <div className="userTable">
                {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                // height={550}
                style={{ height: "100%" }}
              />
            </>
          ) : (<><DataGrid
            localeText={localeText}
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            pageSizeOptions={[10, 20, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick={true}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
        /></>)}
   
                </div>
            </div>
        </div >

    )
};

export default ViewCSV;
