import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';

export const InventoryApiService = {
    getInventoryCSV, readCSV, updateInventoryCSV, downloadCSV, getInventorySellRequestWithDocs, downloadZipWithDocs, downloadDoc,
    getCsvBySellReqId, getSellReqById, getCsvHistoryBySellReqId, getCsvDocBySellReqId, showPDF, getInventorySellRequestsByCompanyId,
    showPDFForOrder,getMyInventories,getInventoryById,editInventory, getInventoryChangeRequests, getInventoryChangeRequestById, 
    updateInventoryChangeRequestStatus, getAllInventoryChangeRequests, downloadInventoryTemplate, getAllDuplicateInventory, getInventoryListByCompanyId, searchInventory, fetchUnreadMessageCount, getAllBarrelsCount
}

async function getInventoryCSV(id:any,page:any,size:any,isFetchOpenRequests:boolean, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<Object>(Url.GET_INVENTORY_CSV, 
    {
        companyId:id,
        page:page,
        size:size,
        isFetchOpenRequests:isFetchOpenRequests
    }, filter, { signal });
}

async function getCsvBySellReqId(id : any) {
    let path = Url.GET_CSVs_BY_SELL_REQUEST_ID + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function getCsvHistoryBySellReqId(id : any) {
    let path = Url.GET_HISTORY_BY_SELL_REQUEST_ID + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function getCsvDocBySellReqId(id : any) {
    let path = Url.GET_DOCS_BY_SELL_REQUEST_ID + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function getSellReqById(id : any) {
    let path = Url.GET_SELL_REQ_BY_ID_WITH_COMPANY + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function readCSV(csvId: any) {
    return await AxiosApiService.get<Object>(Url.READ_CSV + '/' + csvId);
}

async function updateInventoryCSV(formData: any) {
    return await AxiosApiService.put<any>(Url.UPDATE_INVENTORY_CSV, formData);
}

async function downloadCSV(csvData: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_CSV, csvData);
}

async function downloadInventoryTemplate() {
    return await AxiosApiService.getBlob<Object>(Url.DOWNLOAD_INVENTORY_TEMPLATE);
}

async function getInventorySellRequestWithDocs(csvId: any) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CSV_WITH_DOCS + '/' + csvId);
}

async function downloadZipWithDocs(csvId: any) {
    return await AxiosApiService.getZip<Object>(Url.DOWNLOAD_ZIP_WITH_DOCS + '/' + csvId);
}

async function downloadDoc(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_DOC, downloadDoc);
}

async function showPDF(id:any, companyId:any, siteId:any, fileName:any) {
    return await AxiosApiService.getBlob<Object>(`${Url.SHOW_PDF_URL}?id=${id}&companyId=${companyId}&siteId=${siteId}&fileName=${fileName}`);
}

async function showPDFForOrder(id:any, companyId:any, siteId:any, fileName:any) {
    return await AxiosApiService.getBlob<Object>(`${Url.SHOW_PDF_FOR_ORDER_URL}?id=${id}&companyId=${companyId}&siteId=${siteId}&fileName=${fileName}`);
}

async function getInventorySellRequestsByCompanyId(companyId: any,page:any, size:any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_COMPANY_WISE_SELL_REQUEST + '/' + companyId,{
        params: {
            page:page,
            size:size,
        }
    });
}

async function getMyInventories(service,companyId: any,selectedTab: string, page: any, size: any, filter: any, signal: AbortSignal, activeButton: any) {
    return await AxiosApiService.postWithSearchParams<Object>(Url.GET_MY_INVENTORIES ,
            {
                serviceType: service,
                companyId: companyId,
                selectedTab: selectedTab,
                activeButton: activeButton,
                page: page,
                size: size
            }, filter, { signal }
    );
}

async function getInventoryById(inventoryId) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_BY_ID + inventoryId);
}

async function editInventory(inventoryData) {
    return await AxiosApiService.post<Object>(Url.EDIT_INVENTORY,inventoryData);
}

async function getInventoryChangeRequests(companyId: any, isFetchOpenRequests: any) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CHANGE_REQUESTS + '/' + companyId + '/' + isFetchOpenRequests);
}

async function getInventoryChangeRequestById(id: any) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CHANGE_REQUEST_BY_ID + '/' + id);
}

async function updateInventoryChangeRequestStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.UPDATE_INVENTORY_CHANGE_REQUEST_STATUS, statusDetails)
}

async function getAllInventoryChangeRequests() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_INVENTORY_CHANGE_REQUESTS);
}

async function getAllDuplicateInventory() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_DUPLICATE_INVENTORY);
}

async function getInventoryListByCompanyId(companyId:any) {
    return await AxiosApiService.get<any>(Url.GET_INVENTORY_LIST_BY_COMPANY_ID + "/" + companyId);
}

async function searchInventory(inventorySearchRequestForCustomerService:any) {
    return await AxiosApiService.post<any>(Url.SEARCH_INVENTORY, inventorySearchRequestForCustomerService)
}

async function fetchUnreadMessageCount (sellRequestId: any) {
    return await AxiosApiService.get<any> (Url.FETCH_UNREAD_MESSAGE_COUNT_FOR_SELL_REQUEST + '/' + sellRequestId);
}

async function getAllBarrelsCount (groupId: any) {
    return await AxiosApiService.get<any> (Url.FETCH_ALL_BARRELS_COUNT + '/' + groupId);
}