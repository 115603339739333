import { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import { RangeSlider as SpectrumRangeSlider } from '@adobe/react-spectrum';

import './RangeSlider.css';

export function PriceRangeSlider(props) {
  const { start, range, canRefine, refine } = useRange(props);
  const { min, max } = range;
  const [value, setValue] = useState({ start: min, end: max });

  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);
  const stepSize = Math.max(1, Math.round((max - min) / 100)); // Adjust step dynamically

  useEffect(() => {
    setValue({ start: from, end: to });
  }, [from, to]);
  return (
    <SpectrumRangeSlider
      step={stepSize}
      label="Select price"
      minValue={min}
      maxValue={max}
      value={value}
      onChange={setValue}
      onChangeEnd={(range) => refine([range.start, range.end])}
      isDisabled={!canRefine}
    />
  );
}
