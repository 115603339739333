import { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SearchBar from "../Static/SearchBar";
import "../Dynamic/LoginHistory.scss";
import { VisibilityOutlined } from "@mui/icons-material";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { CommonService } from "../../Service/CommonService";
import { CookieService } from "../../Service/CookieService";
import StatusDropdown from "../Common/StatusDropdown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { useHistory } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import { Form } from "react-bootstrap";

const MyRequests = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadRequestDetails();
  }, []);

  const loadRequestDetails = async () => {
    try {
      setIsLoading(true);

      const response = await Service.fetchRequestData(
        CommonService.isUser() ? CookieService.getCookie("companyId") : null
      ).then((response) => {
        console.log(response.data);
        setTableData(Object.values(response.data));
        setIsLoading(false);
      });
    } catch (error) {
      console.log("error fetching data" + error);
      setIsLoading(false);
    }
  };

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  // State to store the filter values
  const [filters, setFilters] = useState({
    requestType: "",
    modifiedDate: "",
    createdBy: "",
    companyName: "",
    status: "",
  });

  // Handle filtering based on input search
  const handleFilterChange = (event: any, field: string) => {
    const value = event.target.value;
    console.log(field + ",");
    console.log(value);
    // Update the filter state
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    console.log(filters);
  };

  // Apply the filters to the rows
  const filteredRows = tableData.filter((row) => {
    return (
      row.requestType
        .toLowerCase()
        .includes(filters.requestType.toLowerCase()) &&
      row.modifiedDate
        ?.toLowerCase()
        .includes(filters.modifiedDate?.toLowerCase()) &&
      row.createdBy.toLowerCase().includes(filters.createdBy.toLowerCase()) &&
      row.companyName
        .toLowerCase()
        .includes(filters.companyName.toLowerCase()) &&
      row.status.toLowerCase().includes(filters.status.toLowerCase())
    );
  });

  // Define the columns with input search
  const columns: GridColDef[] = [
    {
      field: "requestType",
      headerName: "Request Type",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,

      renderHeader: (params) => (
        <>
          <div>
            <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
              {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
              <Form.Select
                style={{ cursor: "pointer" }}
                value={filters.requestType}
                className="form-control mb-1"
                onChange={(event) => handleFilterChange(event, "requestType")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              >
                <option value="">Select Request Type</option>
                <option value="Sell Request">Sell Request</option>
                <option value="Offer Request">Offer Request</option>
                <option value="Sample Request">Sample Request</option>
                <option value="Order Request">Order Request</option>
              </Form.Select>

            </div>
          </div>
        </>
      ),
    },
    // {
    //   field: "requestType",
    //   headerName: "Request Type",
    //   flex: 1,
    //   headerClassName: "table-header border-0 my-2 h-100",
    //   renderCell: (params) => <CellWithTooltip value={params.value} />,

    //   renderHeader: (params) => (
    //     <>
    //       <div>
    //         <Typography
    //           variant="body2"
    //           sx={{ color: "#6F6F6F", fontSize: "14px" }}
    //         >
    //           {params.colDef.headerName}
    //         </Typography>
    //         <div className="position-relative">
    //           <Form.Select
    //             style={{ cursor: "pointer" }}
    //             value={filters.status}
    //             className="form-control mb-1"
    //             onChange={(event) => handleFilterChange(event, "requestType")}
    //           >
    //             <option value="">Select Status</option>
    //             <option value="">Select Request Type</option>
    //           <option value="Sell Request">Sell Request</option>
    //           <option value="Offer Request">Offer Request</option>
    //           <option value="Sample Request">Sample Request</option>
    //           <option value="Order Request">Order Request</option>
    //           </Form.Select>
    //           {/* <input
    //           type="text"
    //           className="form-control border mt-1"
    //           style={{ textIndent: '24px', boxShadow: 'none' }}
    //           placeholder="Search"
    //           value={filters.status}
    //           onChange={(event) => handleFilterChange(event, "status")}
    //         /> */}

    //           {/* <SearchIcon
    //             sx={{
    //               color: "#6F6F6F",
    //               position: "absolute",
    //               top: "50%",
    //               left: "8px",
    //               transform: "translateY(-50%)",
    //             }}
    //           /> */}
    //         </div>
    //       </div>
    //     </>
    //   )
    // },
    {
      field: "modifiedDate",
      headerName: "Requested On",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.modifiedDate ? row.modifiedDate : null;
        if (dateTimeString) {
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <input
            type="date"
            className="form-control border mt-1"
            style={{ boxShadow: "none" }}
            id="birthdate"
            name="birthdate"
            placeholder="MM/DD/YYYY"
            value={filters.modifiedDate}
            onChange={(event) => handleFilterChange(event, "modifiedDate")}
            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
          />
        </div>
      ),
    },
    {
      field: "createdBy",
      headerName: "Requested By",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative">
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              value={filters.createdBy}
              onChange={(event) => handleFilterChange(event, "createdBy")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
    },
    ...(CommonService.isAdmin()
      ? [
        {
          field: "companyName",
          headerName: "Company Name",
          headerClassName: "table-header border-0 my-2 h-100",
          flex: 1,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
          renderHeader: (params) => (
            <div>
              <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
              >
                {params.colDef.headerName}
              </Typography>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control border mt-1"
                  style={{ textIndent: "24px", boxShadow: "none" }}
                  placeholder="Search"
                  value={filters.companyName}
                  onChange={(event) =>
                    handleFilterChange(event, "companyName")
                  }
                  onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                  sx={{
                    color: "#6F6F6F",
                    position: "absolute",
                    top: "50%",
                    left: "8px",
                    transform: "translateY(-50%)",
                  }}
                />
              </div>
            </div>
          ),
        },
      ]
      : []),
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => (
        <>
          <div className="dropdown-nospacing">
            <StatusDropdown
              currentStatus={params.row.status}
              disableCondition={CommonService.isAdmin() || CommonService.isUser()}
              onStatusChange={(newStatus) => { }}
              allowedLabels={[]}
            />
            {params.row.status === "REJECTED" && (
              <Tooltip
                title={`Rejection Note: ${params.row.rejectionNote}`}
                arrow
              >
                <StickyNote2Icon
                  className="note-icon cursor-pointer"
                  style={{ color: "gray" }}
                />
              </Tooltip>
            )}
          </div>
        </>
      ),
      renderHeader: (params) => (
        <>
          <div>
            <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
              {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
              <Form.Select
                style={{ cursor: "pointer" }}
                value={filters.status}
                className="form-control mb-1"
                onChange={(event) => handleFilterChange(event, "status")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              >
                <option value="">Select Status</option>
                <option value="PENDING_REVIEW">Pending Review</option>
                <option value="UNDER_REVIEW">Under Review</option>
                <option value="ACCEPTED">Accepted</option>
                <option value="REJECTED">Rejected</option>
                <option value="OWNERSHIP_TRANSFERRED">
                  Ownership Transferred
                </option>
                <option value="CANCELLED">Cancelled</option>
                <option value="SHIPPED">Shipped</option>
                <option value="UNDER_REVIEW_BY_SELLER">
                  Under Review By Seller
                </option>
                <option value="SELLER_ACCEPTED">Seller Accepted</option>
                <option value="SELLER_REJECTED">Seller Rejected</option>
                <option value="DELIVERED">Delivered</option>
                <option value="FINALIZED">Finalized</option>
                <option value="PUBLISHED">Published</option>
                <option value="UNPUBLISHED">Unpublished</option>
              </Form.Select>
              {/* <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: '24px', boxShadow: 'none' }}
              placeholder="Search"
              value={filters.status}
              onChange={(event) => handleFilterChange(event, "status")}
            /> */}

              {/* <SearchIcon
                sx={{
                  color: "#6F6F6F",
                  position: "absolute",
                  top: "50%",
                  left: "8px",
                  transform: "translateY(-50%)",
                }}
              /> */}
            </div>
          </div>
        </>
      ),
    },
  ];

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
    id: index + 1, // Assigning a unique ID based on the index
  }));

  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const history = useHistory();

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

    if (params.row.requestType == "Order Request")
      history.push(`${linkPrefix}/order-detail/${params.row.requestId}`);
    else if (params.row.requestType == "Sell Request")
      history.push(`${linkPrefix}/view-sell-request/${params.row.requestId}`);
    else if (params.row.requestType == "Sample Request")
      history.push(`${linkPrefix}/view-sample-request/${params.row.requestId}`);
    else if (params.row.requestType == "Offer Request")
      history.push(`${linkPrefix}/view-offer-request/${params.row.requestId}`);
  };

  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (
          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0">
              Manage Requests <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
        )}
      </div>
      <div className="user-table-container">
        <div className="userTable">
        {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                // height={550}
                style={{ height: "100%" }}
              />
            </>
          ) : (<><DataGrid
            localeText={localeText}
            disableRowSelectionOnClick={true}
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={handleRowClick} // Attach the event handler
          // checkboxSelection
          // getRowClassName={getCellClassName}
          /></>)}
          
        </div>
      </div>
    </div>
  );
};

export default MyRequests;
