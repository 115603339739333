 const Constant ={

     Role :{
        ADMIN :"ADMIN",
        SUPER_ADMIN : "SUPER_ADMIN",
        USER : "USER"
      },

      OrderStatus :{
        CART : "CART",
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        SHIPPED : "SHIPPED",
        CANCELLED : "CANCELLED",
        DELIVERED : "DELIVERED",
        SELLER_REJECTED: "SELLER_REJECTED",
        ADMIN_REJECTED: "ADMIN_REJECTED",
        IN_TRANSIT : "IN_TRANSIT",
        OWNERSHIP_TRANSFERRED : "OWNERSHIP_TRANSFERRED"
      },

      SampleRequestStatus :{
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        APPROVED : "APPROVED",
        ACTION_REQUIRED : "ACTION_REQUIRED",
        IN_TRANSIT : "IN_TRANSIT",
        CANCELLED : "CANCELLED",          
      },

      CustomerServiceRequestStatus : {
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        CANCELLED : "CANCELLED",
        CLOSED: "CLOSED"
      },


      facility : {
        WHITEDOG_TRADING_STORAGE : "WhiteDog Trading Storage",
        WDTS : "WDTS",
      } ,

      Profile : {
        OWNER : 'Owner',
        BUYER : 'Buyer'
      },

      CustomerService : {
        PRIVATE_SELL : 'Private Sell',
        BULK_INVENTORY_UPLOAD : 'Bulk Inventory Upload'
      },
}


export default Constant;