import React, { useEffect, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import "../Dynamic/UserList.scss";
import SearchBar from "../Static/SearchBar";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { CommonService } from "../../Service/CommonService";
import { IconButton, Skeleton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteUser from "../popups/DeleteUser";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DeleteCompany from "../popups/DeleteCompany";
import AddSharpIcon from '@mui/icons-material/AddSharp';
import SearchIcon from "@mui/icons-material/Search";

const CompanyList: React.FC = () => {
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [companiesList, setCompaniesList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const controllerRef = useRef<AbortController | null>(null);
  const [filter, setFilter] = useState<any>({
    companyName: null,
    contactPerson: null,
    contactNumber: null,
    email: null,
  });

  const fetchCompaniesList = async (page, size, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try{
      setIsLoading(true);
      setCompaniesList([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response: any = await Service.fetchCompaniesWithPagination(page , size, filter, signal);
      setCompaniesList(response.data.content);
      setTotalCount(response.data.totalElements); 
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  useEffect(() => {
    fetchCompaniesList(page, pageSize, filter);
  }, []);

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //redirect to the add company page
  const handleAddCompany = () => {
    history.push("/master/add-company");
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`/master/company-dashboard/${params.row.id ?? ""}`);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof any) => {
    const value = event.target.value;
  
    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: value, // Update the specific field
    };
  
    // Update the filter state
    setFilter(tempFilter);
  
    // Load the service requests with updated filters
    fetchCompaniesList(page, pageSize, tempFilter);
  };

  const columns: GridColDef[] = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.companyName ?? ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.companyName}
                    defaultValue={filter.companyName}
                    onChange={(event) => handleFilterChange(event, "companyName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => (
        <CellWithTooltip value={params.row.companyName} />
      ),
    },

    // {
    //   field: "siteId",
    //   headerName: "Site Id",
    //   flex: 1,
    //   valueGetter: (value: any, row: any) => `${row.siteId ?? ""}`,
    //   headerClassName: "table-header",
    //   renderCell: (params: any) => (
    //     <CellWithTooltip value={params.value || "-"} />
    //   ),
    // },
    {
      field: "contactPerson",
      headerName: "Contact Person",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.contactPerson ?? ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.contactPerson}
                    defaultValue={filter.contactPerson}
                    onChange={(event) => handleFilterChange(event, "contactPerson")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => (
        <CellWithTooltip value={params.value || "-"} />
      ),
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.contactNumber ?? ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.contactNumber}
                    defaultValue={filter.contactNumber}
                    onChange={(event) => handleFilterChange(event, "contactNumber")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => (
        <CellWithTooltip value={params.value || "-"} />
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.email ?? ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.email}
                    defaultValue={filter.email}
                    onChange={(event) => handleFilterChange(event, "email")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => (
        <CellWithTooltip value={params.value || "-"} />
      ),
    },
  ];

  // // Filter rows based on search query
  // const filteredRows = companiesList?.filter(
  //   (row) =>
  //     row.email?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.contactPerson?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.contactNumber?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = companiesList.map((rowData, index) => ({
    ...rowData,
  }));

  const handleBack = () => {
    history.push("/master/dashboard");
  };

  const reloadTable = () => {
    fetchCompaniesList(page, pageSize, filter);
  };

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return companiesList.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    // selectedRows.current = newSelectedRows;
    // let flag: any = false;
    // flag = selectedRows.current.some((user) => user.userStatus === "INVITED");
    // disableAction.current = flag;
  };

  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        <IconButton
          onClick={handleBack}
          aria-label="ArrowBackIcon"
          sx={{
            backgroundColor: "#FFF",
            mb: 1,
            "&:hover": { backgroundColor: "#FFF" },
          }}
        >
          <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
        </IconButton>
        <div className="header--title mb-2 me-2">
          <h1 className="title mb-0 ms-2">
            Companies <i className="bi bi-heart-fill"></i>
          </h1>
        </div>
        <div className="header--menu ms-auto mb-2">
          <div className="header--menu-items d-flex">
            <div className="mb-2">
              {/* <SearchBar search={search} setSearch={setSearch} /> */}
            </div>

            <div className="menu-items__btnGroup btnGroup-border d-inline-flex mb-2 mx-2"></div>

            <div className="menu-items__btnGroup d-inline-flex mb-2">
              <button
                className="primary-btn d-inline-flex me-1"
                onClick={handleAddCompany}
              >
                <AddSharpIcon sx={{ mr: 0.5 }} />
                Add Company
              </button>
            </div>

            <div className="menu-items__btnGroup d-inline-flex mb-2">
              <button className="white-outline-btn ms-2">
                <DeleteOutlinedIcon sx={{ me: 2, color: "#1010104D" }} />
                <DeleteCompany
                  deleteUser={selectionModel}
                  reloadTable={reloadTable}
                />
                <span className="review--number text-secondary d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">
                  {selectionModel.length}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="user-table-container">
        <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            // initialState={{
            //   pagination: {
            //     paginationModel: { page: 0, pageSize: 5 },
            //   },
            // }}
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: pageSize },
              },
            }}
            pagination
            paginationModel={{
              page,
              pageSize,
            }}
            onPaginationModelChange={(newPaginationModel) => {
              setPage(newPaginationModel.page);
              setPageSize(newPaginationModel.pageSize);

              fetchCompaniesList(newPaginationModel.page, newPaginationModel.pageSize, filter);
            }}
            rowCount={totalCount}
            paginationMode="server"
            checkboxSelection
            disableRowSelectionOnClick={true}
            pageSizeOptions={[10, 20, 50, 100]}
            localeText={localeText}
            onRowClick={handleRowClick}
            onRowSelectionModelChange={handleSelectionModelChange}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
