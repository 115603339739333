import { useEffect, useState } from "react";
import { OrderApiService } from "../../Service/OrderApiService";
import Spinner from "../Common/Spinner";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/Validation.scss"

interface EditRequestTypeProps {
  requestType: string;
  id: string;
  refreshData:() => void
  onEditClick: (itemId: string) => void;
}

const EditRequest: React.FC<EditRequestTypeProps> = ({ requestType, id, refreshData, onEditClick }) => {

  
  const [isLoading,setIsLoading] = useState(false);
  const [offerRequest, setOfferRequest] = useState<any>(null);
  const [sampleRequest, setSampleRequest] = useState<any>(null);
  const [orderRequestItem, setOrderRequestItem] = useState<any>(null);
  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const validateSampleRequestFields = () =>  {
    const newErrors: {[key: string]: string} = {};
    let isValidate = true;
    if (!sampleRequest.quantity || sampleRequest.quantity <= 0) {
      newErrors.quantity = "Please enter a quantity";
      isValidate = false;
    }
    
    if (!sampleRequest.purpose || sampleRequest.purpose.trim() === '') {
      newErrors.purpose = "Please enter request notes";
      isValidate = false;
    }

    setErrors(newErrors);
    return isValidate;  

  }

  const validateOrderRequestFields = () => {
    const newErrors: {[key: string]: string} = {};
    let isValidate = true;

    if (!orderRequestItem.quantity || orderRequestItem.quantity <= 0) {
      newErrors.quantity = "Please enter a quantity";
      isValidate = false;
    }
    
    if (!orderRequestItem.price || orderRequestItem.price <= 0) {
      newErrors.price = "Please enter a price";
      isValidate = false;
    }

    setErrors(newErrors);
    return isValidate;


  }

  const validateOfferRequestFields = () => {
    const newErrors: {[key: string]: string} = {};
    let isValidate = true;

    if (!offerRequest.quantity || offerRequest.quantity <= 0) {
      newErrors.quantity = "Please enter a quantity";
      isValidate = false;
    }
    
    if (!offerRequest.pricePerBarrel || offerRequest.pricePerBarrel <= 0) {
      newErrors.price = "Please enter a price";
      isValidate = false;
    }
    
    setErrors(newErrors);
    return isValidate;
  }

  const handleUpdate = async() => {
    // Implement update logic here according to the request type

    if(requestType == 'SAMPLE_REQUEST') {

      if(await validateSampleRequestFields()) {
      try {
        setIsLoading(true);
      const response = await OrderApiService.saveSampleRequest(sampleRequest)
      .then((response)=>{
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Sample request updated successfully",
          icon: "success",
        });
         //reload the date
      refreshData();
      onEditClick(id);
      });
     
    } catch(error) {
      setIsLoading(false);
        console.log("error occurred");
      }
    }
    }

    else if(requestType == 'OFFER_REQUEST') {

      if(await validateOfferRequestFields()) {
      try {

        offerRequest.serviceFees = Math.min(offerRequest?.serviceFees, 5000)

        setIsLoading(true);
      const response = await OrderApiService.savePurchaseOffer(offerRequest)
      .then((response)=>{
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Offer request updated successfully",
          icon: "success",
        });
         //reload the date
        refreshData();
      });

    } catch(error) {
      setIsLoading(false);
        console.log("error occurred");
      }
    }
    }

    else if(requestType == 'ORDER_REQUEST') {

      if(await validateOrderRequestFields()) {
      try{

        orderRequestItem.serviceFees = Math.min(orderRequestItem?.serviceFees, 5000)

        setIsLoading(true);
        const response = await OrderApiService.updateOrderItem(orderRequestItem)
        .then((response)=>{
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Order request updated successfully",
          icon: "success",
        });

        //reload the date
      refreshData();
      onEditClick(id);
      });

      } catch(error) {
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Order request is not updated",
          icon: "error",
        });
      }
      }
    }

  };

  const handleCancel = () => {
    // Implement cancel logic here
  };
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const fetchData = async () => {
    if(requestType === "ORDER_REQUEST") {
      // Fetch order request data
      const response  = await OrderApiService.getOrderItem(id)
      setOrderRequestItem(response.data);
    }
    else if(requestType === "SAMPLE_REQUEST") {
      const response: any = await OrderApiService.fetchSampleRequestById(id);
      setSampleRequest(response.data);
    }
    else if(requestType === "OFFER_REQUEST") {
      const response: any = await OrderApiService.fetchOfferRequestById(id);
      setOfferRequest(response.data);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  // Handle change for offer request
  const handleOfferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const { id, value, name } = e.target;
    errors[name] = "";
    setOfferRequest(prevState => ({
      ...prevState,
      [id]: value
    }));
    // If 'quantity' is being updated and relocation is required, update handling fees
    if (id === 'quantity' && offerRequest?.isRelocationRequired && value.trim().length != 0 && Number(value) != 0) {
      offerRequest.handlingFees = 10*Number(value);
    }

    // If 'quantity' is being updated and account transfer is required, update service fees
    if (id === 'quantity' && offerRequest?.isAccountTransfer && value.trim().length != 0 && Number(value) != 0) {
      offerRequest.serviceFees = Math.min(10*Number(value), 5000);
    }

  };

  // Handle change for sample request
  const handleSampleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { id, value, type, name } = e.target;
    errors[name] = "";
    setSampleRequest(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  //handle change for order request
  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {id, value, type, name} = e.target;
    errors[name] = "";
    setOrderRequestItem(prevState => ({
      ...prevState,
      [id]:type === 'checkbox' ? (e.target as HTMLInputElement).checked :value.trim()
    }));

     // If 'quantity' is being updated and relocation is required, update handling fees
     if (id === 'quantity' && orderRequestItem.isRelocationRequired && value.trim().length != 0 && Number(value) != 0) {
      orderRequestItem.handlingFees = 10*Number(value);
    }

    // If 'quantity' is being updated and account transfer is required, update service fees
    if (id === 'quantity' && orderRequestItem.isAccountTransfer && value.trim().length != 0 && Number(value) != 0) {
      orderRequestItem.serviceFees = Math.min(10*Number(value), 5000);
    }

  };

  const handleRadioChange = (name: string, value: boolean) => {
   
    setSampleRequest(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOrderItemRadioChanges = (name: string, value: boolean) => {
    setOrderRequestItem(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if(name === 'isRelocationRequired') {

        //add $10 per barrel in handling fees
        orderRequestItem.handlingFees = value? 10*orderRequestItem?.quantity: 0;
      
    }

    else if(name === 'isAccountTransfer') {
      
      //add $10 in service fees
      orderRequestItem.serviceFees = value? 10: 0;
    }


  };

  return (
    <div className={`msgbox-container ${isCollapsed ? '' : 'show'}`}>
      <div className="title mb-3">
        {requestType === "OFFER_REQUEST" && (<h3 className="text-white fw-semibold fs-5 mb-0 mx-2">Edit Offer Request</h3>)}
        {requestType === "SAMPLE_REQUEST" && (<h3 className="text-white fw-semibold fs-5 mb-0 mx-2">Edit Sample Request</h3>)}
        {requestType === "ORDER_REQUEST" && (<h3 className="text-white fw-semibold fs-5 mb-0 mx-2">Edit Order Request</h3>)}
      </div>
      <div className="card">
        <div className="card-body" style={{paddingRight:"16px"}}>
         <div className="card-title">Summary</div>

          {requestType === "OFFER_REQUEST" && (<>
            <div className="form-group mb-3">
              <label htmlFor="quantity" className="fw-semibold fs-6 mb-1">Quantity</label>
              <div className="input-group border rounded">
                <input
                  type="number"
                  className={`form-control ${errors.quantity? "is_invalid": ""}`}
                  id="quantity"
                  name = "quantity"
                  value={offerRequest?.quantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Check if the value contains only digits
                      handleOfferChange(e);
                    }
                  }}
                />
                <span className="input-group-text bg-body-secondary text-secondary border-0 ps-2">Barrels</span>
              </div>
              {errors.quantity && <div className="error-message">{errors.quantity}</div>}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="pricePerBarrel" className="fw-semibold fs-6 mb-1">Price($)</label>
              <div className="input-group border rounded">
                <input
                  type="text"
                  className={`form-control ${errors.price? "is_invalid": ""}`}
                  id="pricePerBarrel"
                  name= "price"
                  value={offerRequest?.pricePerBarrel}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Check if the value contains only digits
                      handleOfferChange(e);
                    }
                  }}
                />
                <span className="input-group-text bg-body-secondary text-secondary border-0 ps-2">/Barrel</span>
              </div>
              {errors.price && <div className="error-message">{errors.price}</div>}

            </div>
           {CommonService.isAdmin() && (<><div className="form-group mb-3">
              <label htmlFor="handlingFees" className="fw-semibold fs-6 mb-1">Total Handling Charges($)</label>
              <input
                type="text"
                className="form-control mb-3"
                id="handlingFees"
                value={offerRequest?.handlingFees}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Check if the value contains only digits
                    handleOfferChange(e);
                  }
                } } />
              {/* {errors.handlingFees && <div className="text-danger">{errors.handlingFees}</div>} */}

            </div><div className="form-group mb-3">
                <label htmlFor="serviceFees" className="fw-semibold fs-6 mb-1">Total Service Charges($)</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  id="serviceFees"
                  value={Math.min(offerRequest?.serviceFees, 5000)}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Check if the value contains only digits
                      handleOfferChange(e);
                    }
                  } } />
                {/* {errors.serviceFees && <div className="text-danger">{errors.serviceFees}</div>} */}

              </div></>)}
          </>
          )}


          {requestType === "SAMPLE_REQUEST" && (
            <> 
      <div className="mb-3">
  <label className="form-label">Multiple barrels from the same lot?</label>
  <div>
    <div className="form-check form-check-inline ps-0">
      <input
        className="form-check-input"
        type="radio"
        name="multipleBarrels"
        id="multipleBarrelsYes"
        value="yes"
        checked={sampleRequest?.multipleBarrels === true}
        onChange={() => handleRadioChange("multipleBarrels", true)}
      />
      <label className="form-check-label" htmlFor="multipleBarrelsYes">
        Yes
      </label>
    </div>
    <div className="form-check form-check-inline ps-2">
      <input
        className="form-check-input"
        type="radio"
        name="multipleBarrels"
        id="multipleBarrelsNo"
        value="no"
        checked={sampleRequest?.multipleBarrels === false}
        onChange={() => handleRadioChange("multipleBarrels", false)}
      />
      <label className="form-check-label" htmlFor="multipleBarrelsNo">
        No
      </label>
    </div>
  </div>
</div>

<div className="mb-3">
  <label className="form-label">Multiple lots needed?</label>
  <div>
    <div className="form-check form-check-inline ps-0">
      <input
        className="form-check-input"
        type="radio"
        name="multiplelots"
        id="multiplelotsYes"
        value="yes"
        checked={sampleRequest?.multiplelots === true}
        onChange={() => handleRadioChange("multiplelots", true)}
      />
      <label className="form-check-label" htmlFor="multiplelotsYes">
        Yes
      </label>
    </div>
    <div className="form-check form-check-inline ps-2">
      <input
        className="form-check-input"
        type="radio"
        name="multiplelots"
        id="multiplelotsNo"
        value="no"
        checked={sampleRequest?.multiplelots === false}
        onChange={() => handleRadioChange("multiplelots", false)}
      />
      <label className="form-check-label" htmlFor="multiplelotsNo">
        No
      </label>
    </div>
  </div>
</div>

              <div className="mb-3">
                <label htmlFor="size" className="form-label">Size Needed?</label>
                <select
                  id="size"
                  className="form-select rounded-pill border-secondary fs-6"
                  value={sampleRequest?.size}
                  onChange={handleSampleChange}
                >
                  <option value="750mL">750mL</option>
                  <option value="200mL">200mL</option>
                  <option value="100mL">100mL</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="quantity" className="form-label">Qty Request for Size</label>
                <input
                  type="number"
                  className={`form-control ${errors.quantity? "is_invalid": ""}`}
                  id="quantity"
                  name="quantity"
                  value={sampleRequest?.quantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Check if the value contains only digits
                      handleSampleChange(e);
                    }
                  }}
                />
            {errors.quantity && <div className="error-message">{errors.quantity}</div>}

              </div>

              <div className="mb-1">
                <label htmlFor="comment" className="form-label">Request Notes</label>
                <textarea
                  id="comment"
                  name= "comment"
                  style={{ resize: "none" }}
                  rows={3}
                  className={`form-control ${errors.comment? "is_invalid": ""}`}
                  value={sampleRequest?.comment}
                  onChange={handleSampleChange}
                ></textarea>
                <div className="form-text text-end">{sampleRequest?.comment?.length || 0}/500</div>
              </div>
              {errors.comment && <div className="error-message">{errors.comment}</div>}

            </>
          )}

          {requestType === "ORDER_REQUEST" && (
            <>
            <div className="form-group mb-3">
              <label htmlFor="quantity" className="fw-semibold fs-6 mb-1">Total Quantity</label>
              <div className="input-group border rounded">
                <input
                  type="text"
                  className={`form-control ${errors.quantity? "is_invalid": ""}`}
                  id="quantity"
                  name ="quantity"
                  pattern="\d*" // Ensures only digits are allowed
                  value={orderRequestItem?.quantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Check if the value contains only digits
                      handleOrderChange(e);
                    }
                  }} />
                <span className="input-group-text bg-body-secondary text-secondary border-0 ps-2">Barrels</span>
              </div>
              {errors.quantity && <div className="error-message">{errors.quantity}</div>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="price" className="fw-semibold fs-6 mb-1">Price($)</label>
                <input
                  type="text"
                  className={`form-control ${errors.price? "is_invalid": ""}`}
                  id="price"
                  name="price"
                  value={orderRequestItem?.price}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Check if the value contains only digits
                      handleOrderChange(e);
                    }
                  }}  />
                {errors.price && <div className="error-message">{errors.price}</div>}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="handlingFees" className="fw-semibold fs-6 mb-1">Total Handling Charges($)</label>
              <input
                type="text"
                className="form-control mb-3"
                id="handlingFees"
                value={orderRequestItem?.handlingFees}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Check if the value contains only digits
                    handleOrderChange(e);
                  }
                }}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="serviceFees" className="fw-semibold fs-6 mb-1">Total Service Charges($)</label>
              <input
                type="text"
                className="form-control mb-3"
                id="serviceFees"
                value={Math.min(orderRequestItem?.serviceFees, 5000)}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Check if the value contains only digits
                    handleOrderChange(e);
                  }
                }}
              />
            </div>

            <div className="mb-3">
  <label className="form-label">Is there a requirement to relocate the barrels?</label>
  <div>
    <div className="form-check form-check-inline ps-0">
      <input
        className="form-check-input"
        type="radio"
        name="isRelocationRequired"
        id="isRelocationRequiredYes"
        value="yes"
        checked={orderRequestItem?.isRelocationRequired === true}
        onChange={() => handleOrderItemRadioChanges("isRelocationRequired", true)}
      />
      <label className="form-check-label" htmlFor="isRelocationRequiredYes">
        Yes
      </label>
    </div>
    <div className="form-check form-check-inline ps-2">
      <input
        className="form-check-input"
        type="radio"
        name="isRelocationRequired"
        id="isRelocationRequiredNo"
        value="no"
        checked={orderRequestItem?.isRelocationRequired === false}
        onChange={() => handleOrderItemRadioChanges("isRelocationRequired", false)}
      />
      <label className="form-check-label" htmlFor="isRelocationRequiredNo">
        No
      </label>
    </div>
  </div>
</div>

<div className="mb-3">
  <label className="form-label">Is there requirement to change account?</label>
  <div>
    <div className="form-check form-check-inline ps-0">
      <input
        className="form-check-input"
        type="radio"
        name="isAccountTransfer"
        id="isAccountTransferYes"
        value="yes"
        checked={orderRequestItem?.isAccountTransfer === true}
        onChange={() => handleOrderItemRadioChanges("isAccountTransfer", true)}
      />
      <label className="form-check-label" htmlFor="isAccountTransferYes">
        Yes
      </label>
    </div>
    <div className="form-check form-check-inline ps-2">
      <input
        className="form-check-input"
        type="radio"
        name="isAccountTransfer"
        id="isAccountTransferNo"
        value="no"
        checked={orderRequestItem?.isAccountTransfer === false}
        onChange={() => handleOrderItemRadioChanges("isAccountTransfer", false)}
      />
      <label className="form-check-label" htmlFor="isAccountTransferNo">
        No
      </label>
    </div>
  </div>
</div>
              </>
          )}

          <div className="d-flex">
            <button className="btn primary-btn me-2" onClick={handleUpdate} disabled={isLoading}>
            {isLoading && <Spinner/>}  Update
            </button>
            <button className="btn secondary-btn" onClick={() => onEditClick(id)} disabled={isLoading}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRequest;