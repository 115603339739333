import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { InventoryApiService } from '../../Service/InventoryApiService';
import { CommonService } from '../../Service/CommonService';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Spinner from '../Common/Spinner';
import { Form } from 'react-bootstrap';

interface ChildProps {
    inventoryCsv: any;
    fetchData : any;
    isFromDropdown : boolean;
    popupShow: boolean;
}

type FormValue = {
    platformFee: number,
    feeType: string,
    status: string,
}

const SingleApproveCSV: React.FC<ChildProps> = ({ inventoryCsv ,fetchData, isFromDropdown, popupShow }) => {

    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState<FormValue>({ platformFee: 0, feeType: 'FLAT', status: 'ACCEPTED' });
    const [isApproved, setisApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error,setError] = useState<string>("");
    const [feePerBarrel,setFeePerBarrel] = useState("");

    const handleClose = () => {
        setisApproved(false);
        fetchData();
        setError("");
        setFormData({ platformFee: 0, feeType: 'FLAT', status: 'ACCEPTED' });
        setShow(false)
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        console.log("called single approve CSV");
        console.log(inventoryCsv);

        setShow(popupShow);


    }, [inventoryCsv]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setError("");
        setFeePerBarrel(value);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, feeType: e.target.value });
    };

    const handleSubmit = () => {

        if(Number(feePerBarrel.trim()) < 1) {
            setError("Please enter Fee($)/barrel");
            return ;
        }

        setIsLoading(true);
        const updatedCSV = { ...formData, id: inventoryCsv.id };
        InventoryApiService.updateInventoryCSV(updatedCSV)
            .then(response => {
                setIsLoading(false);
                setisApproved(true)
                //fetchData();
                // CommonService.Toast.fire({
                //     title: "CSV approved successfully !",
                //     icon: 'success',
                // });
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            })


    }

    return (
        <>
            {/* {isFromDropdown && <div onClick={handleShow}>
            <HowToRegIcon fontSize="small" sx={{ mr: 1 }} />Approved</div>} */}
            {!isFromDropdown && <Tooltip title="Approve" arrow>
                <IconButton onClick={handleShow}>
                    <FontAwesomeIcon icon={faUserCheck} />
                </IconButton>
            </Tooltip>}
            <Modal show={show} onHide={handleClose} className="modal__wd modal__wd--submitSellReq">
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Accept Sell Request</Modal.Title>

                    {!isApproved && (
                        <p className="modal-text text-start">
                            Please Enter fee / barrel</p>
                    )}
                    {isApproved && (
                        <p className="modal-text text-start">
                            CSV accepted successfully ! </p>
                    )}

                </Modal.Header>

                {!isApproved && (
                    <Modal.Body>
                        <form className="form__inner row d-flex">

                            <div className="col-12 mb-3">
                                <label htmlFor="feeTypeSelect" className="form-label">Fee Type <span className='text-danger'>*</span></label>
                                <div className="position-relative">
                                    <Form.Select
                                        className="form-control"
                                        id="feeTypeSelect"
                                        value={formData.feeType}
                                        onChange={handleSelectChange}
                                        required
                                    >
                                        {/* <option value="">Select a fee type</option> */}
                                        <option value="FLAT">Flat $</option>
                                        <option value="PERCENTAGE">Percentage (%)</option>
                                    </Form.Select>

                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <label htmlFor="platformFeeInput" className="form-label">Fee
                                    {(formData.feeType === 'FLAT') && <span>($)</span>}
                                    {(formData.feeType === 'PERCENTAGE') && <span>(%)</span>}
                                    /barrel <span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="number"
                                    className={`form-control ${error? "is_invalid" : ""}`}
                                    id="platformFeeInput"
                                    name="platformFee"
                                    value={formData.platformFee}
                                    onChange={handleInputChange}
                                    min="0"
                                    step="5" // Allow input of double values
                                />
                                {error && <div className='error-message'>{error}</div>}
                            </div>

                        </form>
                    </Modal.Body>
                )}

                <Modal.Footer className={`justify-content-center ${isApproved ? 'bg-transparent' : ''}`}>
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className={isApproved ? "col-12" : "col-6 ps-0"}>
                            <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                {!isApproved && ('Cancel')}
                                {isApproved && ('Close')}
                            </button>
                        </div>
                        {!isApproved && (
                            <div className="col-6 pe-0">
                                <button className="primary-btn w-100" onClick={handleSubmit} disabled={isLoading}>
                                {isLoading && <Spinner/>} Accept
                                </button>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default SingleApproveCSV;