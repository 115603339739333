import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';
import axios from 'axios';

export const OrderApiService = {
    getOrderItems, saveOrderItems, deleteOrderItem, saveSampleRequest, isSampleRequestCreated, updateSampleRequest, savePurchaseOffer, isPurchaseOfferCreated, updatePurchaseOfferRequest, changeOrderStatus,
    updateOrderStatus, getActiveOrders, getActiveSampleRequests, getActiveOfferRequests, addToWishlist, removeFromWishlist, getUserWishlist, isInventoryInWishlist, getOrders, getPendingOrders, getPendingOrdersCount, updateOrderStatusByOrderId,
    changeSampleRequestStatus, getItemsByOrderId, changeOfferStatus, fetchSampleRequestById, uploadSampleRequestDocs, fetchSampleRequestDocs, fetchSampleRequestHistory,
    downloadSampleRequestDOC, uploadOrderRequestDocs, fetchOrderRequestDocs, fetchOrderRequestHistory, downloadOrderRequestDOC, getCompanyWiseSampleRequests,
    getCompanyWiseOfferRequests, getCompanyWiseOrderRequests, getOrderSummaryByOrderId, removeItemFromCart, 
    fetchOfferRequestById, fetchOfferRequestDocs, fetchOfferRequestHistory, uploadOfferRequestDocs, downloadOfferRequestDOC,
    getUnseenMessageCount, getAllNotifications, updateIsSeenFlag, getOrderItem, updateOrderItem, isInventoryInCartForUser, 
    updateOrderItemStatus, getCompanyWiseOrderRequestsPagination, updateSampleRequestOwnerStatus, changeOwnerStatusForOffer, getAllBarrels, getReservedBarrelsCount,updateReservedBarrelDetails
}

async function getOrderItems() {
    return await AxiosApiService.get<Object>(Url.GET_ORDER_ITEMS);
}

async function saveOrderItems(orderItems: any) {
    return await AxiosApiService.post<any>(Url.SAVE_ORDER_ITEMS, orderItems);
}

async function deleteOrderItem(orderId: any) {
    return await AxiosApiService.delete<any>(Url.DELETE_ITEM + '/' + orderId);
}

async function saveSampleRequest(sampleOrder: any) {
    return await AxiosApiService.post<any>(Url.SAMPLE_ORDER_REQUEST, sampleOrder);
}

async function isSampleRequestCreated(sampleOrder: any) {
    return await AxiosApiService.post<any>(Url.IS_SAMPLE_ORDER_REQUEST_CREATED, sampleOrder);
}

async function updateSampleRequest(sampleOrder: any) {
    return await AxiosApiService.post<any>(Url.UPDATE_SAMPLE_ORDER_REQUEST, sampleOrder);
}

async function savePurchaseOffer(purchaseOfferDetails: any) {
    return await AxiosApiService.post<any>(Url.SAVE_PURCHASE_OFFER, purchaseOfferDetails);
}

async function isPurchaseOfferCreated(purchaseOfferDetails: any) {
    return await AxiosApiService.post<any>(Url.IS_PURCHASE_OFFER_REQUEST_CREATED, purchaseOfferDetails);
}

async function updatePurchaseOfferRequest(purchaseOfferDetails: any) {
    return await AxiosApiService.post<any>(Url.UPDATE_PURCHASE_OFFER_REQUEST, purchaseOfferDetails);
}

async function updateOrderStatus(placeOrder: any) {
    return await AxiosApiService.post<any>(Url.PLACE_ORDER, placeOrder);
}

async function getActiveOrders() {
    return await AxiosApiService.get<any>(Url.GET_ACTIVE_ORDERS);
}

async function getActiveSampleRequests(page:any,size:any,isFetchOpenRequests:boolean, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_SAMPLE_REQUEST,
        {
            page: page,
            size: size,
            isFetchOpenRequests:isFetchOpenRequests
        }, filter, { signal });
}

async function getActiveOfferRequests(page:any, size:any, isFetchOpenRequests:boolean, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_OFFER_REQUEST,
        {
            page:page,
            size:size,
            isFetchOpenRequests:isFetchOpenRequests,
        }, filter, { signal });
}

async function addToWishlist(wishListRequest: any) {
    return await AxiosApiService.post<any>(Url.ADD_TO_WISH_LIST, wishListRequest);
}

async function removeFromWishlist(wishListRequest: any) {
    return await AxiosApiService.post<any>(Url.REMOVE_FROM_WISH_LIST, wishListRequest);
}

async function getUserWishlist(userId: any) {
    return await AxiosApiService.get<any>(Url.GET_WISH_LIST + '/' + userId);
}

async function isInventoryInWishlist(inventoryId: any) {
    return await AxiosApiService.get<boolean>(Url.IS_INVENTORY_IN_WISH_LIST + '/' + inventoryId);
}

async function isInventoryInCartForUser(inventoryId: any) {
    return await AxiosApiService.get<any>(Url.IS_INVENTORY_IN_CART_FOR_USER + '/' + inventoryId);
}

async function getOrders(page: number, size : number,forReview: boolean,isFetchOpenRequests:boolean, selectedTab: any,filter: any, signal: AbortSignal) {

    return await AxiosApiService.postWithSearchParams<any>(Url.GET_ORDERS, 
        {
            page: page,
            size: size,
            forReview: forReview,
            isFetchOpenRequests: isFetchOpenRequests,
            selectedTab: selectedTab
        }, filter, { signal });

}

async function getPendingOrders() {
    return await AxiosApiService.get<any>(Url.GET_PENDING_ORDERS);
}

async function getPendingOrdersCount() {
    return await AxiosApiService.get<any>(Url.GET_PENDING_ORDERS_COUNT);
}

async function updateOrderStatusByOrderId(statusDetails: any) {
    return await AxiosApiService.post<any>(Url.UPDATE_ORDER_STATUS_BY_ORDER_ID, statusDetails)
}

async function changeOrderStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_ORDER_STATUS, statusDetails)
}

async function changeOfferStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_OFFER_STATUS, statusDetails)
}

async function changeSampleRequestStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_SAMPLE_REQUEST_STATUS, statusDetails)
}

async function updateSampleRequestOwnerStatus(requestData:any) {
    return await AxiosApiService.put<any>(Url.UPDATE_SAMPLE_REQUEST_OWNER_STATUS, requestData);
}

async function getItemsByOrderId(orderId: any,dashboardPrefrence: string) {
    return await AxiosApiService.getWithParams<any>(Url.GET_ORDER_ITEMS_BY_ORDER_ID + '/' + orderId, { 
        params: {
            dashboardPrefrence: dashboardPrefrence
        }
    } );
}

async function getOrderSummaryByOrderId(orderId: any, dashboardPrefrence: any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_ORDER_SUMMARY_BY_ORDER_ID + '/' + orderId, { 
        params: {
            dashboardPrefrence: dashboardPrefrence
        }
    });
}

async function fetchSampleRequestById(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_SAMPLE_REQUEST_BY_ID + "/" + id);
}

async function uploadSampleRequestDocs(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_SAMPLE_REQUEST_DOC, fileUpload)
}

async function fetchSampleRequestDocs(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_SAMPLE_REQUEST_DOCS + "/" + id);
}

async function fetchSampleRequestHistory(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_SAMPLE_REQUEST_HISTORY + "/" + id);
}

async function downloadSampleRequestDOC(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_SAMPLE_REQUEST_DOC, downloadDoc);
}

async function uploadOrderRequestDocs(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_ORDER_REQUEST_DOC, fileUpload)
}

async function fetchOrderRequestDocs(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_ORDER_REQUEST_DOCS + "/" + id);
}

async function fetchOrderRequestHistory(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_ORDER_REQUEST_HISTORY + "/" + id);
}

async function downloadOrderRequestDOC(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_ORDER_REQUEST_DOC, downloadDoc);
}

async function getCompanyWiseSampleRequests(companyId: any, isFetchOpenRequests: Boolean,dashboardPrefrence: string,page:any, size:any, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_COMPANY_WISE_SAMPLE_REQUEST + '/' + companyId + '/' + isFetchOpenRequests,
        {
            page: page,
            size: size,
            dashboardPrefrence: dashboardPrefrence
        }, filter, { signal });
}

async function getCompanyWiseOfferRequests(companyId: any, isFetchOpenRequests: Boolean,dashboardPrefrence: string,page:any,size:any, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_COMPANY_WISE_OFFER_REQUEST + '/' + companyId + '/' + isFetchOpenRequests, 
        {
            dashboardPrefrence: dashboardPrefrence,
            page:page,
            size:size,
        }, filter, { signal });
}

async function getCompanyWiseOrderRequests(companyId: any, isFetchOpenRequests: boolean, dashboardPrefrence: any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_COMPANY_WISE_ORDER_REQUEST + '/' + companyId + '/' + isFetchOpenRequests, {
        params: {
            dashboardPrefrence: dashboardPrefrence
        }
    });
}

async function getCompanyWiseOrderRequestsPagination(companyId: any, isFetchOpenRequests: boolean, dashboardPrefrence: any,page :number,size:number, filter: any, signal: AbortSignal) {
    return await AxiosApiService.postWithSearchParams<any>(Url.GET_COMPANY_WISE_ORDER_REQUEST_PAGINATION + '/' + companyId + '/' + isFetchOpenRequests, 
        {
            page: page,
            size: size,
            dashboardPrefrence: dashboardPrefrence
        }, filter, { signal });
}

async function removeItemFromCart(id:any) {
    return await AxiosApiService.get<any>(Url.REMOVE_ITEM_FROM_CART + '/' + id);
}

async function fetchOfferRequestById(id : any) {
    return await AxiosApiService.get<any>(Url.FETCH_OFFER_REQUEST_BY_ID + "/" + id);
}

async function fetchOfferRequestDocs(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_OFFER_REQUEST_DOCS + "/" + id);
}

async function fetchOfferRequestHistory(id : any) {
    return await AxiosApiService.get<Object>(Url.FETCH_OFFER_REQUEST_HISTORY + "/" + id);
}

async function uploadOfferRequestDocs(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_OFFER_REQUEST_DOC, fileUpload)
}

async function downloadOfferRequestDOC(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_OFFER_REQUEST_DOC, downloadDoc);
}

async function getUnseenMessageCount() {
    return await AxiosApiService.get<any>(Url.GET_ALL_UNSEEN_NOTIFICATION_COUNT);
}

async function getAllNotifications() {
    return await AxiosApiService.get<any>(Url.FETCH_ALL_NOTIFICATION);
    
}

async function updateIsSeenFlag() {
    return await AxiosApiService.get<any>(Url.UPDATE_IS_SEEN_FLAG);
}

async function getOrderItem(id:any) {
    return await AxiosApiService.get<any>(Url.GET_ORDER_ITEM_BY_ID + "/" +id);
}

async function updateOrderItem(orderItem:any) {
    return await AxiosApiService.post<any>(Url.UPDATE_ORDER_ITEM, orderItem);   
}

async function updateOrderItemStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.UPDATE_ORDER_ITEM_STATUS, statusDetails)
}

async function changeOwnerStatusForOffer(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.CHANGE_OWNER_STATUS_FOR_OFFER, statusDetails)
}

async function getAllBarrels(offerRequestId:any, groupId:any) {
    return await AxiosApiService.getWithParams<any>(Url.GET_ALL_BARRELS,  {
        params: {
            offerRequestId: offerRequestId,
            groupId: groupId
        }
    });
}

async function getReservedBarrelsCount(offerRequestId: any) {
    return await AxiosApiService.get<any>(Url.GET_RESERVED_BARRELS_COUNT + '/' + offerRequestId);
}

async function updateReservedBarrelDetails(offerRequestId:any, barrelDetails:any) {
    return await AxiosApiService.post<any>(Url.UPDATE_RESERVED_BARREL_DETAILS_FOR_OFFER_REQUEST + '/' + offerRequestId, barrelDetails);
}