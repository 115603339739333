import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@mui/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/InventoryCSV.scss";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { InventoryApiService } from "../../Service/InventoryApiService";
import { CookieService } from "../../Service/CookieService";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import UploadCSV from "../popups/UploadCSV";
import SingleApproveCSV from "../popups/SingleApproveCSV";
import SingleRejectCSV from "../popups/SingleRejectCSV";
import SinglePublishCSV from "../popups/SinglePublishCSV";
import { Button, Form } from "react-bootstrap";
import ProductDetail from "./ProductDetail";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Swal from "sweetalert2";
import StatusDropdown from "../Common/StatusDropdown";
import SearchBar from "../Static/SearchBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadCSVForUser from "../popups/UploadCSVForUser";


const InventoryCSV = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const currentPath = location.pathname;
  const { id } = useParams<{ id: string }>();
  const controllerRef = useRef<AbortController | null>(null);
  const [filter, setFilter] = useState<any>({
    firstName: null,
    userEmail: null,
    companyName: null,
    fileName: null,
    requestNumber: null,
    sellRequestName: null,
    requestedOn: null,
    feePerBarrel: null,
    status: null
  });
  const cursorPosition = useRef<number | null>(null);

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const [handleShow, setHandleShow] = useState(false);
  const statusDetailsRef = useRef({ id: "", status: "", fileName: "", feeType: "", platformFee: "" });
  const [status, setStatus] = useState<any>();

  const handleStatusChange = async (status: string, id: string, fileName: string, feeType: any, platformFee:any) => {
    console.log(status);

    if (status != "Accepted" && status != "Rejected" && status != "Published") {
      const orderStatus = status?.replace(/ /g, "_");

      //create the object to send
      const sd = {
        id: id,
        status: orderStatus?.toUpperCase(),
        fileName: fileName,
        feeType: feeType,
        platformFee: platformFee,

      };

      console.log("this is calling and this is the data " + sd.fileName);
      statusDetailsRef.current = sd;
      InventoryApiService.updateInventoryCSV(statusDetailsRef.current).then(
        (response) => {
          CommonService.Toast.fire({
            title: "Sell request status updated successfully",
            icon: "success",
          });
          fetchData(page,pageSize, filter);
        }
      );
    } else {
      setHandleShow(true);
    }
  };

  const fetchData = async (page:any, size:any, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setHandleShow(false);
      setTableData([]);

       // Cancel the previous request if any
       if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response: any = await InventoryApiService.getInventoryCSV(
        CommonService.isAdmin() ? id : CommonService.getCompanyId(),
        page ?? 0, // Fallback to 0 if page is null or undefined
        size ?? 10, // Fallback to 10 if size is null or undefined
        false,
        filter ?? { // Fallback to default filter object if filter is null or undefined
          firstName: null,
          userEmail: null,
          companyName: null,
          fileName: null,
          requestNumber: null,
          sellRequestName: null,
          requestedOn: null,
          feePerBarrel: null,
          status: null,
        },
        signal
      );
      
      setTableData(response.data.content);
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) { 
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, filter);
  }, []);

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    console.log("Visibility button clicked for row:", params.row);
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  const handleFilterChange = (event: any, field: keyof any) => {
    const value = event.target.value;
  
    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: field === "status" && value === "" ? null : value, // Update field only if the condition is met
    };
  
    cursorPosition.current = event.target.selectionStart;
console.log(cursorPosition.current)
    // Update the filter state
    setFilter(tempFilter);
  
    // Load the service requests with updated filters
    fetchData(page, pageSize, tempFilter);
  };

  const columns: GridColDef[] = [];

  if (CommonService.isAdmin()) {
    columns.push({
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.ecommUser.firstName || ""} ${row.ecommUser.lastName || ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                onFocus={(e) => {
                  // Restore the cursor position on focus
                  if (cursorPosition.current !== null) {
                    e.target.selectionStart = cursorPosition.current;
                  }
                }}
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.firstName}
                    defaultValue={filter.firstName}
                    onChange={(event) => handleFilterChange(event, "firstName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    });

    columns.push({
      field: "ecommUser.email",
      headerName: "Email",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.ecommUser.email || ""} `,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                onFocus={(e) => {
                  // Restore the cursor position on focus
                  if (cursorPosition.current !== null) {
                    e.target.selectionStart = cursorPosition.current;
                  }
                }}
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.userEmail}
                    defaultValue={filter.userEmail}
                    onChange={(event) => handleFilterChange(event, "userEmail")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    });

    columns.push({
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.companyName || ""} `,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                onFocus={(e) => {
                  // Restore the cursor position on focus
                  if (cursorPosition.current !== null) {
                    e.target.selectionStart = cursorPosition.current;
                  }
                }}
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.companyName}
                    defaultValue={filter.companyName}
                    onChange={(event) => handleFilterChange(event, "companyName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    });
  }

  columns.push({
    field: "fileName",
    headerName: "Inventory File",
    flex: 1,
    renderCell: (params) => {
      const csvFileName = params.value;
      if (csvFileName) {
        const parts = csvFileName.split("_");
        const fileNameToShow = parts.slice(1).join("_");
        return fileNameToShow;
      } else {
        return "";
      }
    },
    headerClassName: "table-header border-0 my-2 h-100",
    renderHeader: (params) => (
      <div>
          <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
              {params.colDef.headerName}
          </Typography>
          <div className="position-relative">
              <input
              onFocus={(e) => {
                // Restore the cursor position on focus
                if (cursorPosition.current !== null) {
                  e.target.selectionStart = cursorPosition.current;
                }
              }}
                  type="text"
                  className="form-control border mt-1"
                  style={{ textIndent: "24px", boxShadow: "none" }}
                  placeholder="Search"
                  //value={filter.fileName}
                  defaultValue={filter.fileName}
                  onChange={(event) => handleFilterChange(event, "fileName")}
                  onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              />
              <SearchIcon
                  sx={{
                      color: "#6F6F6F",
                      position: "absolute",
                      top: "50%",
                      left: "8px",
                      transform: "translateY(-50%)",
                  }}
              />
          </div>
      </div>
    ),
  });

  columns.push({
    field: "requestNumber",
    headerName: "Request Number",
    flex: 1,
    valueGetter: (value: any, row: any) =>
      `${row.requestNumber || "-"}`,
    headerClassName: "table-header border-0 my-2 h-100",
    renderHeader: (params) => (
      <div>
          <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
              {params.colDef.headerName}
          </Typography>
          <div className="position-relative">
              <input
                  type="text"
                  className="form-control border mt-1"
                  style={{ textIndent: "24px", boxShadow: "none" }}
                  placeholder="Search"
                  //value={filter.requestNumber}
                  defaultValue={filter.requestNumber}
                  onChange={(event) => handleFilterChange(event, "requestNumber")}
                  onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                  onFocus={(e) => {
                    // Restore the cursor position on focus
                    if (cursorPosition.current !== null) {
                      e.target.selectionStart = cursorPosition.current;
                    }
                  }}
              />
              <SearchIcon
                  sx={{
                      color: "#6F6F6F",
                      position: "absolute",
                      top: "50%",
                      left: "8px",
                      transform: "translateY(-50%)",
                  }}
              />
          </div>
      </div>
    ),
  });

  columns.push({
    field: "sellRequestName",
    headerName: "Name of Sell Request",
    flex: 1,
    valueGetter: (value: any, row: any) =>
      `${row.sellRequestName || "-"}`,
    headerClassName: "table-header border-0 my-2 h-100",
    renderHeader: (params) => (
      <div>
          <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
              {params.colDef.headerName}
          </Typography>
          <div className="position-relative">
              <input
              onFocus={(e) => {
                // Restore the cursor position on focus
                if (cursorPosition.current !== null) {
                  e.target.selectionStart = cursorPosition.current;
                }
              }}
                  type="text"
                  className="form-control border mt-1"
                  style={{ textIndent: "24px", boxShadow: "none" }}
                  placeholder="Search"
                  //value={filter.sellRequestName}
                  defaultValue={filter.sellRequestName}
                  onChange={(event) => handleFilterChange(event, "sellRequestName")}
                  onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              />
              <SearchIcon
                  sx={{
                      color: "#6F6F6F",
                      position: "absolute",
                      top: "50%",
                      left: "8px",
                      transform: "translateY(-50%)",
                  }}
              />
          </div>
      </div>
    ),
  });
  
  columns.push({
    field: "createdDate",
    headerName: "Submitted On",
    flex: 1,
    valueGetter: (value: any, row: any) => {
      const dateTimeString = row.createdDate ? row.createdDate : null;
      if (dateTimeString) {
        //call the method which formate the time
        return CommonService.formatDateTime(dateTimeString);
      }
    },
    headerClassName: "table-header border-0 my-2 h-100",
    renderHeader: (params) => (
      <div>
          <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
              {params.colDef.headerName}
          </Typography>
          <input
              type="date"
              className="form-control border mt-1"
              style={{ boxShadow: "none" }}
              id="birthdate"
              name="birthdate"
              placeholder="MM/DD/YYYY"
              //value={filter.requestedOn}
              defaultValue={filter.requestedOn}
              onChange={(event) => handleFilterChange(event, "requestedOn")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
          />
      </div>
    ),
  });

  columns.push({
    cellClassName: "purple-cell",
    field: "platformFee",
    headerName: "Fee(per Barrel)",
    flex: 1,
    valueGetter: (value: any, row: any) => {
      if (row.feeType != null && row.platformFee != null) {
        if (row.feeType == "PERCENTAGE") {
          return row.platformFee + "%" + " / " + "Barrel";
        } else return "$" + row.platformFee + " / " + "Barrel";
      } else {
        return "";
      }
    },
    headerClassName: "table-header border-0 my-2 h-100",
    renderHeader: (params) => (
      <div>
          <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
              {params.colDef.headerName}
          </Typography>
          <div className="position-relative">
              <input
              onFocus={(e) => {
                // Restore the cursor position on focus
                if (cursorPosition.current !== null) {
                  e.target.selectionStart = cursorPosition.current;
                }
              }}
                  type="text"
                  className="form-control border mt-1"
                  style={{ textIndent: "24px", boxShadow: "none" }}
                  placeholder="Search"
                  //value={filter.feePerBarrel}
                  defaultValue={filter.feePerBarrel}
                  onChange={(event) => handleFilterChange(event, "feePerBarrel")}
                  onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              />
              <SearchIcon
                  sx={{
                      color: "#6F6F6F",
                      position: "absolute",
                      top: "50%",
                      left: "8px",
                      transform: "translateY(-50%)",
                  }}
              />
          </div>
      </div>
    ),
  });

  columns.push({
    field: "status",
    headerName: "Status",
    headerClassName: "table-header border-0 my-2 h-100",
    cellClassName: "status-badge",
    flex: 1,
    renderHeader: (params) => (
      <>
          <div>
              <Typography
                  variant="body2"
                  sx={{ color: "#6F6F6F", fontSize: "14px" }}
              >
                  {params.colDef.headerName}
              </Typography>
              <div className="position-relative">
                  <Form.Select
                      style={{ cursor: "pointer",borderBottomColor:"#D1D1D1",marginTop:"4px" }}
                      value={filter.status}
                      className="form-control mb-1"
                      onChange={(event) => handleFilterChange(event, "status")}
                      onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                  >
                      <option value="">Select Status</option>
                      <option value="PENDING_REVIEW">Pending Review</option>
                      <option value="UNDER_REVIEW">Under Review</option>
                      <option value="ACCEPTED">Accepted</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="FINALIZED">Finalized</option>
                      <option value="PUBLISHED">Published</option>
                      <option value="CANCELLED">Cancelled</option>
                  </Form.Select>
              </div>
          </div>
      </>
    ),
    renderCell: (params: any) => (
      <div>
        
          {/* <Button
            style={{
              background:
                params.row.status === "APPROVED"
                  ? "green" // Green for 'approved'
                  : params.row.status === "REJECTED"
                  ? "red" // Green for 'approved'
                  : params.row.status === "PENDING_REVIEW"
                  ? "#e8eaeb" // Gray for 'submitted'
                  : params.row.status === "FINALIZED"
                  ? "#50b1ba"
                  : params.row.status === "PUBLISHED"
                  ? "#50b1ba"
                  : "",
              color:
                params.row.status === "PENDING_REVIEW" ? "#000000DE" : "white", // Gray for 'submitted'
              border: "1px transparent",
              padding: "revert", // Default color if none of the conditions are met
            }}
            size="sm"
          >
            {" "}
            {toTitleCase(params.row.status)}
          </Button> */}
          {/* <StatusDropdown
            currentStatus={params.row.status}
            disableCondition = {CommonService.isAdmin() || CommonService.isUser()}
            onStatusChange={(newStatus)=>{
              
            }}
            allowedLabels={[]} */}

<div onClick={(e) => e.stopPropagation()}>

          {(params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && (
                <>
                  {CommonService.isUser() && params.row?.status === "PENDING_REVIEW" && (<StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={false}
                    allowedLabels={['Cancelled']}
                  />)}

                {CommonService.isUser() && params.row?.status === "UNDER_REVIEW" && (<StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={false}
                    allowedLabels={['Cancelled']}
                  />)}  

                  {CommonService.isAdmin() && (
                    <>
                      <StatusDropdown
                        currentStatus={params.row?.status}
                        onStatusChange={(newStatus) => {
                          setStatus(newStatus);
                          console.log(newStatus);
                          handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                        }}
                        disableCondition={false}
                        allowedLabels={['Accepted', 'Rejected', 'Under Review']}
                      />

                      {status == "Accepted" && handleShow && (
                        <SingleApproveCSV
                                  fetchData={fetchData}
                                  inventoryCsv={params.row}
                                  isFromDropdown={true}
                                  popupShow = {handleShow}
                                  
                                />
                      )}

                      {status == "Rejected" && handleShow && (
                         <SingleRejectCSV
                                   fetchData={fetchData}
                                   rejectUser={params.row}
                                   isFromDropdown={true}
                                   popupShow = {handleShow}
                                 />
                      )}
                    </>
                  )}
                </>
              )}

              {params.row?.status === "ACCEPTED" && (
                <>

                  <StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={CommonService.isAdmin()}
                    allowedLabels={["Finalized"]}
                  />

                  {status === "Finalized" && handleShow && (
                   <SinglePublishCSV
                   fetchData={fetchData}
                   inventoryCsv={params.row}
                   isFromDropdown={true}
                   popupShow = {true}
           />
                  )}
                </>
              )}

              {params.row?.status === "FINALIZED" && (
                <>
                  <StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus,params.row?.id, params.row?.fileName, params.row?.feeType, params.row?.platformFee);
                    }}
                    disableCondition={CommonService.isUser()}
                    allowedLabels={["Published"]}
                  />
                  {status === "Published" && handleShow && (
                      <SinglePublishCSV
                                fetchData={fetchData}
                                inventoryCsv={params.row}
                                isFromDropdown={true}
                                popupShow = {true}
                        />
                  )}
                </>
              )}

              {(params.row?.status === "CANCELLED" ||
                params.row?.status === "REJECTED" ||
                params.row?.status === "PUBLISHED") && (
                  <StatusDropdown
                    currentStatus={params.row?.status}
                    onStatusChange={() => { }}
                    disableCondition={true}
                    allowedLabels={[]}
                  />
                )}

          

       
        {params.row.status === "REJECTED" && (
          <Tooltip title={`Rejection Note: ${params.row.rejectionNote}`} arrow>
            <StickyNote2Icon
              className="note-icon cursor-pointer"
              style={{ color: "gray" }}
            />
          </Tooltip>
        )}
        </div>
      </div>
    ),
  });

  // columns.push({
  //   field: "action",
  //   headerName: "Action",
  //   sortable: false,
  //   width: 200,
  //   headerClassName: "table-header",


  //   renderCell: (params: any) => (
  //     <div onClick={(e) => e.stopPropagation()}>
  //       {params.row.status === "APPROVED" && CommonService.isUser() && (
  //         <SinglePublishCSV
  //           fetchData={fetchData}
  //           inventoryCsv={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
  //         />
  //       )}
  //       {params.row.status === "PENDING_REVIEW" && CommonService.isAdmin() && (
  //         <SingleApproveCSV
  //           fetchData={fetchData}
  //           inventoryCsv={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
            
  //         />
  //       )}
  //       {params.row.status === "PENDING_REVIEW" && CommonService.isAdmin() && (
  //         <SingleRejectCSV
  //           fetchData={fetchData}
  //           rejectUser={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
  //         />
  //       )}
  //       {params.row.status === "FINALIZED" && CommonService.isAdmin() && (
  //         <SinglePublishCSV
  //           fetchData={fetchData}
  //           inventoryCsv={params.row}
  //           isFromDropdown={false}
  //           popupShow = {false}
  //         />
  //       )}
  //     </div>
  //   ),
  // });

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    console.log(newSelection.length);
  };

  // Filter rows based on search query
  // const filteredRows = tableData.filter(
  //   (row) =>
  //     CommonService.isAdmin() ?
  //       row.ecommUser.email?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.ecommUser.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.ecommUser.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.ecommUser.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.fileName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       (row.ecommUser.firstName + " " + row.ecommUser.lastName).toLowerCase().includes(search?.toLowerCase())
  //     :
  //       row.fileName?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = tableData.map((rowData, index) => ({
    ...rowData
  }));

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/view-sell-request/${params.row.id}`);
  }

  const handleBack = () => {
    if(currentPath == "/master/inventory-sell-requests")
      history.push("/master/dashboard")
    else
      history.push("/master/company-dashboard/"+id)
    
  }

  return (
    <div className="usersList" style={{paddingBottom:CommonService.isAdmin() ? 47 : ""}}>
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
      {CommonService.isAdmin() && (
            <IconButton
              onClick={handleBack}
              aria-label="ArrowBackIcon"
              sx={{
                backgroundColor: "#FFF",
                mb: 1,
                "&:hover": { backgroundColor: "#FFF" },
              }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
      )}

      {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-2">
              <h1 className="title mb-0 ms-2">
                {CommonService.isAdmin() && "Inventory Sell Requests"}
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
      )}
        <div className="header--menu ms-auto mb-2">
          <div className="header--menu-items row d-flex justify-content-end">
            <div className="col-auto mb-2">
              <SearchBar search={search} setSearch={setSearch} />
            </div>

            <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2">
              {CommonService.isAdmin() && <UploadCSV fetchData={fetchData} />}

            {CommonService.isUser() &&  <UploadCSVForUser fetchData={fetchData} inventoryCount={tableData.length} />}
             {/* <button className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1`} >
                          Need Review
                          <span className="review--number d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">{reviewUserCount}</span>
                      </button> */}
            </div>
          </div>

        </div>
      </div>
      <div className="user-table-container">
        <div className="userTable">

        <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: pageSize},
              },
            }}
            pagination
            paginationModel={{
              page,
              pageSize,
            }}
            onPaginationModelChange={(newPaginationModel) => {
              setPage(newPaginationModel.page);
              setPageSize(newPaginationModel.pageSize);

              fetchData(newPaginationModel.page, newPaginationModel.pageSize, filter);

            }}
            rowCount={totalCount}
            paginationMode="server"
            pageSizeOptions={[10, 20, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick={true}
            localeText={localeText}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryCSV;