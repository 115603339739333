import React, { useEffect, useRef, useState } from "react";
import { OrderApiService } from "../../Service/OrderApiService";
import { useHistory, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ViewProductDetail from "./ViewProductDetail";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MessageApiService } from "../../Service/MessageApiService";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import { InventoryApiService } from "../../Service/InventoryApiService";
import Customspinner from "../Common/CustomSpinner";
import BarrelSelectionPanel from "./BarrelSelectionPanel";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '../../assets/images/icons/EditIcon.svg';
import EditIconWhite from '../../assets/images/icons/EditIconWhite.svg';
import DownLoadIcon from '../../assets/images/icons/DownLoadIcon.svg';

const ViewOfferRequest: React.FC = () => {

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [offerRequest, setOfferRequest] = useState<any>(null);
  const [offerRequestDocs, setOfferRequestDocs] = useState<any[]>([]);
  const [offerRequestHistoryData, setOfferRequestHistoryData] = useState<any[]>([]);
  const rejectionNote = useRef("");
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "",companyId: "", ownerStatus: "", ownerRejectionNote: "", cancellationReason: "", trackNumber: "", groupId: "", quantity: ""});
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [isOwnerStatusChange, setIsOwnerStatusChange] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const handleViewClose = () => setViewDetails(prev => !prev);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [unreadGroupsCount, setUnreadGroupsCount] = React.useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
  const [isCancelled, setIsCancelled] = useState(false);
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [isRejected, setIsRejected] = useState(false);
  const [isInTransit, setIsInTransit] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>("");
  const [totalAvailableBarrelsCount, setTotalAvailableBarrelsCount] = useState(0);
  const [totalReservedBarrelsCount, setTotalReservedBarrelCount] = useState(0);
  const [isNotEnoughBarrels, setIsNotEnoughBarrels] = useState(false);
  const [isNotSelectedRequiredBarrel, setNotSelectedRequiredBarrel] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [viewBarrelSelection, setViewBarrelSelection] = useState(false);
  const handleShowBarrelSelection = () => setViewBarrelSelection(true);


  const handleClose = () => {
    setShow(false);
    setIsCancelled(false);
    setCancellationReason('');
    setError("");
    setNote("");
    setTrackNumber("");
    setIsRejected(false);
    setIsInTransit(false);
    setFiles([]);
  };
  

  const handleCloseBarrelSelection = () => {
    setViewBarrelSelection(false);

    fetchTotalAvailableBarrelCount(offerRequest?.groupId);
    fetchTotalReservedBarrelCount();
  }

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const routeBack = () => {
    if (CommonService.isUser()) history.push("/user/offer-request");
    else history.push("/master/offer-request");
  };

  //  Initial data fetching on component load
  useEffect(() => {
    const initializeData = async () => {
      await fetchData();
      
      if (CommonService.isAdmin()) {
        await getAdminUnreadMessageGroupsCount();
      }
    };

    initializeData();
  }, []);


  //Fetches all required data for the offer request
  const fetchData = async () => {
    try {
      fetchOfferRequestDetail();
      fetchOfferRequestDocs();
      fetchOfferRequestHistory();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


   /**
   * Fetches detailed information about the offer request
   * Also handles setting up seller status and barrel counts for admins
   */
   const fetchOfferRequestDetail = async () => {
    setIsLoading(true);
    try {
      const response = await OrderApiService.fetchOfferRequestById(id);
      

      // Determine if current user is the seller
      const hasSeller = CommonService.isSameCompany(response.data?.typeSenseResponse?.company_id) && 
                       CommonService.isUser();
      setIsSeller(hasSeller);

      // Handle admin-specific data fetching
      if (CommonService.isAdmin()) {
        await fetchTotalAvailableBarrelCount(response?.data?.groupId);
        
        const isNotReviewStatus = response?.data?.status !== "PENDING_REVIEW" && 
                                 response?.data?.status !== "UNDER_REVIEW";
        if (isNotReviewStatus) {
          await fetchTotalReservedBarrelCount();
        }
      }

      //now set the data in offer request 
      setOfferRequest(response.data);
    } catch (error) {
      console.error('Error fetching offer request details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetches the total available barrel count for a given group
  const fetchTotalAvailableBarrelCount = async (groupId: string) => {
    setIsSpinner(true);
    try {
      const response = await InventoryApiService.getAllBarrelsCount(groupId);
      setTotalAvailableBarrelsCount(response?.data);
    } catch (error) {
      console.error('Error fetching available barrels count:', error);
    } finally {
      setIsSpinner(false);
    }
  };

  //Fetches the total reserved barrel count
  const fetchTotalReservedBarrelCount = async () => {
    setIsSpinner(true);
    try {
      const response = await OrderApiService.getReservedBarrelsCount(id);
      setTotalReservedBarrelCount(response?.data);
    } catch (error) {
      console.error('Error fetching reserved barrels count:', error);
    } finally {
      setIsSpinner(false);
    }
  };

  const fetchOfferRequestDocs = async () => {
    const response: any = await OrderApiService.fetchOfferRequestDocs(id);
    setOfferRequestDocs(response.data);
  };

  const fetchOfferRequestHistory = async () => {
    const response: any = await OrderApiService.fetchOfferRequestHistory(id);
    setOfferRequestHistoryData(response.data);
  };

  const getAdminUnreadMessageGroupsCount = async () => {
    try {
      const response = await MessageApiService.getAdminMessageGroups(id, "OFFER_REQUEST");

      setUnreadGroupsCount(response.data.unreadGroupsCount);
      setUnreadMessageCount(response.data.unreadMessageCount);
    } catch (error) {
      console.log("error fetching Admin Message Groups Count" + error);
    }
  }

  //handle edit button click
  const handleEditButtonClick = () => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  //handle message box click
  const handleMessageBoxClick = () => {
    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setOpenEditSection(false);
      setMessageBoxOpen(true);
    }
  };

  const handleAdminMessageClick = () => {
    setShowMessageBox(prev => !prev);

    // Reset unread groups count when the message box is toggled
    setUnreadGroupsCount(unreadGroupsCount > 0 && unreadMessageCount > 0 ? unreadGroupsCount - 1 : unreadGroupsCount);
  };

  const downloadOfferRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: offerRequest.siteId,
      companyId: offerRequest.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadOfferRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }


 

  const handleStatusChange = async (newStatus: string) => {

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      companyId: CommonService.getCompanyId(),
      ownerStatus:null,
      ownerRejectionNote:null,
      cancellationReason: '',
      trackNumber: '',
      groupId: offerRequest?.typeSenseResponse?.group_id,
      quantity: offerRequest?.quantity
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED ) {
      setIsCancelled(true);
      handleShow();
    } else if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      setIsRejected(true);
      handleShow();
    } else if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT) {
      setIsInTransit(true);
      handleShow();
    } 
    else {
      await changeOfferStatus();
      fetchOfferRequestHistory();
    }
  };


  const changeOfferStatus = async () => {

    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter rejection note");
      return;
    } else if(statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED && cancellationReason.trim().length === 0) {
      setError("Please enter cancellation reason");
      return ;
    } else if(statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT && trackNumberValue.trim().length === 0) {
      setError("Please enter tracking number");
      return ;
    }

    setIsLoading(true);

    //to prevent to reflect the data in ui 
    if(statusDetailsRef.current.status == Constant.OrderStatus.ACCEPTED) {
      setIsSpinner(true);
    }
    
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      statusDetailsRef.current.cancellationReason = cancellationReason;
      statusDetailsRef.current.trackNumber = trackNumberValue;
      statusDetailsRef.current.companyId = CommonService.getCompanyId();
      statusDetailsRef.current.groupId =  offerRequest?.typeSenseResponse?.group_id;
      
      const response = await OrderApiService.changeOfferStatus(statusDetailsRef.current);


      if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT
        && (files.length > 0)) {
       uploadOfferRequestDocs();
     }
      
      CommonService.Toast.fire({
        title: "Offer Request updated successfully",
        icon: "success",
      });

      handleClose();

    } catch (error) {

      if(statusDetailsRef.current.status == Constant.OrderStatus.ACCEPTED) {
        setIsNotEnoughBarrels(true);
        CommonService.Toast.fire({
          title: "Out of Stock, please change quantity",
          icon: "error",
        });
      }

      if(statusDetailsRef.current.status == Constant.OrderStatus.OWNERSHIP_TRANSFERRED) {
        setNotSelectedRequiredBarrel(true);
        CommonService.Toast.fire({
          title: "Please select the barrels to match the quantity",
          icon: "error",
        });
      }
    } finally {
      await fetchOfferRequestDetail(); 
      setIsLoading(false); 
      setIsSpinner(false);
    }
  };


  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadOfferRequestDocs = async () => {
  
      const formData = new FormData();
      let response;
      let offerRequestDoc = {};
  
      if (files && Array.isArray(files)) {
        files.forEach(function (file: any) {
          formData.append("file", file);
        });
      }
  
      const fileNames = files.map((file) => file.name).join(", ");
  
      offerRequestDoc = {
        offerRequestId: id,
        description: "uploaded " + fileNames + " document",
        userId: CommonService.getUserId(),
        title: fileNames,
        companyId: CommonService.getCompanyId(),
      };
  
      formData.append(
        "param",
        new Blob([JSON.stringify(offerRequestDoc)], {
          type: "application/json",
        })
      );
  
      response = await OrderApiService.uploadOfferRequestDocs(formData);
  
      fetchOfferRequestDocs();
    };

  const handleOwnerStatusChange = async (newStatus: string) => {

    let updatedStatus;

    if (newStatus === "Reject for Owner")
      updatedStatus = "Admin Rejected";
    else if (newStatus === "Accept for Owner")
      updatedStatus = "Admin Accepted";
    else if (newStatus === "Send to Owner")
      updatedStatus = "Pending Review";
    else
      updatedStatus = newStatus;    

    const status = updatedStatus?.replace(/ /g, "_");

    const details = {
        id: id,
        status:null,
        rejectionNote:null,
        ownerStatus: status.toUpperCase(),
        ownerRejectionNote: rejectionNote.current,
        companyId: CommonService.getCompanyId(),
        cancellationReason: '',
        trackNumber: '',
        groupId: offerRequest?.typeSenseResponse?.group_id,
        quantity: offerRequest?.quantity
    };

    statusDetailsRef.current = details;
    if (statusDetailsRef.current.ownerStatus === Constant.OrderStatus.REJECTED || statusDetailsRef.current.ownerStatus === Constant.OrderStatus.ADMIN_REJECTED) {
        setIsOwnerStatusChange(true); // to call method of order item status change
        handleShow();
    } else {
        changeOwnerStatus();
    }
  };

  const changeOwnerStatus = async () => {

    if((statusDetailsRef.current.ownerStatus === Constant.OrderStatus.REJECTED || statusDetailsRef.current.ownerStatus === Constant.OrderStatus.ADMIN_REJECTED) && rejectionNoteValue.trim().length == 0){
      setError("Please enter rejection note");
      return ;
    }

    setIsLoading(true);
    
    try {
      statusDetailsRef.current.ownerRejectionNote = rejectionNote.current;

      await OrderApiService.changeOwnerStatusForOffer(statusDetailsRef.current);
      setIsLoading(false);
      setIsOwnerStatusChange(false); 

      CommonService.Toast.fire({
          title: "Owner status updated successfully",
          icon: "success",
      });

      handleClose();
      fetchOfferRequestDetail();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const handleRejectionNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
  
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  
        // Clear the input value to allow selecting the same file again
        event.target.value = "";
      }
    };

  const handleTrackingNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrackNumber(event.target.value);
    setError("");
  };

  

  const handleCancellationReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCancellationReason(event.target.value);
    setError("");
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const fullFileName = row.fileName || "";
        const fileNameAfterFirstUnderscore = fullFileName.split('_').slice(1).join('_');
        return fileNameAfterFirstUnderscore || fullFileName;
      },
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadOfferRequestDOC(params.row)}
            >
              {/* <CloudDownloadOutlinedIcon /> */}
              <img className="cursor-pointer" 
                          src={DownLoadIcon}
                          alt="EditIcon"
                        />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="usersList position-relative pt-0">

        <div className="usersList__header--wrapper detail-page-header--wrapper d-flex p-3">
          {CommonService.isAdmin() && (
            <div className="usersList__header--inner d-flex justify-content-between gap-4">
              <div className="d-flex align-items-center gap-1">
              <div className="back__button me-1" onClick={routeBack}>
                <IconButton
                  aria-label="ArrowBackIcon"
                  sx={{ backgroundColor: "#FFF" }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>
              </div>
              <div className="header--title me-3">
                <h1 className="title mb-0 text-nowrap">
                  Offer Request Details
                  <i className="bi bi-heart-fill"></i>
                </h1>
              </div>
            </div>
          </div>)}
          <div className="d-flex align-items-center flex-column flex-lg-row justify-content-lg-between w-100 gap-2 gap-lg-0">
          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0 gap-xxl-3">
          <div className="time__details me-3 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Request number
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {offerRequest?.requestNumber}
              </Typography>
            </div>
            {(CommonService.isSameCompany(offerRequest?.companyId) ||
              CommonService.isAdmin()) && (
              <>
                {" "}
                {CommonService.isAdmin() && (<div className="company__details me-3 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Company name
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "#333333",
                      fontSize: { xl: "18px", xs: "16px" },
                      fontWeight: 700,
                    }}
                  >
                    {offerRequest?.companyName}
                  </Typography>
                </div>)}
                <div className="person__details me-3 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Requested by
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                  >
                    {offerRequest?.firstName } {offerRequest?.lastName}
                  </Typography>
                </div>
              </>
            )}
            <div className="time__details me-3 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Requested on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                { !isSeller ? CommonService.formatDateTime(offerRequest?.createdDate) 
                            : CommonService.formatDateTime(offerRequestHistoryData[offerRequestHistoryData.length - 1]?.histories[offerRequestHistoryData[offerRequestHistoryData.length - 1].histories.length - 1]?.createdDate)}
             
              </Typography>
            </div>

            {( offerRequest?.trackNumber && !isSeller &&
          <div className="time__details me-3 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Track Number
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {offerRequest?.trackNumber}
              </Typography>
            </div>)}
          </div>
          
          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
              <IconButton
                aria-label="message"
                size="small"
                onClick={CommonService.isAdmin() ? handleAdminMessageClick : handleMessageBoxClick}
                sx={{
                  borderRadius: "50%",
                  backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF",
                  color: isMessageBoxOpen ? "#FFF" : "#1C1B1F",
                  marginInline: "8px",
                  padding: "8px",
                }}
              >
                <MarkunreadOutlinedIcon />
                {unreadGroupsCount != 0 && CommonService.isAdmin() && <div className="msg-count-wrapper">
                  {unreadGroupsCount}
                </div>}
                {showMessageBox && <ArrowDropUpIcon className="arrow-icon" />}
              </IconButton>
              {/* {showMessageBox &&
                <div className="admin-message-box">
                  <div className="admin-message-box-left-inner" onClick={handleAdminMessageClick}></div>
                  <div className="admin-message-box-inner positio-relative">
                    <AdminMessageBox selectedGroup={""} requestId={id} 
                      requestType={"OFFER_REQUEST"} unreadGroupsCount={unreadGroupsCount}
                      setUnreadGroupsCount={setUnreadGroupsCount} isSeller={isSeller} />
                  </div>
                </div>
              } */}




              {(offerRequest?.status === "PENDING_REVIEW" || offerRequest?.status === "UNDER_REVIEW") && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={(CommonService.isAdmin() && offerRequest?.status === "UNDER_REVIEW" && offerRequest?.hasValidStatus) || (CommonService.isUser() && offerRequest?.companyId != CommonService.getCompanyId())}
                  allowedLabels={CommonService.isUser() ? ["Cancelled"] : (offerRequest?.hasValidStatus ? ["Under Review"] : offerRequest?.hasItemRejected ? ["Under Review", "Rejected"] : ["Under Review","Accepted", "Rejected"])}
                />
              )}


{/* {offerRequest?.status === "ACCEPTED" && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={CommonService.isUser() && offerRequest?.companyId != CommonService.getCompanyId()}
                  allowedLabels={CommonService.isAdmin() ? ["In Progress","Rejected"] : ["Cancelled"]}
                />
              )} */}

              {/* Accepted to Payment Received - Both User and Admin */}
              {["ACCEPTED","IN_PROGRESS", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED","PAYMENT_RECEIVED"].includes(offerRequest?.status) && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser() && offerRequest?.companyId != CommonService.getCompanyId()}
                  allowedLabels={CommonService.isAdmin() ? ["Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "In Progress","Ownership Transferred", "Rejected"] : ["Cancelled"]} 
                />
              )}

{(offerRequest?.status === "OWNERSHIP_TRANSFERRED") && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={(offerRequest?.typeSenseResponse?.is_relocation_required || offerRequest.isRelocationRequired) ? ["In Transit","Rejected"]: ["Rejected","Closed"]}
                />
              )}
  
  {(offerRequest?.status === "IN_TRANSIT") && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Rejected","Closed"]}
                />
              )}

{(offerRequest?.status === "CANCELLED" ||
                offerRequest?.status === "REJECTED" || offerRequest?.status === "CLOSED") && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={true}
                  allowedLabels={[]}
                />
              )}

              {offerRequest?.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${offerRequest?.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
              {offerRequest?.status === "CANCELLED" && (
                <Tooltip
                  title={`Cancellation Reason: ${offerRequest?.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}


              {(CommonService.isAdmin() || !isSeller) && (offerRequest?.status === "PENDING_REVIEW" ||
                offerRequest?.status === "UNDER_REVIEW") && (
                  <Tooltip title="Edit" arrow>
                    <IconButton
                        aria-label="message"
                        size="small"
                        onClick={handleEditButtonClick}
                        sx={{ borderRadius: "50%", backgroundColor: isOpenEditSection
                          ? "#97AEB8" // When only `isOpenEditSection` is true
                          : "#fff", color: "#1C1B1F", marginInline: "8px", padding: "8px" }}

                    >
                      {isOpenEditSection
                ? <img className="cursor-pointer" 
                src={EditIconWhite}
                alt="EditIconWhite"
              />:<img className="cursor-pointer" 
              src={EditIcon}
              alt="EditIcon"
            />
              }
                        
                        
                    </IconButton>
                  </Tooltip>
              )}


            </div>
          </div>
          </div>
        </div>


        <div className="viewSampleRequest--wrapper view_request--wrapper viewOrderRequest--wrapper viewOfferRequest--wrapper">
          <div
            className={`${
              isOpenEditSection
              ? "margin-right-400"  // When only `isOpenEditSection` is true
              : isMessageBoxOpen
              ? "margin-right-400"   // Only `isMessageBoxOpen` is true
              : "no-margin"    
            }`}
          >
            <div className="view-sample-request">
              <div className="row m-0">
              <div className="col-12 px-0">
                <div>
                  <div>
                    <div className="inventory-file-list-table">
                        <div className="table-responsive-lg table-responsive-fixed-header">
                          <Table className='orderRequest-inv-table'>
                            <thead>
                              <tr>
                                <th className='text-nowrap text-start'>Inventory</th>
                                
                                  <th colSpan={2} className="text-center text-nowrap">Owner</th>
                                  <th colSpan={2} className="text-center text-nowrap">Buyer</th>
                              
                                <th className='text-nowrap'>Quantity</th>
                                <th className='text-nowrap'>Offered Price</th>
                                {isSeller && <th className='text-nowrap'>Fee</th>}
                                { !isSeller &&
                                <th colSpan={2} className="text-center text-nowrap">Fees</th>}
                                <th className='text-nowrap'>Total Price</th>
                                {( isSeller || CommonService.isAdmin()) &&
                                <th className='text-nowrap text-center'>Owner Status</th>}
                              </tr>
                              <tr>
                                <th className='text-nowrap text-center'></th>
                               
                                <th className='text-nowrap text-center'>Relocation</th>
                                <th className='text-nowrap text-center'>Cha. of Acc.</th>
                                <th className='text-nowrap text-center'>Relocation</th>
                                <th className='text-nowrap text-center'>Cha. of Acc.</th>
                                
                                <th className='text-nowrap text-center'></th>
                                <th className='text-nowrap text-center'></th>
                                { !isSeller &&<>
                                <th className='text-nowrap text-center'>Handling</th>
                                <th className='text-nowrap text-center'>Service</th>
                                </>}
                                <th className='text-nowrap text-center'></th>
                                {( isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center'></th>}
                                { isSeller &&<th className='text-nowrap text-center'></th>}
                              </tr>
                            </thead>
                            <tbody>
                            {offerRequest ? (
                              <tr>
                                <td className="text-start cursor-pointer" onClick={() => {
                                            handleViewClose();
                                          }}><span className="inventory-name">{offerRequest?.inventoryName}</span>
                                {(isSeller || CommonService.isAdmin()) && (<Typography component="p" sx={{ color: "#737376", fontSize: "13px", fontWeight: "400", lineHeight:"21.28px" }}>
                                    {offerRequest?.inventoryOwnerCompanyName}
                                </Typography>)}
                                </td>
                               
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.typeSenseResponse?.is_relocation_required ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.typeSenseResponse?.is_account_transfer ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.isRelocationRequired ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.isAccountTransfer ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>

                              <td className={`text-nowrap ${!totalAvailableBarrelsCount < offerRequest?.quantity && "pb-4"}`}>
                              <div className="position-relative">
                                   {offerRequest?.quantity} barrels 
                                    <>

                                    {CommonService.isAdmin() && !isSpinner && !['REJECTED', 'CLOSED', 'IN_TRANSIT', 'OWNERSHIP_TRANSFERRED'].includes(offerRequest?.status) && (
    <Tooltip 
        arrow 
        title={`${Intl.NumberFormat().format(totalAvailableBarrelsCount + totalReservedBarrelsCount)} ${(totalAvailableBarrelsCount + totalReservedBarrelsCount) > 1 ? 'Barrels' : 'Barrel'} in Stock`}
    >
        <span 
            className="available-barrels-count ms-2" 
            style={{ 
                backgroundColor: ((totalAvailableBarrelsCount + totalReservedBarrelsCount) < offerRequest?.quantity && totalReservedBarrelsCount !== offerRequest?.quantity) ? "#C7858B" : "#75BC84" 
            }}
        >
            {(totalAvailableBarrelsCount + totalReservedBarrelsCount) >= 10000 ? "9,999+" : Intl.NumberFormat().format(totalAvailableBarrelsCount + totalReservedBarrelsCount)}
        </span>
    </Tooltip>
)}
                   
      {CommonService.isAdmin() && !isSpinner && 
        !['REJECTED','PENDING_REVIEW','UNDER_REVIEW'].includes(offerRequest?.status) && (
          <div>
          <>
            {totalAvailableBarrelsCount >= offerRequest?.quantity && 
              totalReservedBarrelsCount === 0 && (
                <button 
                  onClick={handleShowBarrelSelection} 
                  className="reserve-barrel-btn ps-0"
                >
                  <AddSharpIcon /> Reserve
                </button>
            )}

            {totalReservedBarrelsCount > 0 && (
              <button 
                onClick={handleShowBarrelSelection} 
                className="reserve-barrel-btn ps-0"
              >
                {totalReservedBarrelsCount} Reserved
              </button>
            )}
          </>
          </div>
      )}
      
      {/* {(totalAvailableBarrelsCount+totalReservedBarrelsCount) < offerRequest?.quantity && !isSpinner && 
        isNotEnoughBarrels && 
        CommonService.isAdmin() && (
          <span 
            className="barrel-error" 
            style={{
              bottom: totalAvailableBarrelsCount > 0 ? "-20px" : "-30px"
            }}
          >
            <WarningIcon /> Out of Stock, please change quantity.
          </span>
      )}

    {(totalAvailableBarrelsCount < offerRequest?.quantity || totalReservedBarrelsCount < offerRequest?.quantity) && !isSeller && 
        isNotSelectedRequiredBarrel && 
        CommonService.isAdmin() && (
          <span 
            className="barrel-error" 
            style={{
              bottom: totalAvailableBarrelsCount > 0 ? "-20px" : "-30px"
            }}
          >
            <WarningIcon /> Please select the barrels to match the quantity
          </span>
      )} */}
    
    {(totalAvailableBarrelsCount+totalReservedBarrelsCount) < offerRequest?.quantity && !isSpinner &&
        isNotEnoughBarrels && 
        CommonService.isAdmin() && (
          <span 
            className="barrel-error" 
            style={{
              bottom: totalAvailableBarrelsCount > 0 ? "-40px" : "-25px"
            }}
          >
            <WarningIcon /> Out of Stock, please change quantity.
          </span>
      )}

    {(totalAvailableBarrelsCount < offerRequest?.quantity || totalReservedBarrelsCount < offerRequest?.quantity) && 
        isNotSelectedRequiredBarrel && !isSpinner &&
        CommonService.isAdmin() && (
          <span 
            className="barrel-error" 
            style={{
              bottom: totalAvailableBarrelsCount > 0 ? "-22px" : "-25px"
            }}
          >
            <WarningIcon /> Please select the barrels to match the quantity
          </span>
      )}
                                    </>
                                    </div>
                                </td>



                                <td className='text-nowrap'>
                                  <CurrencyFormatter
                                    amount={offerRequest?.pricePerBarrel}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                  <p className="per-barrel-text">per barrel</p>
                                </td>

                                {isSeller && (
                                <td>
                                  {offerRequest?.typeSenseResponse?.fee_type === 'PERCENTAGE' ? (
                                    <>
                                      (
                                      <CurrencyFormatter
                                        amount={(offerRequest?.pricePerBarrel * offerRequest?.typeSenseResponse?.platform_fee) / 100}
                                        locale="en-US"
                                        currency="USD"
                                      />
                                      
                                      )
                                      <p className="per-barrel-text">per barrel</p>
                                    </>
                                  ) : (
                                    <>
                                      (
                                      <CurrencyFormatter
                                        amount={offerRequest?.typeSenseResponse?.platform_fee}
                                        locale="en-US"
                                        currency="USD"
                                      />
                                      
                                      )
                                      <p className="per-barrel-text">per barrel</p>
                                    </>
                                    
                                  )}
                                </td>
                              )}


                                { !isSeller && <>
                                <td className='text-nowrap text-center'><CurrencyFormatter
                                  amount={offerRequest?.handlingFees/offerRequest?.quantity}
                                  locale="en-US"
                                  currency="USD"
                                />
                                <p className="per-barrel-text">per barrel</p>
                                </td>
                                <td className='text-nowrap text-center'>
                                  <CurrencyFormatter
                                    amount={offerRequest?.serviceFees}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                  
                                </td>
                                </>}

                                  <td className='text-nowrap'>
                                  <CurrencyFormatter
                                    amount={
                                      !isSeller
                                        ? offerRequest?.totalAmount // When not a seller, use the total amount
                                        : (
                                          // When a seller, deduct handling fees, service fees, and platform fee (based on fee type)
                                          offerRequest?.totalAmount
                                          - (offerRequest?.handlingFees + offerRequest?.serviceFees)
                                          - (
                                            offerRequest?.quantity * (offerRequest?.typeSenseResponse?.fee_type === 'PERCENTAGE'
                                              ? (offerRequest?.pricePerBarrel * offerRequest?.typeSenseResponse?.platform_fee) / 100
                                              : offerRequest?.typeSenseResponse?.platform_fee)
                                          )
                                        )
                                    }
                                    locale="en-US"
                                    currency="USD"
                                  />
                                </td>


                                {(isSeller || CommonService.isAdmin()) && <> 
                                <td className='text-nowrap text-center'>
                                  {CommonService.isAdmin() && <StatusDropdown currentStatus={formatStatus(offerRequest?.ownerStatus)}
                                        onStatusChange={(newStatus) =>
                                          handleOwnerStatusChange(newStatus)
                                        }
                                        disableCondition={CommonService.isSameCompany(offerRequest?.companyId) || ["REJECTED", "ADMIN_REJECTED", "ACCEPTED"].includes(offerRequest?.ownerStatus)
                                            || ["ACCEPTED", "REJECTED", "CONTRACT_SENT","IN_PROGRESS", "INVOICE_SENT", "CONTRACT_EXECUTED", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "CANCELLED", "CLOSED", "IN_TRANSIT"].includes(offerRequest?.status)
                                        }
                                        allowedLabels={(offerRequest?.ownerStatus === "ADMIN_ACCEPTED") ? ["Reject for Owner"] 
                                                        : offerRequest?.ownerStatus === "PENDING_REVIEW" ? ["Reject for Owner", "Accept for Owner"] 
                                                        :["Reject for Owner", "Accept for Owner", "Send to Owner"]}/>}
                                  
                                  {CommonService.isUser() && <StatusDropdown currentStatus={formatStatus(offerRequest?.ownerStatus)}
                                            onStatusChange={(newStatus) =>
                                              handleOwnerStatusChange(newStatus)
                                            }
                                            disableCondition={CommonService.isSameCompany(offerRequest?.companyId) || ["REJECTED", "ADMIN_REJECTED"].includes(offerRequest?.ownerStatus)
                                                || ["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "CANCELLED"].includes(offerRequest?.status)
                                            }
                                            allowedLabels={offerRequest?.ownerStatus === "ADMIN_ACCEPTED" ? ["Rejected"] : ["Accepted", "Rejected"]}/>}

                                  {(offerRequest?.ownerStatus === "REJECTED" || offerRequest?.ownerStatus === "ADMIN_REJECTED") && (
                                          <Tooltip
                                          title={`Rejection Note: ${offerRequest.ownerRejectionNote}`}
                                          arrow
                                          >
                                          <StickyNote2Icon
                                              className="note-icon cursor-pointer"
                                              style={{ color: "gray" }}
                                          />
                                          </Tooltip>
                                  )}
                                </td>
                                </>}
                          
                              </tr>
                              ):(<tr>
                                  <td colSpan={12} style={{ textAlign: 'center' }} className='border-0'>
                                    {/* Fetching records... */}
                                    <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Customspinner />
                                    </div>
                                  </td>
                              </tr>)}
                            </tbody>
                          </Table>
                {/* )} */}
                        </div>
                        {viewDetails &&
                          <ViewProductDetail handleViewClose={handleViewClose} inventoryId={offerRequest?.inventoryId}/>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="row m-0">
                <div className="col-xl-6 mb-3 mb-xl-0 ps-0 pe-xl-2 pe-0">
                  <div className="orderDetail-footer-content-wrapper">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-0">List of Documents</h3>
                    <UploadDocs
                      fetchData={fetchData}
                      csvId={id}
                      uploadCSV={false}
                      requestType={"OFFER_REQUEST"}
                    />
                  </div>
                  <div
                    className="inventory-doc-table"
                    // style={{ height: 350, width: "100%" }}
                  >
          <><DataGrid
            localeText={localeText}
            rows={offerRequestDocs}
            columns={columnsForDoc}
            className="data-grid upload-doc-datagrid-table"
            // hideFooterPagination
            hideFooter
            loading={isLoading}
            disableColumnResize
          /></>
          {/* )} */}

                    
                  </div>
                  </div>
                </div>
                <div className="col-xl-6 mb-3 mb-xl-0 pe-0 ps-xl-2 ps-0">
                  <div className="recentActivity h-100">
                    <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">Recent Activity</h3>
                    </div>
                    <div className="history-stepper-wrapper">
                      <HistoryStepper historyData={offerRequestHistoryData} />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {(isOpenEditSection || isMessageBoxOpen) && (
            <div className="msgContainerBox">
              {isOpenEditSection && (
                <EditRequest
                  requestType={"OFFER_REQUEST"}
                  id={id} //reload the date
                  refreshData={fetchData}
                  onEditClick={handleEditButtonClick}
                />
              )}
              {isMessageBoxOpen && (
                <ContainerMessageBox
                  requestType={"OFFER_REQUEST"}
                  sellerCompanyId={isSeller ? CommonService.getCompanyId() : null}
                  isSeller={isSeller}
                />
              )}
            </div>
          )}
          {viewBarrelSelection && <BarrelSelectionPanel requestType={"Offer"} requestId={offerRequest?.id} groupId={offerRequest?.groupId} inventoryName={offerRequest?.inventoryName} requiredQuantity={offerRequest.quantity} companyName={offerRequest.inventoryOwnerCompanyName} currentStatus={offerRequest.status} handleCloseBarrelSelection={handleCloseBarrelSelection} />}
          {showMessageBox &&
                <div className="admin-message-box">
                  <div className="admin-message-box-left-inner" onClick={handleAdminMessageClick}></div>
                  <div className="admin-message-box-inner positio-relative">
                    <AdminMessageBox selectedGroup={""} requestId={id} 
                      requestType={"OFFER_REQUEST"} unreadGroupsCount={unreadGroupsCount}
                      setUnreadGroupsCount={setUnreadGroupsCount} isSeller={isSeller} />
                  </div>
                </div>
              }
        </div>
      </div>

      {/* Status Change Model Pop-up */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >

      <Modal.Header className="flex-column align-items-start">
    {isOwnerStatusChange ? (
        <Modal.Title>Update Owner Status</Modal.Title>
    ) : (
        <Modal.Title>Update Offer Request Status</Modal.Title>
    )}
    <p className="modal-text">
        {isCancelled && (offerRequest?.status === "PENDING_REVIEW" || offerRequest?.status === "UNDER_REVIEW") && 
            "Are you sure you want to cancel?"}
        {isCancelled && !(offerRequest?.status === "PENDING_REVIEW" || offerRequest?.status === "UNDER_REVIEW") && 
            "Are you sure you want to cancel? A cancellation charge may apply."}
        {(isRejected || isOwnerStatusChange) && "Are you sure you want to change status to Rejected?"}
        {isInTransit && "Please enter Tracking Number"}
    </p>
</Modal.Header>


        <Modal.Body className="pb-1">
          <div className="form__inner">
           {isCancelled && (
            <textarea
            className={`form-control ${error ? "is_invalid" : ""}`}
            aria-label="With textarea"
            value={cancellationReason}
            onChange={handleCancellationReasonChange}
            rows={8}
            style={{ resize: "none" }}
            placeholder="Enter Cancellation Reason..."
          />
           )}
           {(isRejected || isOwnerStatusChange) && (<textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNoteValue}
              onChange={handleRejectionNoteChange}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter Rejection Note..."
            />)}
            {isInTransit && ( <><label className="modal-body__title mb-1">Track Number</label><Form.Control
              className={`form-control ${error ? "is_invalid" : ""}`}
              type="text"
              value={trackNumberValue}
              onChange={handleTrackingNumberChange}
              placeholder="Enter Tracking Number..." />
              </>
              )}
            {error && <div className="error-message">{error}</div>}

            {isInTransit && (<div className="d-flex align-items-center h-100 mt-2">
              <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
                <IconButton
                  type="button"
                  aria-label="submit"
                  sx={{
                    transform: "rotate(45deg)",
                    padding: "8px",
                  }}
                  onClick={handleButtonClick}
                >
                  <AttachFileIcon />
                </IconButton>
                <span className="cursor-pointer" onClick={handleButtonClick}>
                  Upload Document
                </span>
                <div className="d-flex align-items-center gap-2 flex-wrap">
                  {files?.map((file, index) => (
                    <div
                      className="file__upload--info d-flex align-items-center mb-1 me-2"
                      key={index}
                    >
                      <div className="file-name me-3">{file.name}</div>
                      <div className="file-delete">
                        <img
                          src={TrashIcon}
                          alt="TrashIcon"
                          onClick={() => handleDeleteFile(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
            )}
           
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                {isInTransit ? "No" : "Cancel"}
                
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={isOwnerStatusChange ? changeOwnerStatus : changeOfferStatus}
                disabled={isLoading}
              >
                {isLoading && <Spinner />} {isCancelled && "Cancel Request"} {(isRejected || isOwnerStatusChange) && "Reject Request"} {isInTransit && "Yes"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewOfferRequest;
