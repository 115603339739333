import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import React from "react";
import SingleReInvite from "../popups/SingleReInvite";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import Constant from "../../Constant";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, ButtonGroup } from "react-bootstrap";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StatusDropdown from "../Common/StatusDropdown";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import Spinner from "../Common/Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TrashIcon from "../../assets/images/icons/Trash.svg";

const ActiveOrder = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewOrderCount, setReviewOrderCount] = useState(0);
  const [search, setSearch] = useState("");
  let accepted: any[] = [];
  const disableAction = useRef(false);
  const [isNeedReviewClicked, setNeedReviewClicked] = useState(false);
  const selectedRows = useRef(accepted);
  const [userRole, setUserRole] = useState<string | null>(null);
  const rejectionNote = useRef("");
  const trackNumber = useRef("");
  const [ttbFile, setTTBFile] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  /* interface StatusDetails {
     id: string;
     status: string;
   }*/
  const statusDetailsRef = useRef({
    id: "",
    status: "",
    rejectionNote: "",
    trackNumber: "",
    quantity: 0,
  });
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [isApproved, setIsApproved] = useState(false); 
  const [isBeforeCancel, setIsBeforeCancel] = useState(false);
  const [isAfterCancel, setIsAfterCancel] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  let dashboardPrefrence = "";
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handleClose = () => {
    setShow(false);
    setNote("");
    setTrackNumber("");
    setError("");
  };
  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  const controllerRef = useRef<AbortController | null>(null);
  const [filter, setFilter] = useState<any>({
    requestNumber: null,
    requestedOn: null,
    modifiedOn: null,
    ownerRequestedOn: null,
    ownerModifiedOn: null,
    totalInventories: null,
    quantity: null,
    finalAmount: null,
    companyName: null,
    firstName: null,
    status: null,
  });

  const handleStatusChange = async (newStatus: string, oldStatus: string, rowId: string, quantity: number) => {
    console.log(newStatus);

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: rowId,
      companyId: CommonService.getCompanyId(),
      status: orderStatus.toUpperCase(),
      trackNumber: "",
      rejectionNote: "",
      quantity: quantity,
    };

    statusDetailsRef.current = sd;

    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      setIsApproved(false);
      handleShow();
    } else if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT) {
      setIsApproved(true);
      handleShow();
    } else if (statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED) {
      if(["PENDING_REVIEW", "UNDER_REVIEW"].includes(oldStatus)){
        setIsBeforeCancel(true);
        setIsAfterCancel(false);
      }else if(["ACCEPTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED"].includes(oldStatus)){
        setIsAfterCancel(true);
        setIsBeforeCancel(false);
      }
      handleShow();
    } else if (["ACCEPTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "CLOSED"].includes(statusDetailsRef.current.status)) {
      setIsApproved(false);
      setIsAfterCancel(false);
      setIsBeforeCancel(false);
      handleShow();
    } else {
      changeOrderStatus();
    }
  };

  const changeOrderStatus = async () => {

    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length == 0) {
      setError("Please enter rejection note");
      return;
    }

    if (statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter cancellation note");
      return;
    }

    if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT && trackNumberValue.trim().length === 0) {
      setError("Please enter truck number");
      return;
    }
    setIsLoading(true);

    try {
      if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED ||
        statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED
      ) {
        statusDetailsRef.current.rejectionNote = rejectionNote.current;
      } else if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT) {
        statusDetailsRef.current.trackNumber = trackNumber.current;
      }

      await OrderApiService.changeOrderStatus(statusDetailsRef.current);
      const rowIndex = tableData.findIndex(
        (row) => row.id === statusDetailsRef.current.id
      );

      setIsLoading(false);
      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: statusDetailsRef.current.status,
          rejectionNote: statusDetailsRef.current.rejectionNote,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT
        && (files.length > 0)) {
          uploadOrderRequestDocs();
      }
      handleClose();
      CommonService.Toast.fire({
        title: "Order status updated successfully",
        icon: "success",
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchPendingOrdersCount = async () => {
    try {
      const response = await OrderApiService.getPendingOrdersCount();
      setReviewOrderCount(response.data);
    } catch (error) {
      console.log("error occurred while api calling");
    }
  };

  const fetchAllAcceptedOrders = async (
    page: number,
    pageSize: number,
    filter: any
  ) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      // First fetch the count of pending orders
      //await fetchPendingOrdersCount();

      const response = await OrderApiService.getOrders(
        page,
        pageSize,
        false,
        false,
        null,
        filter,
        signal
      );
      setTableData(response.data.content);
      setTotalCount(response.data.totalElements);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  };

  //get all the pending orders
  const showPendingOrders = async (
    page: number,
    pageSize: number,
    tempFilter
  ) => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (!isNeedReviewClicked) {
      try {
        setNeedReviewClicked(true);
        setIsLoading(true);
        setTableData([]);
        // Cancel the previous request if any
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Store the current controller to cancel the request if needed
        controllerRef.current = controller;

        const response = await OrderApiService.getOrders(
          page,
          pageSize,
          true,
          false,
          null,
          tempFilter,
          signal
        );
        setTableData(response.data.content);
        setTotalCount(response.data.totalElements);
      } catch (error) {
        console.log("error is occurred");
      } finally {
        setIsLoading(false); // Set the loading flag to false after the API call completes
      }
    } else {
      //update the status
      setNeedReviewClicked(false);
      fetchAllAcceptedOrders(page, pageSize, tempFilter);
    }
  };

  const justShowPendingOrders = async (
    page: number,
    pageSize: number,
    filter: any
  ) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getOrders(
        page,
        pageSize,
        true,
        false,
        null,
        filter,
        signal
      );
      setTableData(response.data.content);
      setTotalCount(response.data.totalElements);
    } catch (error) {
      console.log("error is occurred");
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  };

  const history = useHistory();

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
    history.push(`${linkPrefix}/order-detail/${params.row.id}`);
  };

  const isUserRole = userRole === "ADMIN";

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  const clicked = (event: any) => {
    if (userRole === "ADMIN") {
      event.preventDefault();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if ( statusDetailsRef.current.status === Constant.OrderStatus.REJECTED ||
      statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED
    ) {
      setNote(event.target.value);
      setError("");
      rejectionNote.current = event.target.value;
    } else {
      setTrackNumber(event.target.value);
      setError("");
      trackNumber.current = event.target.value;
    }
  };

  const fetchUserRole = () => {
    const cookies = new Cookies();
    const role = cookies.get("userRole");

    setUserRole(role);
    //  if (role == 'SUPER_ADMIN')
    fetchAllAcceptedOrders(page, pageSize, filter);
    // else if (role == 'USER')
    //   showPendingOrders();
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/order-detail/${params.row.id}`);
  };

  const handleBack = () => {
    if (currentPath.startsWith("/master/active-order")) {
      history.push(`/master/dashboard`);
    } else if (currentPath.startsWith("/master/my-warehouse/active-order/"))
      history.push(`/master/company-dashboard/` + id);

    // history.goBack();
  };

  const openOrderRequests = async (
    page: number,
    pageSize: number,
    filter: any
  ) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      if (currentPath.startsWith("/user/view-as-buyer/active-order"))
        dashboardPrefrence = Constant.Profile.BUYER;
      else if (currentPath.startsWith("/user/view-as-owner/active-order"))
        dashboardPrefrence = Constant.Profile.OWNER;
      else dashboardPrefrence = CommonService.getDashboardPrefrence();

      const response: any =
        await OrderApiService.getCompanyWiseOrderRequestsPagination(
          params,
          false,
          dashboardPrefrence,
          page,
          pageSize,
          filter,
          signal
        ); // Applies the status filter only if isFetchOpenRequests is true;
      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: keyof any
  ) => {
    const value = event.target.value;

    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: field === "status" && value === "" ? null : value, // Update field only if the condition is met
    };

    // Update the filter state
    setFilter(tempFilter);

    if (currentPath === "/master/active-order") {
      if (isNeedReviewClicked)
        justShowPendingOrders(page, pageSize, tempFilter);
      else fetchAllAcceptedOrders(page, pageSize, tempFilter);
    } else openOrderRequests(page, pageSize, tempFilter);
  };

  useEffect(() => {
    if (currentPath === "/master/active-order") fetchUserRole();
    else openOrderRequests(page, pageSize, filter);
  }, []);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {

      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const uploadOrderRequestDocs = async () => {
  
    const formData = new FormData();
    let response;
    let OrderRequestDoc = {};

    if (ttbFile && Array.isArray(ttbFile)) {
      ttbFile.forEach(function (file: any) {
        formData.append("file", file);
      });
    }

    const fileNames = ttbFile.map((file) => file.name).join(", ");

    OrderRequestDoc = {
      orderRequestId: id,
      description: "uploaded " + fileNames + " document",
      userId: CommonService.getUserId(),
      title: fileNames,
      companyId: CommonService.getCompanyId()
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(OrderRequestDoc)], {
        type: "application/json",
      })
    );

    response = await OrderApiService.uploadOrderRequestDocs(formData);
  };

  const getStatusOptions = () => {
    const profile = CommonService.getDashboardPrefrence();

    switch (userRole) {
      case "SUPER_ADMIN":
        return (
          <>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="UNDER_REVIEW">Under Review</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="CONTRACT_SENT">Contract Sent</option>
            <option value="CONTRACT_EXECUTED">Contract Executed</option>
            <option value="INVOICE_SENT">Invoice Sent</option>
            <option value="PAYMENT_RECEIVED">Payment Received</option>
            <option value="OWNERSHIP_TRANSFERRED">Ownership Transferred</option>
            <option value="IN_TRANSIT">In Transit</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="CLOSED">Closed</option>
          </>
        );
    }

    switch (profile) {
      case Constant.Profile.BUYER:
        return (
          <>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="UNDER_REVIEW">Under Review</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="CONTRACT_SENT">Contract Sent</option>
            <option value="CONTRACT_EXECUTED">Contract Executed</option>
            <option value="INVOICE_SENT">Invoice Sent</option>
            <option value="PAYMENT_RECEIVED">Payment Received</option>
            <option value="OWNERSHIP_TRANSFERRED">Ownership Transferred</option>
            <option value="IN_TRANSIT">In Transit</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="CLOSED">Closed</option>
          </>
        );
      case Constant.Profile.OWNER:
        return (
          <>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
            <option value="ACCEPTED_REJECTED">Accepted | Rejected</option>
          </>
        );
      default:
        break;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 190,
      renderCell: (params: any) => (
        <CellWithTooltip value={params.row.requestNumber || "-"} />
      ),
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{width:"140px"}}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.requestNumber}
              defaultValue={filter.requestNumber}
              onChange={(event) => handleFilterChange(event, "requestNumber")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
    },
    ...(CommonService.isAdmin()
    ? [
        {
          field: "companyName",
          headerName: "Company Name",
          headerClassName: "table-header border-0 my-2 h-100",
          flex: 1,
          minWidth: 190,
          renderCell: (params: any) => (
            <CellWithTooltip value={params.value} />
          ),
          renderHeader: (params) => (
            <div>
              <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
              >
                {params.colDef.headerName}
              </Typography>
              <div className="position-relative" style={{width:"140px"}}>
                <input
                  type="text"
                  className="form-control border mt-1"
                  style={{ textIndent: "24px", boxShadow: "none" }}
                  placeholder="Search"
                  //value={filter.companyName}
                  defaultValue={filter.companyName}
                  onChange={(event) =>
                    handleFilterChange(event, "companyName")
                  }
                  onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                  sx={{
                    color: "#6F6F6F",
                    position: "absolute",
                    top: "50%",
                    left: "8px",
                    transform: "translateY(-50%)",
                  }}
                />
              </div>
            </div>
          ),
        },
      ]
    : []),
    {
      field: "modifiedDate",
      headerName: "Modified On",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 190,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.modifiedDate ? row.modifiedDate : null;
        const sellerModifiedDateTimeString = row.ownerModifiedDate ? row.ownerModifiedDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.isUser() &&
            !CommonService.isSameCompany(row.companyId)
            ? CommonService.formatDateTime(sellerModifiedDateTimeString)
            : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div style={{width:"140px"}}>
          <input
            type="date"
            className="form-control border mt-1"
            style={{ boxShadow: "none" }}
            id="birthdate"
            name="birthdate"
            placeholder="MM/DD/YYYY"
            value={CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ?
              filter.ownerModifiedOn : filter.modifiedOn}
            onChange={(event) => handleFilterChange(event, 
              CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ? "ownerModifiedOn" : "modifiedOn")}                  
            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
          />
          </div>
        </div>
      ),
    },
    {
      field: "createdDate",
      headerName: "Requested On",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 190,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        const sellerCreatedDateTimeString = row.ownerCreatedDate ? row.ownerCreatedDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.isUser() &&
            !CommonService.isSameCompany(row.companyId)
            ? CommonService.formatDateTime(sellerCreatedDateTimeString)
            : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div style={{width:"140px"}}>
          <input
            type="date"
            className="form-control border mt-1"
            style={{ boxShadow: "none" }}
            id="birthdate"
            name="birthdate"
            placeholder="MM/DD/YYYY"
            value={CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ?
              filter.ownerRequestedOn : filter.requestedOn}            
            onChange={(event) => handleFilterChange(event, 
              CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ? "ownerRequestedOn" : "requestedOn")}
            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
          />
          </div>
        </div>
      ),
    },
    ...(CommonService.isAdmin() ||
    CommonService.getDashboardPrefrence() == Constant.Profile.BUYER
      ? [
          {
            field: "firstName",
            headerName: "Requested By",
            flex: 1,
            minWidth: 190,
            valueGetter: (value: any, row: any) =>
              `${row.firstName || ""} ${row.lastName || ""}`,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => (
              <CellWithTooltip value={params.value} />
            ),
            renderHeader: (params) => (
              <div>
                <Typography
                  variant="body2"
                  sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                  {params.colDef.headerName}
                </Typography>
                <div className="position-relative" style={{width:"140px"}}>
                  <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.firstName}
                    defaultValue={filter.firstName}
                    onChange={(event) => handleFilterChange(event, "firstName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                  />
                  <SearchIcon
                    sx={{
                      color: "#6F6F6F",
                      position: "absolute",
                      top: "50%",
                      left: "8px",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
            ),
          },
        ]
      : []),
    {
      field: "totalInventories",
      headerName: "Total Inventories",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 190,
      renderCell: (params: any) => (
        <CellWithTooltip value={params.value} />
      ),
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{width:"140px"}}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              defaultValue={filter.totalInventories}
              onChange={(event) => handleFilterChange(event, "totalInventories")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
    },
    {
      field: "quantity",
      headerName: "Total Quantity",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 190,
      renderCell: (params: any) => (
        <CellWithTooltip value={`${params.value} barrels`} />
      ),
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{width:"140px"}}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.quantity}
              defaultValue={filter.quantity}
              onChange={(event) => handleFilterChange(event, "quantity")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
    },
    {
      field: "finalAmount",
      headerName: "Total Amount",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 190,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{width:"140px"}}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.finalAmount}
              defaultValue={filter.finalAmount}
              onChange={(event) =>
                handleFilterChange(event, "finalAmount")
              }
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header border-0 my-2 h-100",
      cellClassName: "status-badge",
      flex: 1,
      minWidth: 190, // Allow it to grow if there is extra space

      renderCell: (params: any) => {
        const isStatusValid = params.row.hasValidStatus || false;
        const isItemRejected = params.row.hasItemRejected || false;
        const isRelocationFlag = params.row.relocationFlag || false;
        return (
          <div onClick={(e) => e.stopPropagation()}>
            {/* Pending Review - User */}
            {(["PENDING_REVIEW", "UNDER_REVIEW", "ACCEPTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "REJECTED"].includes(params.row.status) 
            || params.row.status === "ACCEPTED_REJECTED") && CommonService.isUser() && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.status,
                  params.row.id,
                  params.row.quantity
                )
              }
              disableCondition={CommonService.isAdmin() || !CommonService.isSameCompany(params.row.companyId) || ["OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "REJECTED"].includes(params.row?.status)}
              allowedLabels={["Cancelled"]}
            />
          )}

          {((params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && CommonService.isAdmin()) && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.status,
                  params.row.id,
                  params.row.quantity
                )
              }
              disableCondition={CommonService.isUser() || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || (params.row.status === "UNDER_REVIEW" && isStatusValid)}
              allowedLabels={isStatusValid ? ["Under Review"] : isItemRejected ? ["Under Review", "Rejected"] : ["Under Review", "Accepted", "Rejected"]}
            />
          )}

            {/* Accepted to Payment Received - Both User and Admin */}
            {["ACCEPTED", "REJECTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED"].includes(params.row?.status) && CommonService.isAdmin() && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.status, params.row.id, params.row.quantity)}
                disableCondition={CommonService.isUser() || params.row?.status === "CANCELLED" || params.row?.status === "REJECTED"}
                allowedLabels={["In Progress", "Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "Ownership Transferred", 
                  ...(isRelocationFlag ? ["In Transit"] : []), "Rejected", "Closed"]}
              />
            )}

            {/* Accepted - Admin specific options */}
            {params.row?.status === "IN_TRANSIT" && CommonService.isAdmin() && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) =>
                  handleStatusChange(
                    newStatus,
                    params.row.status,
                    params.row.id,
                    params.row.quantity
                  )
                }
                disableCondition={CommonService.isUser()}
                allowedLabels={["Rejected", "Closed"]}
              />
            )}

            {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
            {(params.row?.status === "CANCELLED" || params.row?.status === "CLOSED") && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) =>
                  handleStatusChange(
                    newStatus,
                    params.row.status,
                    params.row.id,
                    params.row.quantity
                  )
                }
                disableCondition={true}
                allowedLabels={[]}
              />
            )}

            {(params.row.status === "REJECTED" || params.row.status === "CANCELLED") && (
              <Tooltip
                title={`Rejection Note: ${params.row.rejectionNote}`}
                arrow
              >
                <StickyNote2Icon
                  className="note-icon cursor-pointer"
                  style={{ color: "gray" }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
      renderHeader: (params) => (
        <>
          <div>
            <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
              {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"140px"}}>
              <Form.Select
                style={{
                  cursor: "pointer",
                  borderBottomColor: "#D1D1D1",
                  marginTop: "4px",
                }}
                value={filter.status}
                className="form-control mb-1"
                onChange={(event) => handleFilterChange(event, "status")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              >
                <option value="">Select Status</option>
                {getStatusOptions()}
              </Form.Select>
            </div>
          </div>
        </>
      ),
    },
  ].filter(Boolean) as GridColDef[];

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.userStatus === "INVITED");
    disableAction.current = flag;
  };

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Filter rows based on search query
  // const filteredRows = tableData?.filter(
  //   (row) =>
  //     CommonService.isAdmin() ?
  //       row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.email?.toLowerCase().includes(search?.toLowerCase()) ||
  //       (row.firstName + " " + row.lastName).toLowerCase().includes(search?.toLowerCase())
  //     :
  //       row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.companyName?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  // const tableDataWithIds = filteredRows.map((rowData, index) => ({
  //   ...rowData
  // }));

  return (
    <>
      <div
        className="usersList pt-0"
        style={{ paddingBottom: CommonService.isAdmin() ? 51 : "" }}
      >
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-3 pt-3">
          {CommonService.isAdmin() && (
            <IconButton
              onClick={handleBack}
              aria-label="ArrowBackIcon"
              sx={{
                backgroundColor: "#FFF",
                "&:hover": { backgroundColor: "#FFF" },
              }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          )}
          <div className="header--title me-2">
            <h1 className="title mb-0 ms-2">
              {/* {userRole == "ADMIN" && "My Orders"} */}
              {CommonService.isAdmin() && "Order Requests"}{" "}
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
              {/* {userRole === "SUPER_ADMIN" && (
                <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2">
                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 ${
                      isNeedReviewClicked ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showPendingOrders(page, pageSize, filter);
                    }}
                  >
                    Need Review
                    <span
                      className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2 ${
                        isNeedReviewClicked ? "bg-secondary-subtle" : "bg-white"
                      }`}
                    >
                      {reviewOrderCount}
                    </span>
                  </button>
                </div>
              )}{" "} */}
              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-2"> */}
              {/* {isNeedReviewClicked && !disableAction.current && (
                  <button className="white-outline-btn me-1">
                   
                  </button>
                )}
                {isNeedReviewClicked && !disableAction.current && (
                  <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                    
                  </button>
                )} */}
              {/* <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                  Delete
                </button> */}
              {/* <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                  {selectionModel.length}
                </span> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
          <DataGrid
                      rows={tableData}
                      columns={columns}
                      className="data-grid"
                      initialState={{
                        pagination: {
                          paginationModel: { page: page, pageSize: pageSize },
                        },
                      }}
                      pagination
                      paginationModel={{
                        page,
                        pageSize,
                      }}
                      onPaginationModelChange={(newPaginationModel) => {
                        setPage(newPaginationModel.page);
                        setPageSize(newPaginationModel.pageSize);

                          if (currentPath === '/master/active-order'){
                            if(isNeedReviewClicked)
                              justShowPendingOrders(page,pageSize,filter);
                            else
                              fetchAllAcceptedOrders(page ,pageSize,filter);
                          }else
                            openOrderRequests(page,pageSize,filter);
                      }}
                      rowCount={totalCount}
                      paginationMode="server"
                      pageSizeOptions={[10, 20, 50, 100]}
                      // checkboxSelection
                      disableRowSelectionOnClick={true}
                      rowSelectionModel={selectionModel}
                      onRowSelectionModelChange={handleSelectionModelChange}
                      localeText={localeText}
                      onRowClick={(params) => handleRowClick(params)}
                      loading={isLoading}
                      disableColumnResize
                    />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Order Request Status</Modal.Title>
          {isApproved && (
            <p className="modal-text mt-2">
              Please Enter Track Number
            </p>
          )}
          {!isApproved && !isBeforeCancel && !isAfterCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to change status to {formatStatus(statusDetailsRef.current.status)}?
            </p>
          )}
          {isBeforeCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to cancel?
            </p>
          )}
          {isAfterCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to cancel? A cancellation charge may apply.
            </p>
          )}
        </Modal.Header>
        {["IN_TRANSIT", "REJECTED", "CANCELLED"].includes(statusDetailsRef.current.status) && (
        <Modal.Body>
          {isApproved && (
            <div className="form__inner">
              <label className="modal-body__title mb-1">Track Number</label>
              <Form.Control
                className={`form-control ${error ? "is_invalid" : ""}`}
                type="text"
                value={trackNumberValue}
                onChange={handleInputChange}
                placeholder="Enter Tracking Number..."
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
          {(!isApproved || isBeforeCancel || isAfterCancel) && (
            <form className="form__inner">
              <textarea
                required
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                value={rejectionNoteValue}
                onChange={handleInputChange}
                rows={8}
                style={{ resize: "none" }}
                placeholder="Enter text here..."
              />
              {error && <div className="error-message">{error}</div>}
            </form>
          )}

          {isApproved && (
            <div className="d-flex align-items-center h-100 mt-2">
            <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
              <IconButton
                type="button"
                aria-label="submit"
                sx={{
                  transform: "rotate(45deg)",
                  padding: "8px",
                }}
                onClick={handleButtonClick}
              >
                <AttachFileIcon />
              </IconButton>
              <span className="cursor-pointer" onClick={handleButtonClick}>
                Upload Document
              </span>
              <div className="d-flex align-items-center gap-2 flex-wrap">
                {files?.map((file, index) => (
                  <div
                    className="file__upload--info d-flex align-items-center mb-1 me-2"
                    key={index}
                  >
                    <div className="file-name me-3">{file.name}</div>
                    <div className="file-delete">
                      <img
                        src={TrashIcon}
                        alt="TrashIcon"
                        onClick={() => handleDeleteFile(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div>
            </div>
          )}
        </Modal.Body>)}

        <Modal.Footer className={`justify-content-center 
          ${(["IN_TRANSIT", "REJECTED", "CANCELLED"].includes(statusDetailsRef.current.status)) ? '' : 'bg-transparent'}`}>
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeOrderStatus}
                disabled={isLoading}
              >
                {isLoading && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActiveOrder;