import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

let TYPESENSE_SERVER_CONFIG = {
  apiKey: "Pxuu6Dw3nB9xLWQ92XwPwIzFnSoDGHp8", // Be sure to use an API key that only allows searches, in production
  nodes: [
    {
      host: "7ifeyq5gcutmxkr0p-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
  connectionTimeoutSeconds: 5,
  numRetries: 8,
};

export const createTypesenseAdapter = () => {
  return new TypesenseInstantsearchAdapter({
    server: TYPESENSE_SERVER_CONFIG,
    additionalSearchParameters: {
      queryBy: "mash_bill,stage,producer,description,tasting_note,product_name",
      numTypos: 3,
      typoTokensThreshold: 1,
      per_page: 12,
      // filter_by: filterValue,
    },
  });
};

export const searchClient = createTypesenseAdapter().searchClient;
