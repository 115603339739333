import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import { InventoryApiService } from "../../Service/InventoryApiService";
import useFormValidation from "./FormValidation";
import "../Static/Login/Login.scss";
import "./AddCompanyOrUser.scss";
import Spinner from "../Common/Spinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { fireEvent } from "@testing-library/react";
import { error } from "console";

const checkPasswordStrength = (password) => {
  if (password.length < 6) return "Weak";
  if (password.length < 10) return "Medium";
  return "Strong";
};

const EditInventory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [CompaniesList, setCompaniesList] = useState([]);
  const [showPasswordFields, setShowPasswordFields] = useState("no");
  const [selected, setSelected] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isValidZipcode, setValidZipcode] = useState(false);
  const [isZipcodeLoading, setZipcodeLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState<any>();
  const [descriptionError, setDescriptionError] = useState<any>();

  const {
    formData,
    errors,
    handleChange,
    handleBlur,
    setErrors,
    validateForm,
    setFormData,
  } = useFormValidation(inventoryData);

  useEffect(() => {
    fetchInventoryById();
  }, []);

  const fetchInventoryById = async () => {
    try {
      const response = await InventoryApiService.getInventoryById(id);
      const updatedResponse = {
        ...response.data,
        inventoryId: response.data['id'],
        id: '',
        status: 'PENDING_REVIEW'
      };
      setInventoryData(response.data);
      setFormData(updatedResponse);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const validateRequireFields = () => {
    
    let isValidationPass = true;

    const requiredFields = [
      { field: "title", error: "Please enter title" },
      { field: "initialQuantity", error: "Please enter quantity"},
      { field: "price", error: "Please enter price" },
      { field: "mashBill", error: "Please enter mass bill" },
      { field: "facility", error: "Please enter facility" },
      // { field: "barrelNumber", error: "Please enter barrel number"},
      { field: "fillDate", error: "Please enter fill date"},
      { field: "spiritAgeYear", error: "Please enter spirit age"},
      { field: "lotNo", error: "Please enter lot id"},
    ];

    requiredFields.forEach(({ field, error }) => {
      if (formData[field]?.length === 0 || errors[field]) {
        if (formData[field]?.length === 0) {
          setErrors((prevState) => ({ ...prevState, [field]: error }));
        }
        isValidationPass = false;
      }
    });

    if(formData.description.trim() == "") {
      isValidationPass = false;
      setDescriptionError("Please enter description");
    }

    return isValidationPass;
  }


  const routeBack = () => {
    history.goBack();
  };

  
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const isFormValid = await validateRequireFields();

    if (!isFormValid) {
      console.log("validation fallse")
      setIsLoading(false);
      return;
    }

    try {
      let response;

      response = await InventoryApiService.editInventory(formData);

      CommonService.Toast.fire({
        title: "Inventory Change Request Updated Successfully",

        icon: "success",
      });
      history.goBack();
    } catch (error: any) {
      CommonService.Toast.fire({
        title: error.response.data.errorMessage,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle change in editor data
  const handleEditorChange = (event, editor) => {
    setDescriptionError("");
    const data = editor.getData();
    setFormData((prevState) => ({
      ...prevState,
      description: data,
    }));
  };

  return (
    <>
      <div className="add-company-user-wrapper rounded-0">
        <div className="add-company-user-form-wrapper p-0">
          <form
            className="form__inner edit-inventory-form row d-flex mx-auto py-5"
            onSubmit={onSubmit}
            noValidate
          >
            <>
              <div className="col-12 mb-3">
                <label htmlFor="productTitle" className="form-label">
                  Product Title{" "}
                  <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter Product Title"
                  value={formData?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.title ? "is_invalid" : ""}`}
                />
                {errors.title && (
                  <div className="error-message">{errors.title}</div>
                )}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="productDesc" className="form-label">
                  About Product Description{" "}
                  <span className="star-required text-danger">*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData?.description} // Bind the editorData state to the editor
                  config={{
                    toolbar: [
                      "undo",
                      "redo",
                      "|",
                      "bold",
                      "italic",
                      "|",
                      "link",
                      "insertTable",
                      "mediaEmbed",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "indent",
                      "outdent",
                    ],
                    plugins: [
                      Bold,
                      Essentials,
                      Heading,
                      Indent,
                      IndentBlock,
                      Italic,
                      Link,
                      List,
                      MediaEmbed,
                      Paragraph,
                      Table,
                      Undo,
                    ],
                  }}
                  onChange={handleEditorChange} // Handle editor change event
                />
                {descriptionError && (
                  <div className="error-message">{descriptionError}</div>
                )}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="tastingNote" className="form-label">
                  Tasting Notes
                </label>
                <input
                  type="text"
                  id="tastingNote"
                  placeholder="Tasting Notes are here..."
                  name="tastingNote"
                  value={formData?.tastingNote}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="price" className="form-label">
                  Price ($) / barrel{" "}
                  <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="price"
                  name="price"
                  placeholder="Price/Barrel"
                  value={formData?.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.price ? "is_invalid" : ""}`}
                />
                {errors.price && (
                  <div className="error-message">{errors.price}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="initialQuantity" className="form-label">
                  Quantity / barrells{" "}
                  <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="initialQuantity"
                  name="initialQuantity"
                  placeholder="Quantity/Barrells"
                  value={formData?.initialQuantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.initialQuantity ? "is_invalid" : ""
                  }`}
                />
                {errors.initialQuantity && (
                  <div className="error-message">{errors.initialQuantity}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <div className="basic-faq">
                  <span className="question-text d-block mb-2 fw-bold">
                    Is there a requirement to relocate the barrels?{" "}
                    <span className="text-danger">*</span>
                  </span>
                  <div className="custom-radio-container">
                    <div className="d-inline-flex me-2">
                      <input
                        type="radio"
                        className="custom-radio"
                        name="relocationRequired"
                        id="relocationRequired1"
                        value="true" // Set static value
                        checked={formData?.isRelocationRequired === true}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isRelocationRequired: true,
                          })
                        } // Update state on change
                      />
                      <label
                        className="custom-radio-label"
                        htmlFor="relocationRequired1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="d-inline-flex">
                      <input
                        type="radio"
                        className="custom-radio"
                        name="relocationRequired"
                        id="relocationRequired2"
                        value="false" // Set static value
                        checked={formData?.isRelocationRequired === false}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isRelocationRequired: false,
                          })
                        } // Update state on change
                      />
                      <label
                        className="custom-radio-label"
                        htmlFor="relocationRequired2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <div className="basic-faq">
                  <span className="question-text d-block mb-2 fw-bold">
                    Is there a requirement to change account?{" "}
                    <span className="text-danger">*</span>
                  </span>
                  <div className="custom-radio-container">
                    <div className="d-inline-flex me-2">
                      <input
                        type="radio"
                        className="custom-radio"
                        name="isAccountTransfer"
                        id="isAccountTransfer1"
                        value="true" // Set static value
                        checked={formData?.isAccountTransfer === true}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isAccountTransfer: true,
                          })
                        } // Update state on change
                      />
                      <label
                        className="custom-radio-label"
                        htmlFor="isAccountTransfer1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="d-inline-flex">
                      <input
                        type="radio"
                        className="custom-radio"
                        name="isAccountTransfer"
                        id="isAccountTransfer2"
                        value="false" // Set static value
                        checked={formData?.isAccountTransfer === false}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isAccountTransfer: false,
                          })
                        } // Update state on change
                      />
                      <label
                        className="custom-radio-label"
                        htmlFor="isAccountTransfer2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <div className="basic-faq">
                  <span className="question-text d-block mb-2 fw-bold">
                    Are inventories available to make an offer?{" "}
                    <span className="text-danger">*</span>
                  </span>
                  <div className="custom-radio-container">
                    <div className="d-inline-flex me-2">
                      <input
                        type="radio"
                        className="custom-radio"
                        name="isMakeAnOffer"
                        id="isMakeAnOffer1"
                        value="true" // Set static value
                        checked={formData?.isMakeAnOffer === true}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isMakeAnOffer: true,
                          })
                        } // Update state on change
                      />
                      <label
                        className="custom-radio-label"
                        htmlFor="isMakeAnOffer1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="d-inline-flex">
                      <input
                        type="radio"
                        className="custom-radio"
                        name="isMakeAnOffer"
                        id="isMakeAnOffer2"
                        value="false" // Set static value
                        checked={formData?.isMakeAnOffer === false}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isMakeAnOffer: false,
                          })
                        } // Update state on change
                      />
                      <label
                        className="custom-radio-label"
                        htmlFor="isMakeAnOffer2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="mashBill" className="form-label">
                  Mash Bill <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="mashBill"
                  name="mashBill"
                  placeholder="Enter mash bill"
                  value={formData?.mashBill}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.mashBill ? "is_invalid" : ""
                  }`}
                />
                {errors.mashBill && (
                  <div className="error-message">{errors.mashBill}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="facility" className="form-label">
                  Facility <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="facility"
                  name="facility"
                  placeholder="Enter facility"
                  value={formData?.facility}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.facility ? "is_invalid" : ""
                  }`}
                />
                {errors.facility && (
                  <div className="error-message">{errors.facility}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="barrelNumber" className="form-label">
                  Barrel number{" "}
                  {/* <span className="star-required text-danger">*</span> */}
                </label>
                <input
                  type="text"
                  id="barrelNumber"
                  name="barrelNumber"
                  placeholder="Enter barrel number"
                  value={formData?.barrelNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.barrelNumber ? "is_invalid" : ""
                  }`}
                />
                {errors.barrelNumber && (
                  <div className="error-message">{errors.barrelNumber}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="fillDate" className="form-label">
                  Fill Date <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="fillDate"
                  name="fillDate"
                  placeholder="Enter fill date"
                  value={formData?.fillDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.fillDate ? "is_invalid" : ""
                  }`}
                />
                {errors.fillDate && (
                  <div className="error-message">{errors.fillDate}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="spiritAgeYear" className="form-label">
                  Spirit Age (Months){" "}
                  <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="spiritAgeYear"
                  name="spiritAgeYear"
                  placeholder="Enter spirit age"
                  value={formData?.spiritAgeYear}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.spiritAgeYear ? "is_invalid" : ""
                  }`}
                />
                {errors.spiritAgeYear && (
                  <div className="error-message">{errors.spiritAgeYear}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="lotNo" className="form-label">
                  Lot ID <span className="star-required text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="lotNo"
                  name="lotNo"
                  placeholder="Enter lot ID"
                  value={formData?.lotNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.lotNo ? "is_invalid" : ""}`}
                />
                {errors.lotNo && (
                  <div className="error-message">{errors.lotNo}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                <label htmlFor="barrelProfile" className="form-label">
                  Barrel Profile{" "}
                </label>
                <input
                  type="text"
                  id="barrelProfile"
                  name="barrelProfile"
                  placeholder="Enter barrel profile"
                  value={formData?.barrelProfile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.barrelProfile ? "is_invalid" : ""
                  }`}
                />
                {errors.barrelProfile && (
                  <div className="error-message">{errors.barrelProfile}</div>
                )}
              </div>
            </>
            <div className="col-12 mb-3">
              <div className="form__btn--group row d-flex">
                <div className="col-auto pe-1">
                  <button
                   type="button" 
                    onClick={routeBack}
                    className="btn secondary-btn register-btn mb-2"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-auto ps-1">
                  <button
                    type="submit"
                    className="btn primary-btn register-btn mb-2"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />}
                    {"Update Inventory"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditInventory;
