import './Loader.css';
const Customspinner = () => (
  <>
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </>
);

export default Customspinner;