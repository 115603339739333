import React, { useRef, useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import "../Dynamic/BarrelSelectionPanel.scss";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { Tab, Nav } from 'react-bootstrap';
import FAQList from '../popups/FAQList';

interface SupportFaqPanelProps {
    handleCloseFaq: () => void;
}
// function BarrelSelectionPanel({ handleCloseFaq })
const BarrelSelectionPanel: React.FC<SupportFaqPanelProps> = ({ handleCloseFaq }) => {

    return (
        <>
            <div className="faq-offcanvas-wrapper">
                <div className='faq-panel-left' onClick={handleCloseFaq}></div>
                <div className='faq-panel-inner' >
                    <div className='header'>
                        <div className='header-inner d-flex justify-content-between align-items-start'>
                            <div>
                                <p className='main-heading mb-0'>FAQ</p>
                            </div>
                            <button onClick={handleCloseFaq} className='close-btn'><CloseIcon /></button>
                        </div>
                    </div>
                    <div className='faq-panel-body'>
                    <FAQList/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BarrelSelectionPanel
