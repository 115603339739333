import React, { useEffect, useRef, useState } from "react";
import { Form, Button, ListGroup, Card } from "react-bootstrap";
import { MessageApiService } from "../../Service/MessageApiService";
import { CookieService } from "../../Service/CookieService";
import { useWebSocket } from "../../Websocket/WebSocketProvider";
import { useLocation, useParams } from "react-router";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton, Skeleton, Typography } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { format, isToday, isYesterday } from "date-fns";
import { CommonService } from "../../Service/CommonService";
import { OrderApiService } from "../../Service/OrderApiService";
import OfferPercentage from "../../assets/images/icons/OfferPercentage.svg";
import SampleDrop from "../../assets/images/icons/Sample.svg";
import InfoIcon from "../../assets/images/icons/InfoBlack.svg";
import ShoppingCartSimple from "../../assets/images/icons/ShoppingCartSimple.svg";
import SellRequestSimple from "../../assets/images/icons/SellRequestSimple.svg";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyFormatter from "../Static/CurrencyFormatter";
interface MessageBoxProps {
  selectedGroup: any;
  user1: string;
  user2: string;
  groupRequestId: string;
  searchQuery: string;
  requestType: string;
  isSeller: boolean;
}

const MessageBox: React.FC<MessageBoxProps> = ({
  selectedGroup,
  groupRequestId,
  user1,
  user2,
  searchQuery,
  requestType,
  isSeller,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [sellerDetails, setSellerDetails] = useState<any>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [messageContent, setMessageContent] = useState<string>("");
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<string>("user");
  const [admin, setAdmin] = useState<string>("admin");
  const userId = CookieService.getCookie("userId");
  const textareaRef = useRef(null);
  const { id } = useParams<{ id: string }>();
  const requestId = groupRequestId;
  const { webSocketService } = useWebSocket();
  let isTodayLineDisplay = false;
  let dashboardPrefrence = "";
  useEffect(() => {
    const module = "myModule";
    const callbackMethod = (data: any) => {
      console.log("Received data:", data);
      setMessages((prevMessages) => [...prevMessages, data]);
    };

    webSocketService.subscribe(module, callbackMethod);

    return () => {
      // Clean up subscriptions if necessary
    };
  }, [webSocketService]);

  useEffect(() => {
    setSellerDetails(null);
    setShowDetail(false);


    if (CommonService.isUser() && isSeller) {
      const sellerDetails = {
        sellerId: CommonService.getUserId(),
        sellerCompanyId: CommonService.getCompanyId()
      };
      setSellerDetails(sellerDetails);
    }

    if (CommonService.isAdmin() && user2 != "User2") {
      const sellerDetails: any = {
        sellerId: user1,
        sellerCompanyId: user2
      };
      setSellerDetails(sellerDetails);
    }

    loadMessages(groupRequestId, requestType);
  }, [groupRequestId, user2]);

  const loadMessages = async (groupRequestId: any, requestType: any) => {
    try {
      setIsLoading(true);
      let requestId = groupRequestId;
      let sellerCompanyId = user2 == "User2" ? null : user2;
      let hasSeller = user2 == "User2" ? isSeller : true; //if user2 have value then it means admin passed the sellerCompanyId
      const params = { requestId, requestType, hasSeller, sellerCompanyId };

      const response = await MessageApiService.getActiveMessagesByRequestType(
        params
      );
      console.log(response.data);
      setMessages(Object.values(response.data));
      setIsLoading(false);
    } catch (error) {
      console.log("error fetching data" + error);
      setIsLoading(false);
    }
  };

  const filteredMessages =
    searchQuery !== ""
      ? messages.filter(
          (msg: any) =>
            typeof msg.content === "string" &&
            msg.content
              .trim()
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase())
        )
      : messages;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (messageContent.trim() !== "") {
      const newMessage: any = {
        requestId: groupRequestId,
        requestType: requestType,
        content: messageContent,
        ...sellerDetails,
      };

      console.log("Sending message");

      MessageApiService.saveMessage(newMessage)

        .then((response) => {
          console.log(response.data);
          // history.push(`/my-cart`);
        })
        .catch((error) => {
          console.log(error);
        });

      // setMessages([...messages, newMessage]);
      setMessageContent("");
    }
    // if (textareaRef.current) {
    //   textareaRef.current.style.height = 'auto'; // Reset to original size
    // }
  };

  const formatTimestamp = (timestamp: string) => {
    if (timestamp) {
      const date = new Date(timestamp);

      if (isToday(date)) {
        const differenceInMinutes = Math.floor(
          (Date.now() - date.getTime()) / (1000 * 60)
        );
        if (differenceInMinutes < 1) {
          return "now";
        } else if (differenceInMinutes < 60) {
          return `${differenceInMinutes} min`;
        } else {
          return `Today | ${format(date, "h:mm a")}`;
        }
      } else if (isYesterday(date)) {
        return `Yesterday | ${format(date, "h:mm a")}`;
      } else {
        return format(date, "MMM d, yyyy | h:mm a");
      }
    } else {
      return "";
    }
  };

  const todayLine = (timestamp: string) => {
    if (timestamp) {
      const date = new Date(timestamp);

      if (isToday(date) && !isTodayLineDisplay) {
        return (isTodayLineDisplay = true);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    // Update message content
    setMessageContent(value);

    // Adjust textarea height
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // OnKeyDown Event For Text Area
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      const textarea = textareaRef.current;

      if (event.shiftKey) {
        // Prevent the default Enter behavior
        event.preventDefault();

        // Add a new line
        setMessageContent((prev) => prev + "\n");

        // Increase the textarea height by 5px
        if (textarea) {
          textarea.style.height = `${textarea.scrollHeight + 5}px`;
        }
      } else {
        // Prevent default Enter behavior and submit the form
        event.preventDefault();
        handleSubmit(event);
        console.log("Form submitted with:", messageContent);
        
        textarea.style.height = "auto"; // Reset contentEditable
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const messageId = "message-" + (messages.length - 1);
      console.log(messageId);
      const messageElement: any = document.querySelector(
        `div[id="${messageId}"]`
      );

      console.log(messageElement);
      if (messageElement) {
        messageElement.focus();

        setTimeout(() => {
          messageElement.scrollIntoView({ behavior: "auto", block: "center" });
        }, 0);
      }
    });
  }, [messages]);

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  return (
    <>
    {isLoading ? (<><Skeleton
      animation="wave"
      variant="rounded"
      style={{height:"100%"}}
    /></>) : (
    <>
      <div className="message-box-header row  justify-content-between m-0 pb-2">
        {selectedGroup && (
          <div className="row d-flex justify-content-between border-bottom m-0 pb-2">
            {(CommonService.isAdmin() ||
              CommonService.isSameCompany(selectedGroup.companyId)) && (
              <div className="col-auto ps-0">
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "inline",
                    fontWeight: 700,
                    fontSize: "18px",
                  }}
                >
                  {selectedGroup.sendBy} |
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "inline",
                    ml: 1,
                    fontWeight: 700,
                    fontSize: "18px",
                    color: "#8F9195",
                  }}
                >
                  {selectedGroup.companyName}
                </Typography>
              </div>
            )}
            {CommonService.isUser() && selectedGroup != null &&
              !CommonService.isSameCompany(selectedGroup.companyId) && (
                <div className="col-auto ps-0">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "inline",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    #{selectedGroup.requestNumber}
                  </Typography>
                  {/* <Typography
                    variant="subtitle1"
                    sx={{
                      display: "inline",
                      ml: 1,
                      fontWeight: 700,
                      fontSize: "18px",
                      color: "#8F9195",
                    }}
                  >
                    {selectedGroup.companyName}
                  </Typography> */}
                </div>
              )}

            <div
              title="Click to see details"
              className="col-auto ms-auto d-flex align-items-center gap-2  pe-0"
            >
              <div className="" onClick={() => setShowDetail(!showDetail)}>
                <button
                  type="button"
                  className="btn btn-outline-offer d-inline-flex align-items-center"
                >
                  {selectedGroup.requestType == "OFFER_REQUEST" && (
                    <img
                      src={OfferPercentage}
                      alt="OfferPercentage"
                      className="me-1"
                    />
                  )}
                  {selectedGroup.requestType == "SAMPLE_REQUEST" && (
                    <img src={SampleDrop} alt="SampleDrop" className="me-1" />
                  )}
                  {selectedGroup.requestType == "ORDER_REQUEST" && (
                    <img
                      src={ShoppingCartSimple}
                      alt="ShoppingCartSimple"
                      className="me-1"
                    />
                  )}
                  {selectedGroup.requestType == "SELL_REQUEST" && (
                    <img
                      src={SellRequestSimple}
                      alt="SellRequestSimple"
                      className="me-1"
                    />
                  )}

                  {toTitleCase(selectedGroup.requestType)}
                </button>
              </div>
              <div>
                <img
                  src={InfoIcon}
                  alt="info Icon"
                  style={{ fill: "#6F6F6F" }}
                  className="info-icon"
                  onClick={() => setShowDetail(!showDetail)}
                />
              </div>
            </div>
          </div>
        )}
        {showDetail && (
          <div className="offer-req-container">
            <div
              className="offer-req-left-inner"
              onClick={() => setShowDetail(!showDetail)}
            ></div>
            <div className="offer-req-inner">
              <div className="text-end" title="Close">
                <CloseIcon
                  className="close-icon"
                  onClick={() => setShowDetail(!showDetail)}
                />
              </div>
              {selectedGroup.requestType == "OFFER_REQUEST" && (
                <ListGroup>
                  <ListGroup.Item>
                    <h2 className="title mb-0">
                      {" "}
                      {selectedGroup?.inventoryName}{" "}
                    </h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Total Quantity</span>
                    <span className="info">{selectedGroup.quantity}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Total Price</span>
                    <span className="info">
                      <CurrencyFormatter
                        amount={selectedGroup.totalAmount}
                        locale="en-US"
                        currency="USD"
                      />
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Offer Price/Barrels</span>
                    <span className="info">
                      <CurrencyFormatter
                        amount={selectedGroup.pricePerBarrel}
                        locale="en-US"
                        currency="USD"
                      />
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Request Status</span>
                    <span className="info">{selectedGroup.status}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Submitted Date</span>
                    <span className="info">
                      {" "}
                      {CommonService.formatDateTime(selectedGroup.createdDate)}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              )}
              {selectedGroup.requestType == "SAMPLE_REQUEST" && (
                <ListGroup>
                  <ListGroup.Item>
                    <h2 className="title mb-0">
                      {selectedGroup.inventoryName}
                    </h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Sample Size</span>
                    <span className="info">{selectedGroup.size}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Total Quantity</span>
                    <span className="info">{selectedGroup.quantity}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Purpose</span>
                    <span className="info">{selectedGroup.purpose}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Request Status</span>
                    <span className="info">{selectedGroup.status}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Submitted Date</span>
                    <span className="info">
                      {CommonService.formatDateTime(selectedGroup.createdDate)}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              )}
              {selectedGroup.requestType == "ORDER_REQUEST" && (
                <ListGroup>
                  <ListGroup.Item>
                    <h2 className="title mb-0">
                      #{selectedGroup.requestNumber}
                    </h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Total Quantity</span>
                    <span className="info">
                      {selectedGroup.quantity} barrels
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Total Amount</span>
                    <span className="info">
                      <CurrencyFormatter
                        amount={selectedGroup.totalAmount}
                        locale="en-US"
                        currency="USD"
                      />
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Request Status</span>
                    <span className="info">{selectedGroup.status}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Submitted Date</span>
                    <span className="info">
                      {CommonService.formatDateTime(selectedGroup.createdDate)}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              )}
              {selectedGroup.requestType == "SELL_REQUEST" && (
                <ListGroup>
                  <ListGroup.Item>
                    <h2 className="title mb-0">
                      {selectedGroup.inventoryName}
                    </h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Fee Type</span>
                    <span className="info">{selectedGroup.feeType}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Platform Fee</span>
                    <span className="info">
                      {selectedGroup.feeType === "FLAT" && (
                        <>
                          <CurrencyFormatter
                            amount={selectedGroup.platformFee}
                            locale="en-US"
                            currency="USD"
                          />
                          / Barrel
                        </>
                      )}
                      {selectedGroup.feeType !== "FLAT" &&
                        `${selectedGroup.platformFee} %`}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Request Status</span>
                    <span className="info">{selectedGroup.status}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Submitted Date</span>
                    <span className="info">
                      {CommonService.formatDateTime(selectedGroup.createdDate)}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              )}

{selectedGroup.requestType == "CUSTOMER_SERVICE_REQUEST" && (
                <ListGroup>
                  <ListGroup.Item>
                    <h2 className="title mb-0">
                      {" "}
                      {selectedGroup?.serviceName}{" "}
                    </h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Comment</span>
                    <span className="info">{selectedGroup?.comment}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Request Status</span>
                    <span className="info">{selectedGroup.status}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="sub-title">Submitted Date</span>
                    <span className="info">
                      {" "}
                      {CommonService.formatDateTime(selectedGroup.createdDate)}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              )}
            </div>
          </div>
        )}
      </div>
     <><Card>
        {/* <Card.Header>Messages between {user} and {admin}</Card.Header> */}
        <Card.Body>
          <ListGroup variant="flush">
            {filteredMessages.map((message, index) => (
              <ListGroup.Item key={message.id} id={`message-${index}`}>
                {todayLine(message.timestamp) && (
                  <div className="today-line-wrapper">
                    <span className="today-text">Today</span>
                    <div className="hz-line"></div>
                  </div>
                )}

                {message.senderId === userId ? (
                  <>
                    <div className="d-flex justify-content-end">
                      <div className="sender">
                        <div className="sender__msg ms-auto">
                          <pre className="mb-0">{message.content}</pre>
                        </div>
                        <div className="sender__info ms-auto mt-1">
                          <p className="text-end mb-0">
                            <span className="sender__info--name me-1">
                              {message.sendBy}
                            </span>
                            <span className="sender__info--time me-1">
                              {formatTimestamp(message.timestamp)}
                            </span>
                            <span className="sender__info--status">
                              <CheckIcon fontSize="small" />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="avatar">
                        <span>{message?.sendBy?.charAt(0)?.toUpperCase()}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex">
                      <div className="avatar">
                        <span>{message?.sendBy?.charAt(0)?.toUpperCase()}</span>
                      </div>
                      <div className="receiver">
                        <div className="receiver__info ms-1">
                          <p className="text-start mb-0">
                          {CommonService.isAdmin() || !selectedGroup ||
                            CommonService.isSameCompany(
                              selectedGroup.companyId
                            ) ? (
                              <span className="sender__info--name me-1">
                                {message.sendBy}
                              </span>
                            ) : (
                              <span className="sender__info--name me-1">
                                #{message.requestNumber}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="receiver__msg">
                          <pre className="mb-0">{message.content}</pre>
                        </div>
                        <div className="receiver__info ms-1 mt-1">
                          <p className="text-start mb-0">
                            <span className="receiver__info--time me-1">
                              {formatTimestamp(message.timestamp)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
        <Card.Footer
          className="border-0 bg-white position-absolute w-100"
          style={{ bottom: "-5px" }}
        >
          <Form onSubmit={handleSubmit} className="send-msg position-relative">
            <Form.Group controlId="messageContent">
              <Form.Control
                as="textarea"
                ref={textareaRef} // Attach the ref
                rows={1}
                placeholder="Type a reply..."
                value={messageContent}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{
                  paddingRight: "60px",
                  resize: "none",
                  position: "relative",
                  overflow: "hidden", // Prevent scrollbars
                  height: "auto",
                  maxHeight: "100px",
                }}
              />
            </Form.Group>
            <IconButton
              type="submit"
              aria-label="submit"
              sx={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <SendOutlinedIcon />
            </IconButton>
          </Form>
        </Card.Footer>
      </Card>
      </>
      
    </>
  )}
  </>
  );
};

export default MessageBox;
