import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
  cardHeaderClasses,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import InviteUser from "../popups/InviteUser";
import React from "react";
import SingleApprove from "../popups/SingleApprove";
import SingleReInvite from "../popups/SingleReInvite";
import SingleReject from "../popups/SingleReject";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import DeleteUser from "../popups/DeleteUser";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import { isDebuggerStatement } from "typescript";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import Cookies from "universal-cookie";
import Constant from "../../Constant";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import InfoIcon from "@mui/icons-material/Info";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";
import { common } from "@mui/material/colors";
import Spinner from "../Common/Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import SearchIcon from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TrashIcon from "../../assets/images/icons/Trash.svg";

const SampleRequest = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const [userRole, setUserRole] = useState<string | null>(null);
  const sampleRequestStatusRefrence = useRef({
    id: "",
    inventoryId: "",
    sampleRequestStatus: "",
    trackNumber: "",
    rejectionNote: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const rejectionNote = useRef("");
  const trackNumber = useRef("");
  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
    setTrackNumber("");
  };
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [isApproved, setIsApproved] = useState(false); 
  const [isBeforeCancel, setIsBeforeCancel] = useState(false);
  const [isAfterCancel, setIsAfterCancel] = useState(false);
  const [error, setError] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const controllerRef = useRef<AbortController | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<any>({
    inventoryName: null,
    requestNumber: null,
    companyName: null,
    firstName: null,
    modifiedOn: null,
    requestedOn: null,
    ownerRequestedOn: null,
    ownerModifiedOn: null,
    size: null,
    quantity: null,
    purpose: null,
    status: null,
  });


  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const [isLoading, setIsLoading] = useState(false);

  const [isSpinner, setSpinner] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const history = useHistory();

  const fetchAllActiveSampleRequests = async (page, size, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getActiveSampleRequests(page,size,false, filter, signal);
      approved = Object.values(response.data.content); // Convert object to array
      approvedUserRef.current = approved;
      // Access updated value of approvedUser using approvedUserRef.current
      setTableData(approvedUserRef.current);
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSampleRequests = async (page, size, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getCompanyWiseSampleRequests(
        params,false,CommonService.getDashboardPrefrence(),
        page, size, filter, signal
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchUserRole = () => {
    const cookies = new Cookies();
    const role = cookies.get("userRole");
    setUserRole(role);
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (currentPath === "/master/my-warehouse/sample-request/" + id || currentPath === "/user/my-warehouse/sample-request")
      openSampleRequests(page,pageSize, filter);
    else 
      fetchAllActiveSampleRequests(page,pageSize, filter);
  }, []);

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //this code is for dropdown
  const [rowStatusMap, setRowStatusMap] = React.useState<{
    [key: string]: string;
  }>({});

  const handleStatusChange = async (newStatus: string, oldStatus: string, rowId: string, inventoryId:any ) => {

    newStatus = newStatus == 'Reject for Owner' ? 'REJECTED' : (newStatus == 'Accept for Owner' ? 'ACCEPTED' : newStatus);

    const sampleRequestStatus = newStatus?.replace(/ /g, "_");
    //create the object to send
    const currentStatus = {
      id: rowId,
      inventoryId: inventoryId,
      sampleRequestStatus: sampleRequestStatus.toUpperCase(),
      trackNumber: "",
      rejectionNote: "",
    };

    sampleRequestStatusRefrence.current = currentStatus;

    if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.REJECTED) {
      setIsApproved(false);
      handleShow();
    } else if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.IN_TRANSIT) {
      setIsApproved(true);
      handleShow();
    } else if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.CANCELLED) {
      if(["PENDING_REVIEW", "UNDER_REVIEW"].includes(oldStatus)){
        setIsBeforeCancel(true);
        setIsAfterCancel(false);
      }else if(["ACCEPTED", "IN_PROGRESS", "INVOICE_SENT", "PAYMENT_RECEIVED"].includes(oldStatus)){
        setIsAfterCancel(true);
        setIsBeforeCancel(false);
      }
      handleShow();
    } else if (["ACCEPTED", "IN_PROGRESS", "INVOICE_SENT", "PAYMENT_RECEIVED", "CLOSED"].includes(sampleRequestStatusRefrence.current.sampleRequestStatus)) {
      setIsApproved(false);
      setIsAfterCancel(false);
      setIsBeforeCancel(false);
      handleShow();
    } else {
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeSampleRequestStatus();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if ( sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.REJECTED ||
      sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.CANCELLED
    ) {
      setNote(event.target.value);
      setError("");
      rejectionNote.current = event.target.value;
    } else {
      setTrackNumber(event.target.value);
      setError("");
      trackNumber.current = event.target.value;
    }
  };

  const changeSampleRequestStatus = async () => {
    if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.REJECTED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter rejection note");
      return;
    }

    if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.CANCELLED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter cancellation note");
      return;
    }

    if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.IN_TRANSIT && trackNumberValue.trim().length === 0) {
      setError("Please enter truck number");
      return;
    }

    setSpinner(true);
    try {
      if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.REJECTED ||
        sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.CANCELLED
      ) {
        sampleRequestStatusRefrence.current.rejectionNote = rejectionNote.current;
      } else if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.IN_TRANSIT) {
        sampleRequestStatusRefrence.current.trackNumber = trackNumber.current;
      }

      await OrderApiService.changeSampleRequestStatus(sampleRequestStatusRefrence.current);
      setSpinner(false);
        
      // Load the service requests with updated filters
      if (currentPath === "/master/my-warehouse/sample-request/" + id || currentPath === "/user/my-warehouse/sample-request")
        openSampleRequests(page,pageSize, filter);
      else 
        fetchAllActiveSampleRequests(page,pageSize, filter);

      CommonService.Toast.fire({
        title: "Sample Request updated successfully",
        icon: "success",
      });
      const rowIndex = tableData.findIndex(
        (row) => row.id === sampleRequestStatusRefrence.current.id
      );

      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: sampleRequestStatusRefrence.current.sampleRequestStatus,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      if (sampleRequestStatusRefrence.current.sampleRequestStatus === Constant.SampleRequestStatus.IN_TRANSIT
          && (files.length > 0)) {
        uploadSampleRequestDocs();
      }
      handleClose();
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  const revertStatus = async () => {
    let previous = tableData.find(
      (row: any) => row.id === sampleRequestStatusRefrence.current.id
    );
    const rowIndex = tableData.findIndex(
      (row) => row.id === sampleRequestStatusRefrence.current.id
    );

    if (rowIndex !== -1) {
      // Create a new array with the updated status for the found object
      const updatedTableData = [...tableData];
      updatedTableData[rowIndex] = {
        ...updatedTableData[rowIndex],
        status: previous.status,
      };

      // Update the tableData state with the new array
      setTableData(updatedTableData);
    }

    setTableData(tableData);
    handleClose();
  };

  const handleRowClick = (params) => {
    const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
    history.push(`${linkPrefix}/view-sample-request/${params.row.id}`);
  }

  const getStatusOptions = () => {
    const profile = CommonService.getDashboardPrefrence();

    switch (userRole) {
      case "SUPER_ADMIN":
        return (
          <>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="UNDER_REVIEW">Under Review</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="INVOICE_SENT">Invoice Sent</option>
            <option value="PAYMENT_RECEIVED">Payment Received</option>
            <option value="IN_TRANSIT">In Transit</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="CLOSED">Closed</option>
          </>
        );
    }

    switch (profile) {
      case Constant.Profile.BUYER:
        return (
          <>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="UNDER_REVIEW">Under Review</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="INVOICE_SENT">Invoice Sent</option>
            <option value="PAYMENT_RECEIVED">Payment Received</option>
            <option value="IN_TRANSIT">In Transit</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="CLOSED">Closed</option>
          </>
        );
      case Constant.Profile.OWNER:
        return (
          <>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
          </>
        );
      default:
        break;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "requestNumber",
      headerName: "Request Number",
      flex: 1,
      minWidth: 170,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    defaultValue={filter.requestNumber}
                    onChange={(event) => handleFilterChange(event, "requestNumber")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.row.requestNumber || '-'}/>
    },
    {
      field: "inventoryName",
      headerName: "Inventory",
      flex: 1,
      minWidth: 170,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    defaultValue={filter.inventoryName}
                    onChange={(event) => handleFilterChange(event, "inventoryName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.row.inventoryName}/>
    },
    ...(CommonService.isAdmin()
    ? [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 170,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    defaultValue={filter.companyName}
                    onChange={(event) => handleFilterChange(event, "companyName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    }]
    : []),
    {
      field: "modifiedDate",
      headerName: "Modified On",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 170,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.modifiedDate ? row.modifiedDate : null;
        const sellerModifiedDateTimeString = row.ownerModifiedDate ? row.ownerModifiedDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.isUser() && !CommonService.isSameCompany(row.companyId) ?  CommonService.formatDateTime(sellerModifiedDateTimeString) : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div style={{width:"120px"}}>
            <input
                type="date"
                className="form-control border mt-1"
                style={{ boxShadow: "none" }}
                id="birthdate"
                name="birthdate"
                placeholder="MM/DD/YYYY"
                value={CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ?
                  filter.ownerModifiedOn : filter.modifiedOn}
                onChange={(event) => handleFilterChange(event, 
                  CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ? "ownerModifiedOn" : "modifiedOn")}                  
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            </div>
        </div>
        ),
    },
    {
      field: "createdDate",
      headerName: "Requested On",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 170,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        const sellerCreatedDateTimeString = row.ownerCreatedDate ? row.ownerCreatedDate : null;
        
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.isUser() && !CommonService.isSameCompany(row.companyId) ?  CommonService.formatDateTime(sellerCreatedDateTimeString) : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div style={{width:"120px"}}>
            <input
                type="date"
                className="form-control border mt-1"
                style={{ boxShadow: "none" }}
                id="birthdate"
                name="birthdate"
                placeholder="MM/DD/YYYY"
                value={CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ?
                          filter.ownerRequestedOn : filter.requestedOn}
                onChange={(event) => handleFilterChange(event, 
                  CommonService.isUser() && CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ? "ownerRequestedOn" : "requestedOn")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
        </div>
        </div>
      ),
    },
    ...((CommonService.getDashboardPrefrence() == Constant.Profile.BUYER || CommonService.isAdmin())
    ? [
    {
      field: "firstName",
      headerName: "Requested By",
      flex: 1,
      minWidth: 170,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    defaultValue={filter.firstName}
                    onChange={(event) => handleFilterChange(event, "firstName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    }]
    : []),
    {
      field: "size",
      headerName: "Sample Size",
      flex: 1,
      minWidth: 170,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <>
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <div className="position-relative" style={{width:"120px"}}>
                    <Form.Select
                        style={{ cursor: "pointer", borderBottomColor:"#D1D1D1", marginTop:"4px" }}
                        value={filter.size}
                        className="form-control mb-1"
                        onChange={(event) => handleFilterChange(event, "size")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    >
                        <option value="">Select Size</option>
                        <option value="750ml">750ml</option>
                        <option value="200ml">200ml</option>
                        <option value="100ml">100ml</option>
                    </Form.Select>
                </div>
            </div>
        </>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      minWidth: 170,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    defaultValue={filter.quantity}
                    onChange={(event) => handleFilterChange(event, "quantity")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1,
      minWidth: 170,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    defaultValue={filter.purpose}
                    onChange={(event) => handleFilterChange(event, "purpose")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    // {
    //   field: "finalAmount",
    //   headerName: "Total Amount",
    //   headerClassName: "table-header",
    //   flex: 1,
    // minWidth: 170,
    //   renderCell: (params) => <CurrencyFormatter amount={params.value} locale="en-US" currency="USD" />,
    // },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header border-0 my-2 h-100",
      cellClassName: "status-badge",
      flex: 1,
      minWidth: 170,
      renderHeader: (params) => (
        <>
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <div className="position-relative" style={{width:"120px"}}>
                    <Form.Select
                        style={{ cursor: "pointer", borderBottomColor:"#D1D1D1",marginTop:"4px" }}
                        value={filter.status}
                        className="form-control mb-1"
                        onChange={(event) => handleFilterChange(event, "status")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    >
                        <option value="">Select Status</option>
                        {getStatusOptions()}
                    </Form.Select>
                </div>
            </div>
        </>
      ),
      renderCell: (params: any) => (
        <div className="dropdown-nospacing" onClick={(e) => e.stopPropagation()}>
          {/* Pending Review - User */}
          {(["PENDING_REVIEW", "UNDER_REVIEW", "ACCEPTED", "IN_PROGRESS", "INVOICE_SENT", "PAYMENT_RECEIVED", "IN_TRANSIT", "REJECTED"].includes(params.row.status) 
          || params.row.status === "ACCEPTED_REJECTED") && CommonService.isUser() && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row?.status,
                  params.row.id,
                  params.row.inventoryId
                )
              }
              disableCondition={CommonService.isAdmin() || !CommonService.isSameCompany(params.row.companyId) || ["IN_TRANSIT", "REJECTED"].includes(params.row?.status)}
              allowedLabels={["Cancelled"]}
            />
          )}

          {((params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && CommonService.isAdmin()) && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row?.status,
                  params.row.id,
                  params.row.inventoryId
                )
              }
              disableCondition={CommonService.isUser() || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || (params.row.status === "UNDER_REVIEW" && params.row?.hasValidStatus)}
              allowedLabels={params.row?.hasValidStatus ? ["Under Review"] : params.row?.hasItemRejected ? ["Under Review", "Rejected"] : ["Under Review", "Accepted", "Rejected"]}
            />
          )}

          {/* Accepted to Payment Received - Both User and Admin */}
          {["ACCEPTED", "REJECTED", "INVOICE_SENT", "IN_PROGRESS", "PAYMENT_RECEIVED"].includes(params.row?.status) && CommonService.isAdmin() && (
            <StatusDropdown
              currentStatus={formatStatus(params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row?.status, params.row.id, params.row.inventoryId)}
              disableCondition={CommonService.isUser() || params.row?.status === "CANCELLED" || params.row?.status === "REJECTED"}
              allowedLabels={["In Progress", "Invoice Sent", "Payment Received", "In Transit", "Rejected", "Closed"]}
            />
          )}

          {/* Accepted - Admin specific options */}
          {params.row?.status === "IN_TRANSIT"  && CommonService.isAdmin() && (
            <StatusDropdown
              currentStatus={formatStatus(params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row?.status, params.row.id, params.row.inventoryId)}
              disableCondition={CommonService.isUser()}
              allowedLabels={["Rejected", "Closed"]}
            />
          )}

          {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
          {(params.row?.status === "DELIVERED" || params.row?.status === "CANCELLED" || params.row?.status === "CLOSED") && (
            <StatusDropdown
              currentStatus={formatStatus(params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row?.status, params.row.id, params.row.inventoryId)}
              disableCondition={true}
              allowedLabels={[]}
            />
          )}

          {(params.row.status === "REJECTED" || params.row.status === "CANCELLED") && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ].filter(Boolean) as GridColDef[];

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.status === "INVITED");
    disableAction.current = flag;
  };

  // Filter rows based on search query
  // const filteredRows = tableData.filter((row) =>
  //   CommonService.isAdmin()
  //     ? row.purpose?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.inventoryName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       (row.firstName + " " + row.lastName)
  //         .toLowerCase()
  //         .includes(search?.toLowerCase())
  //     : row.purpose?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.inventoryName?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = tableData.map((rowData, index) => ({
    ...rowData,
  }));

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof any) => {
    const value = event.target.value;
  
    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: field === "status" && value === "" ? null : value, // Update field only if the condition is met
    };
  
    // Update the filter state
    setFilter(tempFilter);
  
    // Load the service requests with updated filters
    if (currentPath === "/master/my-warehouse/sample-request/" + id || currentPath === "/user/my-warehouse/sample-request")
      openSampleRequests(page,pageSize, tempFilter);
    else 
      fetchAllActiveSampleRequests(page,pageSize, tempFilter);
  };

  const handleBack = () => {
    if (currentPath.startsWith("/master/sample-request")) {
      history.push(`/master/dashboard`);
    } else if (currentPath.startsWith("/master/my-warehouse/sample-request/"))
      history.push(`/master/company-dashboard/` + id);

    // history.goBack();
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {

      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const uploadSampleRequestDocs = async () => {

    const formData = new FormData();
    let response;
    let SampleRequestDoc = {};

    if (files && Array.isArray(files)) {
      files.forEach(function (file: any) {
        formData.append("file", file);
      });
    }

    const fileNames = files.map((file) => file.name).join(", ");

    SampleRequestDoc = {
      sampleRequestId: sampleRequestStatusRefrence.current.id,
      description: "uploaded " + fileNames + " document",
      userId: CommonService.getUserId(),
      title: fileNames,
      companyId: CommonService.getCompanyId(),
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(SampleRequestDoc)], {
        type: "application/json",
      })
    );

    response = await OrderApiService.uploadSampleRequestDocs(formData);
  };

  return (
    <>
      <div className="usersList pt-0"  style={{paddingBottom:CommonService.isAdmin() ? 53 : ""}}>
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          <div className="header--title me-2">
            <h1 className="title mb-0 ms-2">
              {/* {userRole == "ADMIN" && "My Sample Requests"} */}
              {userRole == "SUPER_ADMIN" && "Sample Requests"}{" "}
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              {/* <div className="col-auto input-container menu-items__search d-inline-flex mb-2 position-relative">
                                <input type="search" id="gsearch" name="gsearch" />
                                <img src={SearchIcon} alt="SearchIcon" className="icon position-absolute top-50 start-50 translate-middle" />
                            </div> */}
              {/* <input placeholder="Search something..." className="input" name="text" type="text"></input> */}
              <div className="col-auto mb-2">
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
              {/* <img src="../images/img_vert_line.svg" alt="vertline_one" className="h-[35px] md:w-full" /> */}
              {/* <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2"></div> */}
              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-2">
                {isNeedReviewClicked.current && !disableAction.current && (
                  <button className="white-outline-btn me-1"></button>
                )}
                {isNeedReviewClicked.current && !disableAction.current && (
                  <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1"></button>
                )}
                <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1"></button>
                <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                  {selectionModel.length}
                </span>
              </div> */}
              {/* <div className={`flex w-[23%] justify-center rounded-[20px] border border-solid border-gray-50 md:w-full ${isButtonClicked ? 'bg-white' : ''}`}>
            <div onClick={handleButtonClick}
    
                className="my-[3px] flex items-center gap-[13px] cursor-pointer"
            >
                <div className={`flex ${isButtonClicked ? 'bg-white' : ''}`}>
                    <p>
                        Need Review
                    </p>
                </div>
                <h2 className={`flex h-[32px] w-[32px] items-center justify-center rounded-[16px] text-center  ${isButtonClicked ? '' : 'bg-white'}`}
                >
                    {reviewUserCount}
                </h2>
            </div>
        </div>
        <img src="../images/img_vert_line.svg" alt="vertline_three" className="h-[35px] md:w-full" />
        <div className="flex gap-2.5">
            <button className="min-w-[129px] gap-2 !text-gray-500">
                Approve
            </button>
            <button
                className="min-w-[111px] gap-2 !text-gray-500"
            >
                Reject
            </button>
            <h3
                className="flex h-[40px] items-center justify-center rounded-[20px] border border-solid border-white-A700 text-center !text-gray-500"
            >
                0
            </h3>
        </div> */}
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            // checkboxSelection
            disableRowSelectionOnClick={true}
            localeText={localeText}
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: pageSize },
              },
            }}
            pagination
            paginationModel={{
              page,
              pageSize,
            }}
            onPaginationModelChange={(newPaginationModel) => {
              setPage(newPaginationModel.page);
              setPageSize(newPaginationModel.pageSize);

              if (currentPath === "/master/my-warehouse/sample-request/" + id || currentPath === "/user/my-warehouse/sample-request")
                openSampleRequests(newPaginationModel.page, newPaginationModel.pageSize, filter);
              else 
                fetchAllActiveSampleRequests(newPaginationModel.page, newPaginationModel.pageSize, filter);

            }}
            rowCount={totalCount}
            paginationMode="server"
            pageSizeOptions={[10, 20, 50, 100]}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
            loading={isLoading}
            disableColumnResize
          />

          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Sample Request Status</Modal.Title>
          {isApproved && (
            <p className="modal-text mt-2">
              Please Enter Track Number
            </p>
          )}
          {!isApproved && !isBeforeCancel && !isAfterCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to change status to {formatStatus(sampleRequestStatusRefrence.current.sampleRequestStatus)}?
            </p>
          )}
          {isBeforeCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to cancel?
            </p>
          )}
          {isAfterCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to cancel? A cancellation charge may apply.
            </p>
          )}
        </Modal.Header>
        {["IN_TRANSIT", "REJECTED", "CANCELLED"].includes(sampleRequestStatusRefrence.current.sampleRequestStatus) && (
          <Modal.Body>
          {isApproved && (
            <div className="form__inner">
              <label className="modal-body__title mb-1">Track Number</label>
              <Form.Control
                className={`form-control ${error ? "is_invalid" : ""}`}
                type="text"
                value={trackNumberValue}
                onChange={handleInputChange}
                placeholder="Enter Tracking Number..."
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
          {(!isApproved || isBeforeCancel || isAfterCancel) && (
            <div className="form__inner">
              <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                defaultValue={rejectionNoteValue}
                onChange={handleInputChange}
                rows={8}
                style={{ resize: "none" }}
                placeholder={(isBeforeCancel || isAfterCancel) ? "Enter Cancellation Note..." : "Enter Rejection Note..."}
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}

          {isApproved && (
            <div className="d-flex align-items-center h-100 mt-2">
            <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
              <IconButton
                type="button"
                aria-label="submit"
                sx={{
                  transform: "rotate(45deg)",
                  padding: "8px",
                }}
                onClick={handleButtonClick}
              >
                <AttachFileIcon />
              </IconButton>
              <span className="cursor-pointer" onClick={handleButtonClick}>
                Upload Document
              </span>
              <div className="d-flex align-items-center gap-2 flex-wrap">
                {files?.map((file, index) => (
                  <div
                    className="file__upload--info d-flex align-items-center mb-1 me-2"
                    key={index}
                  >
                    <div className="file-name me-3">{file.name}</div>
                    <div className="file-delete">
                      <img
                        src={TrashIcon}
                        alt="TrashIcon"
                        onClick={() => handleDeleteFile(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div>
            </div>
          )}

        </Modal.Body>)}
        <Modal.Footer className={`justify-content-center 
          ${(["IN_TRANSIT", "REJECTED", "CANCELLED"].includes(sampleRequestStatusRefrence.current.sampleRequestStatus)) ? '' : 'bg-transparent'}`}>
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={revertStatus}
                disabled={isSpinner}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeSampleRequestStatus}
                disabled={isSpinner}
              >
                {isSpinner && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SampleRequest;