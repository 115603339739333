import React, { FC, useEffect, useState } from "react";
import loginBanner from "../../../assets/images/login/WDTSbarrels.jpg";
import { RouteComponentProps } from "react-router";
import { Service } from "../../../Service/ApiService";
import { CookieService } from "../../../Service/CookieService";
import Constant from "../../../Constant";
import { CommonService } from "../../../Service/CommonService";
import Logo from "../../Logo/Logo";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Url } from "../../../Constants/Url";
import Spinner from "../../Common/Spinner";
import useFormValidation from "../../Dynamic/FormValidation";// Adjust the path as needed
import "./Login.scss"; // Adjust the path as needed
import notificationWebSocketService from "../../../Websocket/NotificationWebSocketService";
import UATbelt from "../../Common/UATbelt";
import { Link } from 'react-router-dom';
import "../../../sass/responsive.scss"

type FormValues = {
  username: string;
  password: string;
  city: string;
  ipAddress: string;
  state: string;
  country: string;
  postalCode: string;
  longitude: string;
  latitude: string;
};

type LoginProps = RouteComponentProps;

const Login: FC<LoginProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [location, setLocation] = useState({
    city: "",
    ip: "",
    region_name: "",
    country_name: "",
    zip_code: "",
    longitude: "",
    latitude: "",
  });

  const initialState: FormValues = {
    username: "",
    password: "",
    city: "",
    ipAddress: "",
    state: "",
    country: "",
    postalCode: "",
    longitude: "",
    latitude: "",
  };

  const { formData, errors, handleChange, handleBlur, setErrors } = useFormValidation(initialState);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Fetch location details
  const getLocationDetails = async () => {
    try {
      const response = await fetch(Url.GET_LOCATION_DETAILS);
      const data = await response.json();
      setLocation(data);
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  useEffect(() => {
    getLocationDetails();
  }, []);

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Basic validation
    if (formData.username.length === 0 || errors.username || formData.password.length === 0 || errors.password) {
      if (formData.username.length === 0) {
        setErrors((prevState) => ({ ...prevState, username: "Please enter email" }));
      }
      if (formData.password.length === 0) {
        setErrors((prevState) => ({ ...prevState, password: "Please enter password" }));
      }
      return;
    }
  
    try {
      setIsLoading(true);
      
      const updatedFormData = {
        ...formData,
        username: formData.username?.toLowerCase(), // Convert username to lowercase
        city: location.city,
        ipAddress: location.ip,
        state: location.region_name,
        country: location.country_name,
        postalCode: location.zip_code,
        longitude: location.longitude,
        latitude: location.latitude,
      };
  
      // Wrap the async call in another function for extra clarity
      const authenticate = async () => {
        const response = await Service.authentication(updatedFormData);
        return response;
      };
  
      // Await for the authentication response
      const response = await authenticate();
      setIsLoading(false);
  
      // Proceed with setting cookies and navigation
      CookieService.setAuthToken("authToken", response.data.accessToken);
      CookieService.setAuthToken("userRole", response.data.role);
      CookieService.setAuthToken("userId", response.data.userId);
      CookieService.setAuthToken("userName", response.data.name || "");
      CookieService.setAuthToken("companyId", response.data.companyId);
      CookieService.setAuthToken("companyName", response.data.companyName);
      localStorage.setItem("dashboardPrefrence", Constant.Profile.OWNER);

      if (response.data.role === Constant.Role.SUPER_ADMIN) {
        history.push("master/dashboard");
      } else if (response.data.role === Constant.Role.ADMIN || response.data.role === Constant.Role.USER) {
        notificationWebSocketService.connect(response.data.userId);
        history.push("/user/inventories");
      }
  
    } catch (error: any) {
      // Catch errors and notify the user
      CommonService.Toast.fire({
        title: "Invalid Credentials!",
        icon: "error",
      });
      setIsLoading(false);
    }
  };
  

  return (
    <><UATbelt /><div className="login text-start">
      <div className="login__form mx-auto">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="login__form--outer pt-5 mt-2">
              <div className="row mb-4">
                <div className="col-12">
                  <a target="_blank" href="https://whitedogllc.com"><Logo size="auto"/></a>
                  <p className="logo-sub__text mb-0 mt-2">Presented by <a target="_blank" href="https://whitedogllc.com" className="ms-1">White Dog Trading & Storage</a></p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <h1 className="login__heading">Welcome back!</h1>
                  <p className="login__text">Enter your credentials to access your account securely.</p>
                </div>
              </div>
              <form className="form__inner row d-flex" onSubmit={handleSubmit}>
                <div className="col-12 mb-3">
                  <label htmlFor="loginEmail" className="form-label">
                    Email <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.username ? "is_invalid" : ""}`}
                    placeholder="Enter your email"
                    value={formData.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="username"
                    id="loginEmail" />
                  {errors.username && <div className="error-message">{errors.username}</div>}
                </div>
                <div className="col-12 mb-4">
                  <label htmlFor="loginPassword" className="form-label">
                    Password <span className="star-required text-danger">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${errors.password ? "is_invalid" : ""}`}
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      id="loginPassword" />
                    <button
                      className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                  {errors.password && <div className="error-message">{errors.password}</div>}
                </div>
                <div className="col-12 col-sm-6 mb-1 mb-sm-3">
                  <div className="form-check">
                    {/* <input className="form-check-input" type="checkbox" id="rememberMe" />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember Me
                    </label> */}
                  </div>
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <div className="form-link text-sm-end">
                  <Link to="/forgot-password">
  Forgot Password?
</Link>                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="form__btn--group row d-flex flex-wrap justify-content-center">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn secondary-btn w-100 mb-2"
                        disabled={isLoading}
                      >
                        {isLoading && <Spinner />}
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="login__form--banner d-flex justify-content-center">
              <img src={loginBanner} alt="logo" height="100%"/>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
};

export default Login;
